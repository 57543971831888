import React from 'react';
import PropTypes from 'prop-types';

const arrows = {
  top: '↑',
  right: '→',
  bottom: '↓',
  left: '←',
};

const Arrow = ({ direction = 'left'}) => (
  <span className='arrow'>{arrows[direction]}</span>
);

Arrow.propTypes = {
  direction: PropTypes.string,
};

export default Arrow;
