/* eslint-disable max-len */

export const headings = {
  userData: {
    userAccount: 'Nutzerkonto',
    personalInfo: 'Persönliche Daten',
    contactInfo: 'Kontaktdaten',
    initialInvestment: 'Ihr Sparvorhaben',
    bankInfo: 'Referenzkonto',
  },
  onboarding: {
    personalInfo: 'Persönliche Angaben',
    contactInfo: 'Kontaktdaten',
    bankInfo: 'Danke für Ihre Bankverbindung.',
    initialInvestment: "So viel Geld für's Geld kann es geben.",
    status: 'Bitte vervollständigen Sie Ihre Registrierung.',
    statusSubHeading: 'Damit Der Zukunftsfonds für Sie Ihr Geld anlegen kann, brauchen wir noch ein paar Daten von Ihnen. Für Fragen dazu stehen wir Ihnen natürlich gerne zur Verfügung.',
    statusProgress: 'Status Ihrer Registrierung',
    summary: 'Sind Ihre Angaben korrekt?',
    summarySubHeading: 'Diese Informationen übermitteln wir an unsere Partnerbank FFB. Dort wird für Sie ein Depot eröffnet.',
    countryNotAvailable: 'Der Zukunftsfonds ist leider noch nicht in Ihrem Land verfügbar.',
  },
  others: {
    userInfo: 'Profil',
    changeBankInfo: 'Bankdaten ändern',
    changeEmail: 'E-Mail-Adresse ändern',
    confirmEmail: 'Neue E-Mail-Adresse bestätigen',
    newPassword: 'Neues Passwort festlegen',
    changePassword: 'Passwort ändern',
    resetPassword: 'Passwort vergessen?',
    login: 'Anmelden',
    orderHistory: 'Depothistorie',
    savingsRate: 'Sparrate anpassen',
    activationCode: 'Aktivierungscode',
    payIn: 'Einzahlen',
    payOut: 'Auszahlen',
    activation: 'Konto bestätigen',
    privacyPolicy: 'Datenschutzhinweis',
    imprint: 'Impressum',
    depotActivity: 'Depotaktivität',
  },
};

export const onboardingHelpTexts = {
  contactInfo: 'Wir benötigen Ihre Adresse, um für Sie ein Depot bei unserer Partnerbank FFB anzulegen.',
  personalInfo: 'Die FFB benötigt die Angabe Ihres Steuerlandes, damit  Ihnen die passenden Steuerformulare zugesendet werden können.',
  bankInfo: 'Wir benötigen Ihre Bankverbindung, damit wir Ihre Einmalanlage oder Ihre monatliche Sparrate für Sie anlegen können.',
  initialInvestment: 'Sie können monatlich sparen, oder mit einer Einmalanlage beginnen.',
  status: 'Schließen Sie die Registrierung ab, um Ihr Sparvorhaben zu aktivieren.',
  summary: 'Bitte prüfen Sie, ob Ihre Angaben richtig sind.',
  countryNotAvailable: 'Danke für Ihre Interesse. Wir melden uns bei Ihnen, sobald Der Zukunftsfonds in Ihrem Land erhältlich ist!',
};

export const faq = {
  contactInfoQ1: 'Warum benötigt der Zukunftsfonds eine Partnerbank?',
  contactInfoQ2: 'Kann ich die Registrierung abbrechen und zu einem späteren Zeitpunkt fortsetzen?',
  contactInfoQ3: 'Ist der Zukunftsfonds ein Anlageprodukt?',

  personalInfoQ1: 'Wie stellt der Zukunftsfonds den Schutz meiner persönlichen Daten sicher?',
  personalInfoQ2: 'Wie beeinflusst der Zukunftsfonds meine Steuer?',

  bankInfoQ1: 'Warum benötigt der Zukunftsfonds eine Partnerbank?',
  bankInfoQ2: 'Kann ich die Registrierung abbrechen und zu einem späteren Zeitpunkt fortsetzen?',
  bankInfoQ3: 'Ist der Zukunftsfonds ein Anlageprodukt?',

  initialInvestmentQ1: 'Dummy Frage Nr 1',// TODO noch zu ändern von dd.d
  initialInvestmentQ2: 'Dummy Frage Nr 2',// TODO noch zu ändern von dd.d
};

export const onboardingStatus = {
  accountCreated: 'Nutzerkonto erstellt',
  accountCreation: 'Antrag: In Bearbeitung bei der FFB',
  emailConfirmed: 'E-Mail-Adresse bestätigt',
  personalInfoCompleted: 'Persönliche Angaben vervollständigt',
  contactInfoCompleted: 'Kontaktdaten vollständig',
  initialInvestmentCompleted: 'Sparvorhaben angelegt',
  bankInfoCompleted: 'Bankkonto hinterlegt',
  termsAndConditionsAccepted: 'Geschäftsbedingungen heruntergeladen und akzeptiert',
  legitimationDone: 'Legitimation durchgeführt',
  notSend: 'Antrag nicht abgeschickt',
  pending: 'Antrag ausstehend',
  successful: 'Erfolgreich durchgeführt',
  failed: 'Antrag abgelehnt',
};

export const initialInvestment = {
  legend: {
    payInTitle: 'Ihr Sparvermögen',
    payInText: 'Ein- und Auszahlungen',
    returnTitle: 'Ihre Gewinne',
    returnText: 'Rendite',
    feesTitle: 'Unsere Gebühren',
    feesText: '% p.a.',
  },
  info: 'Bitte wählen Sie, ob Sie mit einer Einmalanlage oder einer monatlichen Summe sparen möchten. Natürlich können Sie auch beides kombinieren. Bitte sorgen Sie für eine ausreichende Deckung Ihres Konto, wenn Sie eine Einmalanlage tätigen möchten. Ihre monatliche Sparrate wird immer zum 1. des Monats eingezogen.',
  disclaimerTitle: 'Unsere Annahmen',
  disclaimerIntroInfo: 'Unser Rechenbeispiel geht von einer durchschnittlichen Rendite von 2,77% nach Kosten aus.',
  disclaimerIntroText: 'Die oben dargestellte Prognose zur Wertentwicklung ist kein verlässlicher Indikator für eine künftige Wertentwicklung von Der Zukunftsfonds.',
  disclaimerText: 'Die Vermögensanlage in Kapitalmärkte ist mit Risiken verbunden. Der Wert Ihrer Vermögensanlage kann fallen oder steigen. Es kann zu Verlusten des eingesetzten Kapitals kommen. Frühere Wertentwicklungen, Simulationen oder Prognosen sind kein verlässlicher Indikator für die künftige Wertentwicklung. Bitte beachten Sie hierzu unsere %(interpolation)s.',
  disclaimerAdditionalText: 'Der Zukunftsfonds benutzt eine Fondsdatenbank (FVBS), die auch die Vergleichsgruppe der „Defensiven Mischfonds“ beinhaltet. In dieser Vergleichsgruppe sind insgesamt 511 defensive Mischfondstranchen gelistet. Der Durchschnitt dieser Vergleichsgruppe wurde auf die letzten 10 Jahre gerechnet. Die Durchschnittsrendite liegt bei exakt bei 2,77%. Deren Schwankungsbreite bei 3,88%. Durch die Größe der Vergleichsgruppe, dem langen Zeithorizont von 10 Jahren und der unabhängigen Datenbank von FVBS ist diese Aussage daher sehr valide und unterstützt unser Ziel von 2-4% Rendite nach Kosten.',
};

export const changeBankInfo = {
  generalInfo: 'Zum Ändern Ihrer Bankdaten ist aus Sicherheitsgründen der Postweg notwendig.',
  stepsInfo: 'Bitte gehen Sie wie folgt vor:',
  first: 'Laden Sie sich das ',
  linkTextForFirst: 'Formular zur Bankdatenänderung herunter',
  second: 'Füllen Sie das Formular vollständig aus und unterschreiben Sie es persönlich.',
  third: 'Schicken Sie das ausgefüllte Formular an die angegebenen Adressdaten.',
  fourth: 'Wir kümmern uns um den Rest und benachrichtigen Sie, sobald die Bankdaten geändert wurden.',
};

export const countryNotAvailable = {
  info: 'Zur Zeit ist Der Zukunftsfonds nur In Deutschland erhältlich.',
};

export const userInfo = {
  changePassword: 'Passwort ändern',
  changeEmail: 'E-Mail-Adresse ändern',
};

export const orders = {
  ordersEmpty: 'Bisher wurden in Ihrem Depot keine Transaktionen durchgeführt.',
  id: 'ID',
  createdAt: 'Buchungstag',
  valutaDate: 'Wertstellung',
  orderType: 'Art',
  details: 'Details',
  orderValue: 'Betrag (€)',
};

export const hints = {
  passwordEightChars: 'Mindestens 8 Zeichen, 1 Zahl, 1 Groß- und 1 Kleinbuchstabe',
  bankAccountHolder: 'Depot- und Kontoinhaber müssen identisch sein.',
  ibanOnlyGerman: 'Bitte verwenden Sie ausschließlich ein deutsches Konto.',
  confirmEmail: 'Die neue E-Mail-Adresse muss von Ihnen zunächst bestätigt werden. Dazu senden wir Ihnen eine E-Mail. Bis zur Aktivierung bleibt Ihre aktuelle E-Mail-Adresse gültig.',
  rateInfo: 'Der Betrag wird monatlich von dem von Ihnen hinterlegten Bankkonto eingezogen. Ihre neue Sparrate wird zum nächsten Abbuchungstermin berücksichtigt.',
  activationCode: 'Bitte die bei der Registrierung verwendete E-Mail-Adresse angeben.',
  payIn: 'Der Betrag wird von Ihrem Referenzkonto abgebucht und Ihrer Sparsumme hinzugefügt.',
  payOut: 'Ihre Sparsumme beträgt %(interpolation). Welchen Betrag möchten Sie auszahlen?',
  confirmOrder: 'Bitte bestätigen Sie den Auftrag durch die Eingabe Ihres Passworts.',
  savingsRateDayOfMonth: 'Die Sparrate kann monatlich zum 1. oder 15. abgebucht werden',
};

export const orderConfirmation = {
  ffbInstruction: 'Ich beauftrage die FIL Fondsbank (FFB), folgenden Auftrag durchzuführen:',
  note: 'Da es sich um ein reines Ausführungsgeschäft handelt, erfolgt durch den Zukunftsfonds keine Geeignetheitsprüfung, keine Angemessenheitsprüfung und keine Beratung. Ich verzichte hiermit ausdrücklich darauf, dass durch den Zukunftsfonds eine entsprechende Beurteilung stattfindet.',
  payIn: {
    heading: 'Zusammenfassung Ihres Einzahlungsauftrags',
    text: ['Ich ermächtige die FFB, einmalig einen Betrag von', 'per Lastschrift sofort von der im Depot hinterlegten Referenzbankverbindung einzuziehen und in meinem FFB Depot mit der Nummer', 'anzulegen.'],
    ctaButtonLabel: 'Zahlungspflichtig durchführen',
  },
  payOut: {
    heading: 'Zusammenfassung Ihres Auszahlungsauftrags',
    text: ['Ich beauftrage die FFB, einmalig Anteile im Wert von', 'aus meinem FFB Depot mit der Nummer', 'zu verkaufen und den Betrag auf meine im Depot hinterlegte Referenzbankverbindung zu überweisen.'],
    ctaButtonLabel: 'Zahlungspflichtig durchführen',
  },
  savingsRate: {
    heading: 'Zusammenfassung Ihrer Sparplanänderung',
    text: ['Ich ermächtige die FFB, einen Betrag von', 'monatlich am', '. per Lastschrift von der im Depot hinterlegten Referenzbankverbindung einzuziehen und in meinem FFB Depot mit der Nummer', 'anzulegen.', 'Die nächste Sparrate wird am', 'eingezogen.'],
    hint: ['Die nächste Abbuchung des Sparplans steht kurz bevor. Deshalb kann die Sparplananpassung aus banktechnischen Gründen voraussichtlich erst zum ', 'in Kraft treten'],
    suspendSavingsRateText: 'Ich bestätige das Aussetzen meines aktuellen Sparplans.',
    ctaButtonLabel: 'Änderung durchführen',
    transactionMightOccurLater: 'Die nächste Sparrate wird in weniger als 2 Bankarbeitstagen eingezogen. Daher ist es möglich dass Ihre Änderungen für den nächsten Einzugstermin noch nicht greifen.',
  },
  totalSell: {
    heading: 'Zusammenfassung Ihres Totalverkaufs',
    text: ['Ich beauftrage die FFB, einen Verkauf aller Anteile (', ') aus meinem FFB Depot mit der Nummer', 'durchzuführen (Totalverkauf) und den Betrag auf meine im Depot hinterlegte Referenzbankverbindung zu überweisen.'],
    ctaButtonLabel: 'Zahlungspflichtigen Totalverkauf durchführen',
  },
  confirmTotalSell: {
    heading: 'auszahlen',
    text: 'Irgendein Text, der erstmal nicht wichtig ist', //TODO
    adjustAmount: 'Betrag anpassen',
    ctaButtonLabel: 'Totalverkauf bestätigen',
  },
};

export const orderTypes = {
  saving: 'Regelmäßige Einzahlung',
  buy: 'Einmalige Einzahlung',
  sell: 'Auszahlung',
  totalSell: 'Totalverkauf',
  neutral: 'Neutrale Transaktion',
  other: 'Sonstiges',
};

export const savingsRate = {
  currentRate: 'Aktuelle Sparrate:',
  dueDate: 'Nächste Abbuchung:',
  submitSavingsRate: 'Sparrate %(interpolation)s festlegen',
  adjustSavingsRate: 'Sparrate anpassen',
  noRateSet: 'Zurzeit haben Sie keine monatliche Sparrate festgelegt.',
  transactionDate: 'Abbuchungszeitpunkt',
};

export const legitimation = {
  headline: 'Bitte bestätigen Sie Ihre Identität bei der Deutschen Post.',
  info: 'Für die Eröffnung Ihres Depots muss die FFB Sie identifizieren. Die Legitimation wird in Zusammenarbeit mit der Deutschen Post AG durchgeführt. Sie haben die Wahl, ob Sie sich online oder in Ihrer Postfiliale ausweisen wollen.',
  online: 'POSTIDENT durch Videochat',
  branch: 'POSTIDENT durch Filiale',
};

export const dashboard = {
  deposit: 'Ihr Depotstand',
  pending: 'Ausstehende Transaktionen',
  payIn: 'Einzahlen',
  payOut: 'Auszahlen',
  payedIn: 'Bisher eingezahlt:',
  savingsRate: 'Sparrate anpassen',
  orderHistory: 'Historie einsehen',
  infoMessage: {
    text: 'Hallo %(interpolation). Sie haben eine neue Nachricht erhalten.',
    buttonText: 'Jetzt lesen',
  },
  showAllDepositActivities: 'Alle Depotaktivitäten anzeigen',
  isDeleted: 'Ihr Löschungsantrag ist bei uns eingegangen. Wir bedauern sehr, dass Sie uns verlassen. Es dauert 10 Bankarbeitstage bis Ihr Depot und das Kundenkonto endgültig gelöscht werden.',
};

export const payOut = {
  deposit: dashboard.deposit,
};

export const letters = {
  deleteAccount: 'Konto löschen',
  info: 'Um Ihr Konto zu löschen, schicken Sie bitte einen unterschriebenen Antrag an die FIL Fondsbank GmbH.',
  addressFfb: ['FIL Fondsbank GmbH', 'Kundenbetreuung', 'Postfach 11 06 63', '60041 Frankfurt am Main'],
  subjectLine: 'Antrag zur Löschung meines Depots',
  id: 'Auftrag:',
  portfolioNo: 'Depot:',
  personNo: 'Kundennr.:',
  salutation: 'Sehr geehrte Damen und Herren,',
  body: 'hiermit erhalten Sie die Willenserklärung zur Löschung meines Depots.',
  greeting: 'Mit freundlichen Grüßen',
};

export const changeDataInfo = {
  personalInfo: {
    title: 'Persönliche Daten',
    headline: 'Zum Ändern Ihrer persönlichen Daten ist aus Sicherheitsgründen der Postweg notwendig.',
  },
  contactInfo: {
    title: 'Kontaktdaten',
    headline: 'Zum Ändern Ihrer Kontaktdaten ist aus Sicherheitsgründen der Postweg notwendig.',
  },
  change: 'ändern',
  sendTo: 'Senden Sie bitte den Antrag mit den Änderungswünschen an die',
  changeInfo: 'Bitte geben Sie dabei folgende Informationen mit an:',
  nameAddress: 'Name und Adresse',
  sign: 'Bitte unterschreiben Sie den Antrag',
  changeName: 'Bei Namensänderungen',
  addDocuments: 'fügen Sie bitte eine Kopie eines amtlichen Dokuments bei (Personalausweis, Heiratsurkunde etc.), aus dem die Namensänderung hervorgeht.',
};

export const backLinks = {
  backToLogin: 'Zurück zum Login',
  backToDashboard: 'Zurück zum Benutzerkonto',
  backToInbox: 'Zurück zu den Nachrichten',
  backToActivationCode: 'Zurück zur Eingabe des Codes',
  backToOverview: 'Zurück zur Übersicht',
  backToStart: 'Zurück zur Startseite',
  backToUserInfo: 'Zurück zu meinen Daten',
  backToSignUp: 'Zurück zur Anmeldung',
};

export const notFound = {
  fourOhFour: '404',
  pageNotFound: 'Die Seite fehlt',
  text: 'Lassen Sie es nicht mit Ihren Erspanissen geschehen!',
};

export const welcome = {
  shapeFinancialFuture: 'Gestalten Sie Ihre finanzielle Zukunft.',
  registerWithin3Steps: 'Registrieren Sie sich in nur drei einfachen Schritten.',
  letsStart: 'Lassen Sie uns beginnen!',
  account: 'Benutzerkonto anlegen',
  savingsRate: 'Sparvorhaben gestalten',
  identity: 'Identität bestätigen',
};

export const inboxDetail = {
  messageOf: 'Unsere Mitteilung vom ',
};

export const inboxOverview = {
  noMessagesSelected: 'Keine Nachricht ausgewählt. Bitte wählen Sie eine Nachricht auf der linken Seite aus, um sie anzuzeigen.',
  noMessagesYet: 'Bisher sind keine Nachrichten in die Postbox eingegangen.',
};

export const confirmIdentity = {
  headline: 'Bitte bestätigen Sie Ihre Identität bei der Deutschen Post.',
  info: 'Für die Eröffnung Ihres Depots muss die FFB Sie identifizieren. Die Legitimation wird in Zusammenarbeit mit der Deutschen Post AG durchgeführt. Sie haben die Wahl, ob Sie sich online oder in Ihrer Postfiliale ausweisen wollen.',
  postIdentification: {
    heading: 'POSTIDENT durch Filiale',
    text: 'Identifizieren Sie sich in Ihrer Postfiliale',
  },
  videoIdentification: {
    recommendation: 'Unsere Empfehlung',
    heading: 'POSTIDENT durch Videochat',
    list: ['Identifizieren Sie sich bequem von zuhause', 'In nur 5 Minuten erledigt', 'Sparen Sie sich Ausdrucke und wertvolle Zeit'],
  },
  waitButtonText: 'Bitte warten',
  timeoutReachedText: 'Aktuell möchten sehr viele Menschen auf einmal clever sparen und wir können Sie nicht sofort legitimieren. Sie können dieses Fenster einfach schließen – Sie erhalten eine Anleitung mit den nächsten Schritten in wenigen Minuten per E-Mail.',
  buttonText: 'Ich möchte mich identifizieren',
  helpText: 'Klicken Sie auf die Schaltfläche, um sich auf der Website der Deutschen Post zu identifizieren.',
};

export const confirmActivationCode = {
  legend: 'Bitte überprüfen Sie Ihr E-Mail-Postfach.',
  info: 'Sie haben soeben per E-Mail einen sechsstelligen Sicherheitscode erhalten. Bitte geben Sie diesen ein, um die Anmeldung Ihres Benutzerkontos zu bestätigen.',
  help: 'Durch die Überprüfung Ihres Benutzerkontos stellen wir sicher, dass die Anmeldung von einer Person und keinem Computerprogramm durchgeführt wurde.',
  button: 'Code bestätigen',
};

export const resendActivationCode = {
  legend: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
  info: 'Wir senden Ihnen den Sicherheitscode erneut zu. Bitte geben Sie eine korrekte E-Mail-Adresse an.',
  help: 'Durch die Überprüfung Ihres Benutzerkontos stellen wir sicher, dass die Anmeldung von einer Person und keinem Computerprogramm durchgeführt wurde.',
  button: 'Code erneut senden',
  back: 'Zurück',
};

export const inbox = {
  title: 'Nachrichten',
  subject: 'Betreff',
  type: 'Typ',
  date: 'Datum',
  noMessagesYet: 'Bisher sind keine Nachrichten in die Postbox eingegangen.',
  openAttachment: 'Dateianhang herunterladen',
};

export const cookiebar = {
  text: 'Diese Website benutzt Cookies, um Ihnen das bestmögliche Nutzungserlebnis zu bieten. Weitere Informationen über eingesetzte Cookies finden Sie in unserer ',
  linkLabel: 'Mehr Informationen zur Datenschutzerklärung',
  linkText: 'Datenschutzerklärung',
  buttonText: 'Ich habe verstanden',
  buttonLabel: 'Datenschutzhinweis verstanden',
};

export const otherTexts = {
  back: 'Zurück',
  cancel: 'Abbrechen',
  forward: 'Weiter',
  confirm: 'Bestätigen',
  ok: 'OK',
  change: 'bearbeiten',
  myData: 'Profil',
  inbox: 'Nachrichten',
  confirmChanges: 'Änderungen übernehmen',
  nextStep: 'Nächster Schritt',
  confirmAndContinue: 'Bestätigen und fortsetzen',
  confirmAndNextStep: 'Bestätigen und nächster Schritt',
  noCustomerYet: 'Sie sind noch kein Kunde? ',
  signUpNow: 'Jetzt registrieren!',
  resetPassword: 'Passwort vergessen?',
  noActivationCodeYet: 'Haben Sie keinen Code erhalten?',
  resendCode: 'Code noch einmal senden',
  requestDepot: 'Kostenpflichtig beantragen',
  previousStep: 'Zurück',
  previousStepModalText:
    'Wenn Sie zum vorherigen Schritt zurückkehren, werden die hier angegebenen Änderungen verworfen.',
  moreInfo: 'weitere Informationen',
  moreInfo2: 'Mehr Informationen',
  disclaimerText: 'Für Ihre Fondsanteile benötigen Sie ein Wertpapierdepot. Dieses Depot wird von unserem Partner FIL Fondsbank GmbH (FFB) geführt. Mit Ihrer Registrierung beantragen Sie zugleich die Eröffnung Ihres Depots bei der FFB. Die FFB zieht die Gelder von Ihrem Girokonto ein und erwirbt dann für Sie die Anteile an Der Zukunftsfonds, die dann in Ihr persönliches Depot gebucht werden.',
  imprint: 'Impressum',
  additionalTaxCountry: 'Ich bin in einem weiteren Land steuerpflichtig.',
  depositValue: 'Depotwert',
  sessionTimer: 'Ihre Sitzung läuft ab in ',
};
