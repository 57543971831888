import flow from '@/constants/onboarding';

export function getNextStep(status) {
  const toComplete = flow.filter(step => !status[step.key]);
  if (toComplete.length === 0) {
    return 'done';
  }
  return toComplete[0];
};

export function getPreviousStep(currentKey) {
  const currentIndex = flow.findIndex(step => step.key === currentKey);

  if (currentIndex - 1 < 0) return false;

  return flow[currentIndex - 1];
};
