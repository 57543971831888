import * as t from '@/actions/types';

const initialState = window.localStorage.getItem('token') || null;

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.LOGIN_SUCCESS:
      return payload.token;
    case t.RESET_SESSION:
      return null;

    default:
      return state;
  }
};

export function getAuthToken(state) {
  return state.login.token;
}

export function getIsLoggedIn(state) {
  return !!state.login.token && !!state.user.userId;
}

export function getAuthCredentials(state) {
  if (!getIsLoggedIn(state)) { return null; }

  return {
    token: getAuthToken(state),
    userId: state.user.userId,
  };
}
