import * as t from '@/actions/types';
import * as formSelectors from '@/reducers/forms';
import * as personalInfoSelectors from '@/reducers/user/personalInfo';
import * as strings from '@/helpers/strings';

const initialState = {
  firstName: '',
  middleNames: '',
  lastName: '',
  title: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      return {
        firstName: payload.userInfo.personalInformation.firstName,
        middleNames: payload.userInfo.personalInformation.middleNames,
        lastName: payload.userInfo.personalInformation.lastName,
        title: payload.userInfo.personalInformation.title,
      };

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserName(state) {
  return state.user.name;
}

export function getFullUserName(state) {
  const personalInfo = personalInfoSelectors.getUserPersonalInfo(state);
  const nameInfo = getUserName(state);
  const options = formSelectors.getFormOptions(state);
  const completeInfo = Object.assign({}, personalInfo, nameInfo);

  return strings.buildFullName(completeInfo, options, true, true, true);
}
