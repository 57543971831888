/* eslint-disable */

import React from 'react';
import { onboardingTermUrl } from '@/helpers/download_urls';

//TODO: how to outsource those texts into constants?
const Withdrawal = () => (
  <ul>
    <li>
      <p>
        Ich habe zur Kenntnis genommen, dass ich die Erklärungen zum Abschluss des Konto- und/oder Depotvertrags und zur Internetnutzung des Depots nach Maßgabe der hier abrufbaren <a href={onboardingTermUrl('Informationen_zu_Bankgeschaeften_Fernabsatz_012019.pdf')} target='_blank'>
          Informationen zu außerhalb von Geschäftsräumen geschlossenen Verträgen und Fernabsatzverträgen über Finanzdienstleistungen
        </a> abgedruckten Widerrufsbelehrung widerrufen kann.
        Hinsichtlich des Erwerbs oder der Veräußerung von Anteilen oder Aktien eines offenen Investmentvermögens kann das am Ende der <a href={"/api/v1/user/onboarding/broker_information/terms_and_conditions"} target='_blank'>
          Allgemeinen Geschäftsbedingungen
        </a> abgedruckte Widerrufsrecht nach § 305 Kapitalanlagegesetzbuch (KAGB) bestehen.
      </p>
      <p>
        Ich habe die Widerrufsbelehrung hinsichtlich des Kaufs und Verkaufs von Anteilen an offenen Investmentvermögen erhalten und zur Kenntnis genommen.
        Eine Widerrufserklärung ist zu richten an:
      </p>
    </li>
    <li>
      <p>
        FIL Fondsbank GmbH<br />
        Kastanienhöhe 1<br />
        61476 Kronberg
      </p>
    </li>
  </ul>
);

export default Withdrawal;
