import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { SIGN_UP } from '@/router';
import { RESET_PASSWORD } from '@/router';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { loginSignUp as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import Form from '@/components/generic/Form';
import FormFooter from '@/components/generic/FormFooter';
import LabeledInput from '@/components/generic/LabeledInput';
import Button from '@/components/generic/Button';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'logIn'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.submitLogin());
  },
});

const LogInForm = ({
  errors = {},
  onSubmit,
}) => (
  <Form onSubmit={onSubmit} legend={texts.headings.others.login} errors={errors}>
    <Field
      name='email'
      type='email'
      label={labels.email}
      required={true}
      component={LabeledInput}
      errors={errors.email}
    />
    <Field
      name='password'
      type='password'
      label={labels.password}
      required={true}
      component={LabeledInput}
      errors={errors.password}
    />
    <RequiredHint />
    <Button type='submit'>{labels.buttonLogin}</Button>
    <FormFooter>
      <p>
        <Link to={{ type: RESET_PASSWORD }}>{texts.otherTexts.resetPassword}</Link>
      </p>
      <p>
        {texts.otherTexts.noCustomerYet}
        <Link to={{ type: SIGN_UP }}>{texts.otherTexts.signUpNow}</Link>
      </p>
    </FormFooter>
  </Form>
);

LogInForm.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export { LogInForm };
export default compose(
  reduxForm({
    form: 'logIn',
    initialValues: {
      email: '',
      password: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(LogInForm);
