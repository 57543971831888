import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';
import * as texts from '@/constants/texts';
import * as selectors from '@/reducers/selectors';
import { formatDateString } from '@/helpers/dates';
import { buildFullName, buildDepotInfo } from '@/helpers/strings';

import BackButton from '@/components/generic/BackButton';
import AccountLayout from '@/components/specific/AccountLayout';
import UserAccount from '@/components/specific/UserAccount';

const mapStateToProps = (state) => {
  const options = selectors.getFormOptions(state);
  const personalInfo = selectors.getUserPersonalInfo(state);
  const portfolioNumber = selectors.getUserPortfolioNumber(state);
  const customerNumber = selectors.getCustomerNumber(state);

  return {
    contactInfo: selectors.getUserContactInfo(state),
    fullName: buildFullName(personalInfo, options, false, true),
    depotInfo: buildDepotInfo(null, portfolioNumber, customerNumber),
    dateString: formatDateString(new Date(), true),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPreviousStep: () => { dispatch(actions.redirectUserInfo()); },
});

const DeleteAccount = ({
  contactInfo = {},
  fullName = '',
  depotInfo = '',
  dateString = '',
  onPreviousStep,
}) => (
  <AccountLayout>
    <UserAccount title={texts.letters.deleteAccount}>
      <div className='delete-account__text'>
        <p>{texts.letters.info}</p>
        <p>
          {fullName}<br />
          {`${contactInfo.streetName} ${contactInfo.streetNumber}`}<br />
          {`${contactInfo.postalCode} ${contactInfo.town}`}
        </p>
        <p>
          {texts.letters.addressFfb.map(line => [
            <span key='line'>{line}</span>,
            <br key='br' />,
          ])}
        </p>
        <p>{`${contactInfo.town}, ${dateString}`}</p>
        <p><strong>{texts.letters.subjectLine}<br />{depotInfo}</strong></p>
        <p>{texts.letters.salutation}</p>
        <p>{texts.letters.body}</p>
        <p>{texts.letters.greeting}</p>
      </div>
      <BackButton onClick={onPreviousStep}>
        {texts.backLinks.backToUserInfo}
      </BackButton>
    </UserAccount>
  </AccountLayout>
);

DeleteAccount.propTypes = {
  fullName: PropTypes.string,
  depotInfo: PropTypes.string,
  dateString: PropTypes.string,
  contactInfo: PropTypes.object,
  onPreviousStep: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
