import * as t from '@/actions/types';

const initialState = {
  primary: '',
  unconfirmed: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      return {
        primary: payload.userInfo.accountInformation.email || '',
        unconfirmed: payload.userInfo.accountInformation.unconfirmedEmail,
      };

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserEmail(state) {
  return state.user.email.primary;
}

export function getUserUnconfirmedEmail(state) {
  return state.user.email.unconfirmed;
}
