import * as t from '@/actions/types';

const initialState = window.localStorage.getItem('userId') || null;

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.LOGIN_SUCCESS:
      return payload.userId;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserId(state) {
  return state.user.userId;
}
