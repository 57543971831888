import * as t from '@/actions/types';

const initialState = {
  role: '',
  isDeleted: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.LOGIN_SUCCESS:
      return {
        role: payload.role,
        isDeleted: payload.isDeleted || false,
      };
    default:
      return state;
  }
};

export function getLoginRole(state) {
  return state.login.role.role;
}

export function getIsUserDeleted(state) {
  return state.login.role.isDeleted;
}
