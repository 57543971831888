import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Link from 'redux-first-router-link';
import { confirmActivationCode as texts, otherTexts } from '@/constants/texts';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import * as routes from '@/router';

import Form from '@/components/generic/Form';
import LargeNumberedInput from '@/components/generic/LargeNumberedInput';
import Button from '@/components/generic/Button';
import FormFooter from '@/components/generic/FormFooter';
import BackButton from '@/components/generic/BackButton';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  email: selectors.getUserEmail(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (e) => {
      e.preventDefault();
      dispatch(actions.confirmSignupEmail(null,
                                          actions.fetchOnboardingStatusAndForward,
                                          actions.track('registrationDoiSucess')));
    },
    onResend: () => {
      dispatch(actions.resendActivationCode());
    },
  };
};

const ConfirmActivationEmailForm = ({
  email,
  showBackButton = false,
  onSubmit,
  onResend,
}) => (
  <Form onSubmit={onSubmit} legend={texts.legend} info={texts.info}>
    <Field
      name='confirmationToken'
      required={true}
      component={LargeNumberedInput}
      label='Aktivierungscode'
    />
    <input
      name='email'
      type='hidden'
      value={email}
    />
    <FormFooter>
      <p>
        Wir haben Ihren Sicherheitscode an {email} gesendet.
        Ist diese E-Mail-Adresse nicht korrekt,
        klicken Sie bitte <Link to={{ type: routes.CHANGE_SIGNUP_EMAIL }}>hier</Link>.
        Sollten Sie keine E-Mail in Ihrem Postfach finden, überprüfen Sie bitte Ihren Spam-Ordner.
        Oder fordern Sie <a href='#resend' onClick={onResend}>hier</a> eine neue E-Mail an.
      </p>
    </FormFooter>
    <RequiredHint />
    <Button type='submit'>{texts.button}</Button>
    {showBackButton &&
      <BackButton onClick={null}>
        {otherTexts.previousStep}
      </BackButton>
    }
  </Form>
);

ConfirmActivationEmailForm.propTypes = {
  email: PropTypes.string,
  showBackButton: PropTypes.bool,
  onSubmit: PropTypes.func,
  onResend: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'confirmSignupEmail' }),
)(ConfirmActivationEmailForm);
