import React from 'react';
import * as t from './types';
import * as texts from '@/constants/texts';
import PrivacyPolicy from '@/components/routes/PrivacyPolicy';

// ERRORS

export function validationError(formName, error) {
  return {
    type: t.VALIDATION_ERROR,
    payload: { formName, error },
  };
}

export function resetErrors(response, formName) {
  return {
    type: t.RESET_ERRORS,
    payload: { formName },
  };
}

export function addError(error) {
  return {
    type: t.ADD_ERROR,
    payload: { error },
  };
}

export function removeError(id) {
  return {
    type: t.REMOVE_ERROR,
    payload: { id },
  };
}

// ALERTS

export function addAlert(alert) {
  return {
    type: t.ADD_ALERT,
    payload: { alert },
  };
}

export function removeAlert(id) {
  return {
    type: t.REMOVE_ALERT,
    payload: { id },
  };
}

export function showModal(props) {
  return {
    type: t.SHOW_MODAL,
    payload: props,
  };
}

export function hideModal() {
  return {
    type: t.HIDE_MODAL,
  };
}

// LOADING

export function activateLoading(loadingType = 'isOther') {
  return {
    type: t.SET_LOADING,
    payload: {
      [loadingType]: true,
    },
  };
}

export function deactivateLoading(loadingType = 'isOther') {
  return {
    type: t.SET_LOADING,
    payload: {
      [loadingType]: false,
    },
  };
}

export function resetLoading() {
  return {
    type: t.RESET_LOADING,
  };
}

// OTHER
export function toggleLegalDetails(detail) {
  return {
    type: t.TOGGLE_LEGAL_DETAILS,
    payload: {
      detail,
    },
  };
}

export function showPrivacyPolicyModal() {
  return {
    type: t.SHOW_MODAL,
    payload: {
      heading: texts.headings.others.privacyPolicy,
      children: <PrivacyPolicy />,
    },
  };
}

export function showAdditionalTaxCountry() {
  return {
    type: t.SHOW_ADDITIONAL_TAX_COUNTRY,
  };
}
