import * as t from '@/actions/types';

const initialState = [];

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_MESSAGES:
      return payload.messages;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getAllUserMessages(state) {
  return state.messages.all;
}

export function getUserMessageUnreadCount(state) {
  return state.messages.all.filter(m => !m.readAt).length;
}
