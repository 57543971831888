import React from 'react';

import AccountLayout from '@/components/specific/AccountLayout';
import DashboardFormLayout from '@/components/specific/dashboard/DashboardFormLayout';
import PayOutForm from '@/components/forms/PayOutForm';

const PayOut = () => (
  <AccountLayout>
    <DashboardFormLayout>
      <PayOutForm />
    </DashboardFormLayout>
  </AccountLayout>
);

export { PayOut };
export default PayOut;
