import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from 'redux-first-router-link';

const TaskList = ({ headline, tasks }) => (
  <div className='task-list'>
    <h2 className='task-list__headline'>{headline}</h2>

    <ul className='task-list__list'>
      {tasks.map((task) => {
        const classes = classnames('task-list__task', {
          'task-list__task--done': task.done,
          'task-list__task--undone': !task.done,
        });

        let label = task.label;

        if (!task.hideStatusText) {
          label += ': ';
          label += task.done ? 'Ja' : 'Nein';
        }

        return (
          <li className={classes} key={task.label}>
            {task.route ?
              <Link className='task-list__link' to={{ type: task.route }}>
                {label}
              </Link>
              : label
            }
          </li>
        );
      })}
    </ul>
  </div>
);

TaskList.propTypes = {
  headline: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    done: PropTypes.bool,
    hideStatusText: PropTypes.bool,
    label: PropTypes.string.isRequired,
    route: PropTypes.string,
  })),
};

export default TaskList;
