import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { otherTexts as otherLabels } from '@/constants/texts';
import { contactInfo as labels } from '@/constants/forms';

import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import SideBySide from '@/components/generic/SideBySide';
import Button from '@/components/generic/Button';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const initialValues = selectors.getUserContactInfo(state);
  initialValues.mobilePhoneNumber = (initialValues.mobilePhoneNumber || '').replace('+49', '');
  initialValues.landlinePhoneNumber = (initialValues.landlinePhoneNumber || '').replace('+49', '');

  return {
    initialValues,
    errors: selectors.getFormErrors(state, 'contactInfo'),
  };
};

const mapDispatchToProps = (dispatch, { isUpdate = true }) => ({
  onSubmit: (e) => {
    const successActions = [
      actions.setUserDataForm.bind({}, 'contactInfo', false),
      actions.fetchData.bind({}, 'userInfo'),
    ];
    if (!isUpdate) {
      successActions.push(
        actions.fetchOnboardingStatusAndForward,
        actions.track('contactDataFilled'),
      );
    }

    e.preventDefault();
    dispatch(actions.submitContactInfo(isUpdate, successActions));
  },
});

const ContactInfoForm = ({
  legendText = '',
  submitButtonLabel = otherLabels.confirmChanges,
  errors = {},
  onSubmit,
}) => (
  <Form onSubmit={onSubmit} legend={legendText}>
    <SideBySide ratio='golden' key='fullAddress'>
      <Field
        name='streetName'
        type='text'
        maxLength='64'
        label={labels.streetName}
        required={true}
        component={LabeledInput}
        errors={errors.streetName}
      />
      <Field
        name='streetNumber'
        key='streetNumber'
        type='text'
        maxLength='5'
        label={labels.streetNumber}
        required={true}
        component={LabeledInput}
        errors={errors.streetNumber}
      />
    </SideBySide>
    <Field
      name='postalCode'
      key='postalCode'
      type='text'
      maxLength='10'
      label={labels.postalCode}
      required={true}
      component={LabeledInput}
      errors={errors.postalCode}
    />
    <Field
      name='town'
      key='town'
      type='text'
      maxLength='40'
      label={labels.town}
      required={true}
      component={LabeledInput}
      errors={errors.town}
    />
    <LabeledInput
      key='country'
      name='country'
      label={labels.countryCode}
      value={labels.germany}
      disabled={true}
    />
    <Field
      name='mobilePhoneNumber'
      key='mobilePhoneNumber'
      type='tel'
      maxLength='17'
      prefix={labels.prefix}
      label={labels.mobilePhoneNumber}
      hint={labels.mobilePhoneNumberHint}
      required={true}
      component={LabeledInput}
      errors={errors.mobilePhoneNumber}
    />
    <Field
      name='landlinePhoneNumber'
      key='landlinePhoneNumber'
      type='tel'
      maxLength='17'
      prefix={labels.prefix}
      label={labels.landlinePhoneNumber}
      hint={labels.landlinePhoneNumberHint}
      required={false}
      component={LabeledInput}
      errors={errors.landlinePhoneNumber}
    />
    <RequiredHint />
    <Button type='submit'>
      {submitButtonLabel}
    </Button>
  </Form>
);

ContactInfoForm.propTypes = {
  legendText: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'contactInfo',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
)(ContactInfoForm);
