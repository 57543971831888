import * as t from '@/actions/types';

export default (store, lStorage = window.localStorage) => next => action => {
  if (action.type === t.RESET_SESSION) {
    lStorage.removeItem('token');
    lStorage.removeItem('userId');
    return next(action);
  }

  const prevToken = store.getState().login.token || '';
  next(action);
  const stateAfter = store.getState();
  const nextToken = stateAfter.login.token || '';

  if (prevToken !== nextToken) {
    lStorage.setItem('token', nextToken);
    lStorage.setItem('userId', stateAfter.user.userId);
  }
};
