import React from 'react';
import PropTypes from 'prop-types';

const FormFooter = ({ children }) => (
  <div className='form-footer'>
    {children}
  </div>
);

FormFooter.propTypes = {
  children: PropTypes.node,
};

export default FormFooter;
