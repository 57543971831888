import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { redirect } from 'redux-first-router';

import * as actions from '@/actions/creators';
import { resendActivationCode as texts } from '@/constants/texts';
import { loginSignUp as labels } from '@/constants/forms';
import * as selectors from '@/reducers/selectors';
import * as routes from '@/router';

import Form from '@/components/generic/Form';
import BackButton from '@/components/generic/BackButton';
import Button from '@/components/generic/Button';
import LabeledInput from '@/components/generic/LabeledInput';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'changeSignupEmail'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit(e) {
    e.preventDefault();
    dispatch(actions.changeSignupEmail());
  },
  onPreviousStep: () => dispatch(redirect({ type: routes.ACTIVATE_ACCOUNT })),
});

const ResendActivationCodeForm = ({
  showBackButton = false,
  onSubmit, errors = {},
  onPreviousStep,
}) => (
  <Form onSubmit={onSubmit} legend={texts.legend} info={texts.info}>
    <Field
      name='email'
      type='email'
      label={labels.emailAddress}
      required={true}
      component={LabeledInput}
      errors={errors.email}
    />
    <Field
      name='emailConfirmation'
      type='email'
      label={labels.emailConfirm}
      required={true}
      component={LabeledInput}
      errors={errors.emailConfirmation}
    />
    <RequiredHint />
    <Button type='submit'>{texts.button}</Button>
    {showBackButton &&
      <BackButton onClick={onPreviousStep}>
        {texts.back}
      </BackButton>
    }
  </Form>
);

ResendActivationCodeForm.propTypes = {
  showBackButton: PropTypes.bool,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onPreviousStep: PropTypes.func,
};

export default compose(
  reduxForm({
    form: 'changeSignupEmail',
    initialValues: {
      email: '',
      emailConfirmation: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ResendActivationCodeForm);
