import * as t from '@/actions/types';
import * as actions from '@/actions/creators';

export function validateLogin() {
  return {
    type: t.VALIDATE_LOGIN,
  };
}

export function loginSuccess(token, userId, role, isDeleted = false) {
  return {
    type: t.LOGIN_SUCCESS,
    payload: { token, role, isDeleted, userId },
  };
}

export function submitLogout(showSuccessMessage = true) {
  return actions.sendRequest({
    useToken: true,
    useUserId: true,
    endpointName: 'logOut',
    method: 'delete',
    showSuccessMessage: showSuccessMessage,
    successDispatch: [
      actions.logoutSuccess,
      actions.redirectStart,
    ],
  });
};

export const userSessionTick = () => {
  return {
    type: t.USER_SESSION_TIMER_TICK,
  };
};

export const userSessionReset = () => {
  return {
    type: t.USER_SESSION_TIMER_RESET,
  };
};

export function logoutSuccess() {
  return {
    type: t.RESET_SESSION,
  };
};

export function setResetPasswordToken(token) {
  return {
    type: t.SET_RESET_PASSWORD_TOKEN,
    payload: { token },
  };
};

export function resetPassword() {
  return {
    type: t.RESET_PASSWORD,
  };
};

export function submitNewPassword() {
  return {
    type: t.SUBMIT_NEW_PASSWORD,
  };
};

export function resetSession() {
  return {
    type: t.RESET_SESSION,
  };
};
