import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SessionTimer from "@/components/generic/SessionTimer";

const UserAccount = ({ title, timer, children = []}) => (
  <div className='user-account'>
    <div className='user-account__session'>
      <SessionTimer time={timer} />
    </div>
    <div className='user-account__content'>
      <h1 className='user-account__headline'>{title}</h1>
      {children}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  timer: state.login.timer,
});

UserAccount.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  timer: PropTypes.number,
};

export default connect(mapStateToProps)(UserAccount);
