import mapValues from 'lodash-es/mapValues';

import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.TOGGLE_USER_DATA_FORM:
      const formName = payload.formName;
      const newValue = !state[formName];
      const stateCopy = mapValues(state, () => false);
      stateCopy[formName] = newValue;
      return stateCopy;

    case t.SET_USER_DATA_FORM:
      return Object.assign({}, state, payload);

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getIsUserDataFormActive(state, formName) {
  return state.ui.onboarding.summary[formName];
}
