import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import routesMap, * as routes from '@/router';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import * as texts from '@/constants/texts';
import { CONFIRM_IDENTITY } from '@/constants/onboarding';

import Button from '@/components/generic/Button';
import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressStatus from '@/components/generic/ProgressStatus';
import HelpText from '@/components/generic/HelpText';
import TaskList from '@/components/generic/TaskList';

const mapStateToProps = (state) => {
  const route = state.location.type;
  const status = selectors.getOnboardingStatus(state);
  const { portfolioOrderStatus, portfolioRequest } = status;
  const waitingForFFBAccountCreation = portfolioOrderStatus === 'accountCreation';
  const nextStep = selectors.getNextStep(state) || {};
  const showConfirmButton = !waitingForFFBAccountCreation && (
    !portfolioRequest || nextStep.key === CONFIRM_IDENTITY.key
  );

  return {
    isOnboarding: routesMap[route].isOnboarding,
    isLoggedIn: selectors.getIsLoggedIn(state),
    status,
    showConfirmButton,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onConfirm: () => dispatch(actions.fetchOnboardingStatusAndForward()),
  onLogout: () => dispatch(actions.submitLogout()),
});

const Status = ({
  status = {},
  onConfirm,
  isOnboarding = false,
  isLoggedIn = false,
  isViewingData = false,
  showConfirmButton = true,
}) => {
  const accountCreationTasks = [
    { done: true, label: texts.onboardingStatus.accountCreated },
    { done: status.emailConfirmed, label: texts.onboardingStatus.emailConfirmed },
    { done: status.contactInformation, label: texts.onboardingStatus.contactInfoCompleted },
    { done: status.personalInformation, label: texts.onboardingStatus.personalInfoCompleted },
  ];

  const savingsPlanTasks = [
    { done: status.initialInvestment, label: texts.onboardingStatus.initialInvestmentCompleted },
    { done: status.bankInformation, label: texts.onboardingStatus.bankInfoCompleted },
    { done: status.legalAgreements, label: texts.onboardingStatus.termsAndConditionsAccepted },
  ];

  const confirmIdentityTasks = [];

  if (status.portfolioOrderStatus === 'accountCreation') {
    confirmIdentityTasks.push({
      done: true,
      label: texts.onboardingStatus.legitimationDone,
    });
  } else if (status.identificationUrl) {
    confirmIdentityTasks.push({
      done: false,
      label: texts.onboardingStatus.legitimationDone,
      route: routes.CONFIRM_IDENTITY,
    });
  } else {
    confirmIdentityTasks.push({
      done: false,
      label: texts.onboardingStatus.legitimationDone,
    });
  }

  const portfolioStatusTasks = [];

  if (status.portfolioOrderStatus) {
    portfolioStatusTasks.push({
      done: status.portfolioOrderStatus === 'successful',
      label: texts.onboardingStatus[status.portfolioOrderStatus],
      hideStatusText: true,
    });
  } else {
    portfolioStatusTasks.push({
      done: false,
      label: texts.onboardingStatus.notSend,
      hideStatusText: true,
    });
  }

  return (
    <OnboardingLayout>
      <HelpArea>
        <ProgressStatus text={texts.headings.onboarding.statusProgress} />
        <HelpText>{texts.onboardingHelpTexts.status}</HelpText>
      </HelpArea>
      <div className='status'>
        <h1 className='status__headline'>{texts.headings.onboarding.status}</h1>
        <p className='status__text'>{texts.headings.onboarding.statusSubHeading}</p>
        <TaskList headline='Benutzerkonto anlegen' tasks={accountCreationTasks} />
        <TaskList headline='Sparvorhaben gestalten' tasks={savingsPlanTasks} />
        <TaskList headline='Identität bestätigen' tasks={confirmIdentityTasks} />
        <TaskList headline='Depoteröffnung' tasks={portfolioStatusTasks} />

        {showConfirmButton &&
          <Button onClick={onConfirm}>{texts.otherTexts.ok}</Button>
        }
      </div>
    </OnboardingLayout>
  );
};

Status.propTypes = {
  status: PropTypes.object,
  onConfirm: PropTypes.func,
  isOnboarding: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isViewingData: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
