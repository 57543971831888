import React from 'react';
import PropTypes from 'prop-types';

import InputContext from '@/components/generic/InputContext';

const Checkbox = ({
  required = false,
  name = '',
  label = '',
  labelTitle = '',
  link = '',
  linkText = '',
  // for redux-form
  input = {},
  disabled = false,

  errors = [],
  hint = '',
}) => {
  return (
    <InputContext hint={hint} errors={errors}>
      <div className='labeled-checkbox'>
        <input
          {...input}
          id={`input-${input.name}-${labelTitle.replace(/\s/g, '-')}`}
          type='checkbox'
          className='labeled-checkbox__checkbox'
          checked={input.value}
          disabled={disabled}
        />
        <label htmlFor={`input-${input.name}-${labelTitle.replace(/\s/g, '-')}`}
          className='labeled-checkbox__label'
        >
          {labelTitle &&
            <span className='labeled-checkbox__label-title'>
              {labelTitle}{required && ' *'}
            </span>
          }
          {label}{!labelTitle && required && ' *'}
        </label>
        {linkText &&
          <a
            href={link}
            target='_blank'
            className='labeled-checkbox__label-link'
            rel='noopener noreferrer'
          >
            {linkText}
          </a>
        }
      </div>
    </InputContext>
  );
};
Checkbox.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  input: PropTypes.object,
  labelTitle: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  errors: PropTypes.array,
  hint: PropTypes.string,
};

export default Checkbox;
