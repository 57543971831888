import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { steps } from '@/constants/onboarding';

const ProgressSteps = ({ currentStep = 0 }) => (
  <div className='progress-steps'>
    <ol className='progress-steps__list'>
      {steps.map((step, index) => {
        const progressStepsLabelClass = classnames({
          'progress-steps__label': true,
          'progress-steps__label--active': (index === currentStep),
        });

        const progressStepsListItemClass = classnames({
          'progress-steps__list-item': true,
          'progress-steps__list-item--active': (index <= currentStep),
        });

        return (
          <li key={step} className={progressStepsListItemClass}>
            <span className={progressStepsLabelClass}>{step}</span>
          </li>
        );
      })}
    </ol>
  </div>
);

ProgressSteps.propTypes = {
  currentStep: PropTypes.number,
};

export default ProgressSteps;
