import React from 'react';

import AccountLayout from '@/components/specific/AccountLayout';
import DashboardFormLayout from '@/components/specific/dashboard/DashboardFormLayout';
import PayInForm from '@/components/forms/PayInForm';

const PayIn = () => (
  <AccountLayout>
    <DashboardFormLayout>
      <PayInForm />
    </DashboardFormLayout>
  </AccountLayout>
);

export { PayIn };
export default PayIn;
