// console.log(process.env.REACT_APP_API_URL)

// require('dotenv').config()
// console.log(process.env.REACT_APP_API_URL)

const API_URL = process.env.REACT_APP_API_URL;
const endpoints = {
  userInfo: '/user',
  signUp: '/user',
  logIn: '/user/session',
  logOut: '/user/session',
  loginValidation: '/user/session',
  confirmSignupEmail: '/user/activate',
  changeEmail: '/user/email',
  confirmChangedEmail: '/user/email/confirm',
  unlockAccount: '/user/unlock',
  activationCode: '/user/confirmation_token',
  resetPassword: '/user/reset_password_token',
  newPassword: '/user/reset_password',
  changePassword: '/user/password',
  changePersonalInfo: '/user/personal_information',
  formOptions: '/user/options',

  userPortfolio: '/portfolio',
  payIn: '/orders',
  payOut: '/orders',
  totalSell: '/orders',
  orderValidation: '/orders/validate',
  orderHistory: '/transactions',
  savingsRate: '/savings_rates',
  savingsRateValidation: '/savings_rates/validate',

  messages: '/messages',

  onboardingStatus: '/user/onboarding/onboarding_status',
  changeSignupEmail: '/user/onboarding/email',
  personalInfo: '/user/onboarding/personal_information',
  contactInfo: '/user/onboarding/contact_information',
  bankInfo: '/user/onboarding/bank_information',
  initialInvestment: '/user/onboarding/initial_investment',
  graphSettings: '/onboarding/graph_settings',
  validateIban: '/user/onboarding/bank_information/validate_iban',
  legalAgreements: '/user/onboarding/legal_agreements',
};

export default (name, entity, addition) => {
  let endpoint = API_URL;

  endpoint += endpoints[name];

  if (entity !== undefined) {
    endpoint += `/${entity}`;
  }

  if (addition) {
    endpoint += `/${addition}`;
  }

  return endpoint;
};
