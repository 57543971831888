import { redirect } from 'redux-first-router';

import * as t from '@/actions/types';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

const MAX_RETRY_CHECK_DEPOT_REQUEST_STATUS = 10;

/*
  STATUS
*/
export function fetchOnboardingStatus() {
  return actions.sendRequest({
    useToken: true,
    method: 'get',
    endpointName: 'onboardingStatus',
    successDispatch: [
      actions.fetchedOnboardingStatus,
    ],
  });
}

export function fetchOnboardingStatusAndForward() {
  return actions.sendRequest({
    useToken: true,
    method: 'get',
    endpointName: 'onboardingStatus',
    successDispatch: [
      actions.fetchedOnboardingStatus,
      actions.forwardNextOnboardingStep,
    ],
  });
}

export function forwardNextOnboardingStep() {
  return (dispatch, getState, getters = selectors) => {
    const state = getState();
    const status = getters.getOnboardingStatus(state);

    if (status.portfolioOrderStatus === 'accountCreation') {
      // For account_creation we have no further onboarding step and, thus, need to
      // stay on the onboardingStatus screen.
      dispatch(actions.redirectOnboardingStatus());
    } else {
      const nextStep = getters.getNextStep(state);
      dispatch(redirect({ type: nextStep.routeAction }));
    }
  };
}

export function fetchedOnboardingStatus(response) {
  return {
    type: t.FETCHED_ONBOARDING_STATUS,
    payload: { status: response.onboardingStatus },
  };
}

/*
  SUMMARY
*/
export function toggleUserDataForm(formName) {
  return {
    type: t.TOGGLE_USER_DATA_FORM,
    payload: {
      formName,
    },
  };
}

export function setUserDataForm(formName, value) {
  return {
    type: t.SET_USER_DATA_FORM,
    payload: {
      [formName]: value,
    },
  };
}

export function requestDepot() {
  return {
    type: t.REQUEST_DEPOT,
  };
}

export function checkDepotRequestStatus(amountRequests = MAX_RETRY_CHECK_DEPOT_REQUEST_STATUS) {
  return {
    type: t.CHECK_DEPOT_REQUEST_STATUS,
    payload: {
      amountRequests,
    },
  };
}
