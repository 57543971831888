import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { redirect } from 'redux-first-router';

import { LOG_IN } from '@/router';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { personalInfo as personalInfoLabels } from '@/constants/forms';
import { loginSignUp as loginSignUpLabels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import Form from '@/components/generic/Form';
import FormFooter from '@/components/generic/FormFooter';
import LabeledSelect from '@/components/generic/LabeledSelect';
import LabeledInput from '@/components/generic/LabeledInput';
import LabeledCheckbox from '@/components/generic/LabeledCheckbox';
import Button from '@/components/generic/Button';
import SideBySide from '@/components/generic/SideBySide';
import RequiredHint from '@/components/generic/RequiredHint';
import TextInput from '@/components/generic/InputHonyPot';

const mapStateToProps = (state) => ({
  options: selectors.getFormOptions(state),
  errors: selectors.getFormErrors(state, 'signUp'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    if (document.getElementsByName('email2')[0].value === '') {
      dispatch(actions.submitSignUp());
    }else{
      dispatch(redirect({ type: LOG_IN  }));
    }
  },
  onClick: (e) => {
    e.preventDefault();
    dispatch(actions.showPrivacyPolicyModal());
  },
});

const privacyPolicyButton = (onClick) => {
  return (
    <>
      {loginSignUpLabels.privacyPolicyFirst}
      <button type='button' onClick={onClick}>
        {loginSignUpLabels.privacyPolicyLinkLabel}
      </button>
      {loginSignUpLabels.privacyPolicyLast}
    </>
  );
};

const SignUpForm = ({
  options = {},
  errors = {},
  onSubmit,
  onClick,
}) => (
  <Form onSubmit={onSubmit}>
    <SideBySide>
      <Field
        name='gender'
        options={options.gender}
        label={personalInfoLabels.gender}
        required={true}
        component={LabeledSelect}
        errors={errors.gender}
        isInline={true}
        hasEmpty={true}
      />
      <Field
        name='title'
        label={personalInfoLabels.title}
        required={false}
        component={LabeledSelect}
        options={options.title}
        errors={errors.title}
        hasEmpty={true}
      />
    </SideBySide>
    <SideBySide>
      <Field
        name='firstName'
        type='text'
        maxLength='35'
        label={personalInfoLabels.firstName}
        required={true}
        component={LabeledInput}
        errors={errors.firstName}
      />
      <Field
        name='lastName'
        type='text'
        maxLength='35'
        label={personalInfoLabels.lastName}
        required={true}
        component={LabeledInput}
        errors={errors.lastName}
      />
    </SideBySide>
    <Field
      name='email'
      type='email'
      label={loginSignUpLabels.email}
      required={true}
      component={LabeledInput}
      errors={errors.email}
    />
    <Field
      name='password'
      type='password'
      label={loginSignUpLabels.createPassword}
      hint={texts.hints.passwordEightChars}
      required={true}
      component={LabeledInput}
      errors={errors.password}
    />
    <Field
      name='passwordConfirmation'
      type='password'
      label={loginSignUpLabels.passwordConfirmation}
      required={true}
      component={LabeledInput}
      errors={errors.passwordConfirmation}
    />
    <Field
      name='privacyPolicy'
      label={privacyPolicyButton(onClick)}
      required={true}
      component={LabeledCheckbox}
      errors={errors.privacyPolicy}
    />
    <RequiredHint />
    <TextInput />
    <Button type='submit'>{loginSignUpLabels.buttonSignUp}</Button>
    <FormFooter>
      <p>
        Sie haben bereits ein Benutzerkonto bei uns? <Link to={{ type: LOG_IN }}>Jetzt anmelden</Link>,
        um Ihr Sparvorhaben anzupassen.
      </p>
    </FormFooter>
  </Form>
);

SignUpForm.propTypes = {
  options: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
};

export { SignUpForm };
export default compose(
  reduxForm({
    form: 'signUp',
    initialValues: {
      email2: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      privacyPolicy: false,
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(SignUpForm);
