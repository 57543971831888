import * as t from '@/actions/types';

const initialState = null;

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_ONBOARDING_STATUS:
      const nextStep = payload.nextStep;
      return nextStep;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getNextStep(state) {
  return state.onboarding.nextStep;
}
