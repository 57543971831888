import { TRACK_EVENT } from '@/actions/types';

const triggerEvent = event => {
  (global.dataLayer || []).push(event);
};

const isRouterAction = action => action.meta && action.meta.location;
const triggerPageview = userID => {
  triggerEvent({
    userID,
    event: 'pageview',
    url: global.location.toString(),
    referrer: '',
  });
};

function products(state) {
  const { initialOrder, initialSavingsRate } = state.user.initialInvestment || {};

  return [
    {
      id: '1',
      name: 'initialInvestment',
      price: initialOrder,
      brand: 'Initial Investment',
      category: 'Initial Investment',
      quantity: 1,
    },
    {
      id: '2',
      name: 'recurringInvestment',
      price: initialSavingsRate,
      brand: 'Initial Savings Rate',
      category: 'Initial Savings Rate',
      quantity: 1,
    },
  ].filter(({ price }) => price > 0);
}

function cart(state) {
  return {
    currencyCode: 'EUR',
    add: {
      products: products(state),
    },
  };
}

function checkout(state) {
  return {
    checkout: {
      actionField: {
        step: 1,
      },
      products: products(state),
    },
  };
}

const isTrackingEvent = action => action.type === TRACK_EVENT;
const EXPLICITLY_TRACKED_EVENTS = {
  // Step 1
  registrationSuccess: userID => ({ userID, event: 'registrationSuccess' }),
  loginSuccess: userID => ({ userID, event: 'loginSuccess' }),
  registrationDoiSucess: userID => ({ userID, event: 'registrationDoiSucess' }),

  // Step 2
  contactDataFilled: userID => ({ userID, event: 'contactDataFilled' }),
  personalDataFilled: userID => ({ userID, event: 'personalDataFilled' }),
  EEaddToCart: (userID, _, state) => ({ userID, event: 'EEaddToCart', ecommerce: cart(state) }),
  EEcheckout: (userID, _, state) => ({ userID, event: 'EEcheckout', ecommerce: checkout(state) }),
  legalAgreementsAgreed: userID => ({ userID, event: 'legalAgreementsAgreed' }),
  orderCompleted: userID => ({ userID, event: 'orderCompleted' }),
};

export default store => next => action => {
  const result = next(action);
  const state = store.getState();
  const userId = state && state.user && state.user.userId;
  if (isRouterAction(action)) {
    triggerPageview(userId);
  }
  if (isTrackingEvent(action)) {
    triggerEvent(EXPLICITLY_TRACKED_EVENTS[action.payload.eventName](userId, action, state));
  }
  return result;
};
