import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '@/components/generic/Arrow';

const Button = ({ onClick = () => {}, children }) => (
  <div className='back-button'>
    <button
      type='button'
      className='back-button__button'
      onClick={onClick}
    >
      <Arrow />

      {children}
    </button>
  </div>
);

Button.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
