/* eslint-disable */

import React from 'react';

//TODO: how to outsource those texts into constants?
const Sepa = () => (
  <ul>
    <li>
      <p>
        Das Angebot der DFG richtet sich an Anleger, die sich über die Chancen und Risiken einer Anlage in den DER ZUKUNFTSFONDS selbst informieren.
      </p>
      <p>
        Die DFG prüft weder, ob eine Anlage in DER ZUKUNFTSFONDS mit den Anlagezielen des KUNDEN übereinstimmt, noch ob der KUNDE über die erforderlichen Kenntnisse und Erfahrungen verfügt, um die Risiken seiner eigenverantwortlich getroffenen Anlageentscheidung angemessen beurteilen zu können.“
      </p>
    </li>
  </ul>
);

export default Sepa;
