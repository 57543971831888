import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';

import { otherTexts as otherLabels } from '@/constants/texts';
import * as texts from '@/constants/texts';
import ContactInfoForm from '@/components/forms/ContactInfoForm';
import HelpText from '@/components/generic/HelpText';

const ContactInfo = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={0} />
      <HelpText>{texts.onboardingHelpTexts.contactInfo}</HelpText>
    </HelpArea>
    <ContactInfoForm
      isUpdate={false}
      legendText={texts.headings.onboarding.contactInfo}
      submitButtonLabel={otherLabels.nextStep}
    />
  </OnboardingLayout>
);
export default ContactInfo;
