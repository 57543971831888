import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';

import { otherTexts as otherLabels } from '@/constants/texts';
import { bankInfo as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';
import BankInfoForm from '@/components/forms/BankInfoForm';
import HelpText from '@/components/generic/HelpText';

const BankInfo = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={1} />
      <HelpText>{texts.onboardingHelpTexts.bankInfo}</HelpText>
    </HelpArea>
    <BankInfoForm
      isUpdate={false}
      legendText={texts.headings.onboarding.bankInfo}
      infoText={labels.info}
      submitButtonLabel={otherLabels.confirmAndContinue}
      showBackButton={true}
    />
  </OnboardingLayout>
);

export default BankInfo;
