import { getFormValues } from 'redux-form';

import * as t from '@/actions/types';
import * as actions from '@/actions/creators';
import ajax from '@/adapters/ajax';
import { noTokenProvided, serverError } from '@/constants/errors';
import getEndpoint from '@/constants/endpoints';
import { isServerError } from '@/helpers/errors';
import * as routes from '@/router';

export default (store, client = ajax, collector = getFormValues) => next => async action => {
  const { type, payload } = action;

  if (type === t.RESET_PASSWORD) {
    const endpoint = getEndpoint('resetPassword');
    const formValues = collector('resetPassword')(store.getState()) || {};
    const email = formValues.email;

    try {
      store.dispatch(actions.activateLoading('isSubmittingForm'));
      const response = await client(endpoint, 'post', undefined, { user: { email }});

      store.dispatch(actions.resetErrors(null, 'resetPassword'));
      store.dispatch(actions.addAlert(response[0].message));
      store.dispatch({ type: routes.START });

    } catch(error) {
      if (isServerError(error)) {
        return store.dispatch(actions.addError(serverError));
      }

      const errorBody = error[0] || {};
      const errors = errorBody.errors || {};
      store.dispatch(actions.validationError('resetPassword', errors));

    } finally {
      store.dispatch(actions.deactivateLoading('isSubmittingForm'));
    }
  } else if (type === t.SET_RESET_PASSWORD_TOKEN) {
    const { token } = payload;
    const firstEndpoint = getEndpoint('resetPassword');
    const endpoint = `${firstEndpoint}/${token}`;

    try {
      store.dispatch(actions.activateLoading('isSubmittingForm'));
      const response = await client(endpoint, 'get');
      const passwordResponse = response[0].resetPasswordToken || {};

      if (!passwordResponse.valid) {
        store.dispatch(actions.addAlert(noTokenProvided));
        return store.dispatch({ type: routes.START });
      }

      next(action);

    } catch(error) {
      if (isServerError(error)) {
        return store.dispatch(actions.addError(serverError));
      }

      store.dispatch(actions.addAlert(noTokenProvided));
      store.dispatch({ type: routes.START });

    } finally {
      store.dispatch(actions.deactivateLoading('isSubmittingForm'));
    }
  } else {
    return next(action);
  }
};
