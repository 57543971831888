import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';
import HelpText from '@/components/generic/HelpText';
import ResendActivationCodeForm from '@/components/forms/ResendActivationCodeForm';
import { resendActivationCode as texts } from '@/constants/texts';

const ResendActivationCode = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={0} />
      <HelpText>{texts.help}</HelpText>
    </HelpArea>
    <ResendActivationCodeForm showBackButton={true} />
  </OnboardingLayout>
);

export default ResendActivationCode;
