import { redirect as rfrRedirect } from 'redux-first-router';

import * as actions from '@/actions/creators';
import * as t from '@/actions/types';
import * as selectors from '@/reducers/selectors';
import * as routes from './index';
import { validator } from './validator';

const onboardingRoles = ['prospect', 'pending_customer', 'account_creation'];

export const forwardLoggedIn = async (dispatch, getState, action, validators = validator) => {
  const { isValid, token, role, isDeleted, userId } = await validators.validateLogin();
  if (isValid) {
    dispatch(actions.loginSuccess(token, userId, role, isDeleted));

    if (onboardingRoles.includes(role)) {
      dispatch(actions.fetchOnboardingStatusAndForward());
    } else {
      dispatch({ type: routes.DASHBOARD});
    }
  } else {
    dispatch({ type: t.RESET_SESSION });
  }
};

export const forwardOnboarding = (dispatch, getState) => {
  const nextStep = selectors.getNextStep(getState());
  if (!nextStep) {
    dispatch(actions.fetchOnboardingStatusAndForward());
  }
};

export const confirmSignupEmail = (dispatch, getState, action) => {
  const queryParams = getState().location.query || {};
  const confirmationToken = queryParams.confirmation_token;
  dispatch(actions.confirmSignupEmail(confirmationToken, actions.track('registrationDoiSucess')));
  forwardLoggedIn(dispatch, getState, action);
};

export const unlockAccount = (dispatch, getState) => {
  const unlockToken = getState().location.query.unlock_token || '';
  dispatch(actions.unlockAccount(unlockToken));
};

export const newPassword = (dispatch, getState, redirect = rfrRedirect) => {
  const locationQuery = getState().location.query || {};
  const resetPasswordToken = locationQuery.reset_password_token;
  if (!resetPasswordToken) {
    return dispatch(redirect({ type: routes.START }));
  }
  dispatch(actions.setResetPasswordToken(resetPasswordToken));
};

export const fetchMessage = (dispatch, getState, redirect = rfrRedirect) => {
  const locationPayload = getState().location.payload || {};
  const messageId = locationPayload.messageId;

  dispatch(actions.fetchData('message', messageId));
  dispatch(actions.markMessageRead(messageId));
};

export const changeSignupEmail = (dispatch, getState) => {
  const onboardingStatus = selectors.getOnboardingStatus(getState());
  if (onboardingStatus.emailConfirmed) {
    dispatch(actions.redirectOnboardingStatus());
  }
};
