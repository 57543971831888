import React from 'react';
import PropTypes from 'prop-types';

const LogoutButton = ({
  type = 'button',
  onClick = () => {},
  children,
}) => (
  <button
    type={type}
    onClick={onClick}
    className='logout-button'
  >
    {children}
  </button>
);

LogoutButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

export default LogoutButton;
