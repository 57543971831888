import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { loginSignUp as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import LabeledInput from '@/components/generic/LabeledInput';
import Form from '@/components/generic/Form';
import Button from '@/components/generic/Button';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'newPassword'),
  initialValues: {
    password: '',
    passwordConfirmation: '',
    resetPasswordToken: selectors.getResetPasswordToken(state),
  },
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.submitNewPassword());
  },
});

const NewPasswordForm = ({
  errors = {},
  onSubmit,
}) => (
  <Form onSubmit={onSubmit} legend={texts.headings.others.newPassword}>
    <Field
      name='password'
      type='password'
      label={labels.password}
      hint={texts.hints.passwordEightChars}
      required={true}
      component={LabeledInput}
      errors={errors.password}
    />
    <Field
      name='passwordConfirmation'
      type='password'
      label={labels.passwordConfirmation}
      required={true}
      component={LabeledInput}
      errors={errors.passwordConfirmation}
    />
    <Field
      name='resetPasswordToken'
      type='hidden'
      component='input'
    />
    <RequiredHint />
    <Button type='submit'>
      { labels.submitPassword }
    </Button>
  </Form>
);

NewPasswordForm.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export { NewPasswordForm };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'newPassword',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }, mapStateToProps),
)(NewPasswordForm);
