import * as t from '@/actions/types';

const initialState = {
  balance: '0',
  depositsValue: '0',
  canCreateOrders: true,
  pendingTransactionsValue: '0',
  portfolioNumber: null,
  profit: '0',
  historicalPortfolioValues: [],
  savingsRate: {
    monthlyRate: 0,
    dayOfMonth: 1,
  },
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_PORTFOLIO:
      return Object.assign({}, state, payload.portfolio);

    case t.RESET_SESSION:
      return initialState;
    default:
      return state;
  }
};

export function getUserBalance(state) {
  return state.finance.portfolio.balance || '0';
}

export function getUserDepositsValue(state) {
  return state.finance.portfolio.depositsValue || '0';
}

export function getUserCanCreateOrders(state) {
  return state.finance.portfolio.canCreateOrders;
}

export function getUserPortfolioNumber(state) {
  return state.finance.portfolio.portfolioNumber;
}

export function getPendingTransactionsValue(state) {
  return state.finance.portfolio.pendingTransactionsValue || '0';
}

export function getUserProfit(state) {
  return state.finance.portfolio.profit;
}

export function getHistoricalPortfolioValues(state) {
  return state.finance.portfolio.historicalPortfolioValues || [];
}

export function getAdjustedDepositsValue(state) {
  return parseFloat(getUserBalance(state)) + parseFloat(getPendingTransactionsValue(state)) + '';
}

export function getSavingsRate(state) {
  return state.finance.portfolio.savingsRate;
}
