import React from 'react';
import PropTypes from 'prop-types';

const HelpText = ({
  children,
  arrowAlignment = 'bottom',
}) => (
  children ?
    <div className='help-text'>
      <p className={`help-text__content help-text__content--arrow-${arrowAlignment}`}>
        {children}
      </p>
    </div>
  : null
);

HelpText.propTypes = {
  children: PropTypes.string,
  arrowAlignment: PropTypes.oneOf(['bottom', 'top']),
};

export default HelpText;
