import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as labels from '@/constants/forms';
import * as texts from '@/constants/texts';
import * as currency from '@/helpers/currency';
import * as strings from '@/helpers/strings';
import * as selectors from '@/reducers/selectors';

import UserDataOverview from '@/components/generic/UserDataOverview';
import PersonalInfoForm from '@/components/forms/PersonalInfoForm';
import ContactInfoForm from '@/components/forms/ContactInfoForm';
import UserDataInfo from '@/components/specific/UserDataInfo';
import BankInfoForm from '@/components/forms/BankInfoForm';
import InitialInvestmentForm from '@/components/forms/InitialInvestmentForm';
import ChangeBankInfo from '@/components/specific/ChangeBankInfo';

const mapStateToProps = (state) => {
  const personalInfo = selectors.getFormattedPersonalInfo(state);
  const contactInfo = selectors.getFormattedContactInfo(state);
  const bankInfo = selectors.getUserBankInfo(state);
  const name = selectors.getUserName(state);
  const fullName = selectors.getFullUserName(state);
  const customerNumber = selectors.getCustomerNumber(state);
  const { initialSavingsRate, initialOrder } =
    selectors.getUserInitialInvestment(state);

  return {
    ...personalInfo,
    ...contactInfo,
    ...bankInfo,
    name: strings.buildFullName(name),
    fullName,
    customerNumber,
    initialSavingsRate: currency.formatDisplayValue(initialSavingsRate),
    initialOrder: currency.formatDisplayValue(initialOrder),
    userRole: selectors.getLoginRole(state),
    isDeleted: selectors.getIsUserDeleted(state),
  };
};

const UserData = ({
  isDeleted,
  userRole,
  customerNumber,
  email,
  unconfirmedEmail,
  name,
  fullName,
  streetName,
  streetNumber,
  postalCode,
  town,
  country,
  dateOfBirth,
  placeOfBirth,
  countryOfBirth,
  nationality,
  taxCountry,
  taxIdentNumber,
  additionalTaxCountry,
  additionalTaxIdentNumber,
  mobilePhoneNumber,
  landlinePhoneNumber,
  initialSavingsRate,
  initialOrder,
  bankAccountHolder,
  bankAccountIban,
}) => [
  <UserDataOverview
    key='userAccount'
    title={texts.headings.userData.userAccount}
    data={
      [
        { label: labels.loginSignUp.customerNumber, value: customerNumber },
        { label: labels.loginSignUp.email, value: email },
        { label: labels.loginSignUp.unconfirmedEmail, value: unconfirmedEmail },
      ]
    }
  />
  ,
  <UserDataOverview
    key='personalInfo'
    title={texts.headings.userData.personalInfo}
    FormComponent={userRole === 'prospect' ? PersonalInfoForm : UserDataInfo}
    formName='personalInfo'
    userRole={userRole}
    isDeleted={isDeleted}
    data={
      [
        { label: labels.personalInfo.fullName, value: fullName },
        { label: labels.personalInfo.dateOfBirth, value: dateOfBirth },
        { label: labels.personalInfo.placeOfBirth, value: placeOfBirth },
        { label: labels.personalInfo.countryOfBirth, value: countryOfBirth },
        { label: labels.personalInfo.nationalityCountry, value: nationality },
        { label: labels.personalInfo.taxCountry, value: taxCountry },
        { label: labels.personalInfo.taxIdentNumber, value: taxIdentNumber },
        { label: labels.personalInfo.additionalTaxCountry, value: additionalTaxCountry },
        { label: labels.personalInfo.additionalTaxIdentNumber, value: additionalTaxIdentNumber },
      ]
    }
  />
  ,
  <UserDataOverview
    key='contactInfo'
    title={texts.headings.userData.contactInfo}
    FormComponent={userRole === 'prospect' ? ContactInfoForm : UserDataInfo}
    formName='contactInfo'
    isDeleted={isDeleted}
    data={
      [
        { label: labels.contactInfo.streetNameAndNumber, value: streetName + ' ' + streetNumber },
        { label: labels.contactInfo.postalCode, value: postalCode },
        { label: labels.contactInfo.town, value: town },
        { label: labels.contactInfo.countryCode, value: country },
        { label: labels.contactInfo.mobilePhoneNumber, value: mobilePhoneNumber },
        { label: labels.contactInfo.landlinePhoneNumber, value: landlinePhoneNumber },
      ]
    }
  />
  ,
  userRole === 'prospect' &&
  <UserDataOverview
    key='initialInvestment'
    title={texts.headings.userData.initialInvestment}
    FormComponent={InitialInvestmentForm}
    formName='initialInvestment'
    data={[
      { label: labels.initialInvestment.initialOrder, value: initialOrder },
      { label: labels.initialInvestment.initialSavingsRate, value: initialSavingsRate },
    ]}
  />
  ,
  <UserDataOverview
    key='bankInfo'
    title={texts.headings.userData.bankInfo}
    FormComponent={userRole === 'prospect' ? BankInfoForm : ChangeBankInfo}
    formName='bankInfo'
    isDeleted={isDeleted}
    data={
      [
        { label: labels.bankInfo.bankAccountHolder, value: name },
        { label: labels.bankInfo.bankAccountIban, value: bankAccountIban },
      ]
    }
  />
  ,
];

UserData.propTypes = {
  isDeleted: PropTypes.bool,
  customerNumber: PropTypes.string,
  email: PropTypes.string,
  unconfirmedEmail: PropTypes.string,
  name: PropTypes.string,
  fullName: PropTypes.string,
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  postalCode: PropTypes.string,
  town: PropTypes.string,
  country: PropTypes.string,
  dateOfBirth: PropTypes.string,
  placeOfBirth: PropTypes.string,
  countryOfBirth: PropTypes.string,
  nationality: PropTypes.string,
  taxCountry: PropTypes.string,
  taxIdentNumber: PropTypes.string,
  additionalTaxCountry: PropTypes.string,
  additionalTaxIdentNumber: PropTypes.string,
  mobilePhoneNumber: PropTypes.string,
  landlinePhoneNumber: PropTypes.string,
  initialSavingsRate: PropTypes.string,
  initialOrder: PropTypes.string,
  bankAccountHolder: PropTypes.string,
  bankAccountIban: PropTypes.string,
};

export default connect(mapStateToProps)(UserData);
