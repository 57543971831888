export default [
  'title',
  'value',
  'current_step',
  'currentStep',
  'portfolio_order_status',
  'portfolioOrderStatus',
  'order_type',
  'orderType',
];
