/* eslint-disable */

import React from 'react';

//TODO: how to outsource those texts into constants?
const Sepa = () => (
  <ul>
    <li>
      <p>
        Zugleich weise ich mein Kreditinstitut an, die von der FFB auf mein Konto gezogenen Lastschriften einzulösen. Die Gläubiger ID der FFB lautet DE57ZZZ00000130378. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.
      </p>
    </li>
  </ul>
);

export default Sepa;
