import * as t from '@/actions/types';

const initialState = {
  showModal: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.SHOW_MODAL:
      return {
        showModal: true,
        ...payload,
      };

    case t.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};

export function getModal(state) {
  return state.ui.alerts.modal;
}
