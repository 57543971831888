/* eslint-disable max-len */

// everything related to login, signup or password changes
export const loginSignUp = {
  customerNumber: 'Kundennummer',
  email: 'E-Mail',
  emailAddress: 'E-Mail-Addresse',
  emailConfirm: 'E-Mail-Adresse wiederholen',
  unconfirmedEmail: 'E-Mail (unbestätigt)',
  password: 'Passwort',
  createPassword: 'Passwort erstellen',
  passwordConfirmation: 'Passwort wiederholen',
  submitPassword: 'Passwort festlegen',
  currentPassword: 'Ihr aktuelles Passwort',
  newPassword: 'Neues Passwort',
  newPasswordConfirmation: 'Neues Passwort wiederholen',
  resetPassword: 'Passwort zurücksetzen',
  resetPasswordLink: 'Passwort vergessen?',
  privacyPolicyFirst: 'Ich habe den ',
  privacyPolicyLinkLabel: 'Datenschutzhinweis',
  privacyPolicyLast: ' gelesen und akzeptiert.',
  buttonSignUp: 'Los geht\'s!',
  buttonLogin: 'Anmelden',
};

export const personalInfo = {
  firstName: 'Vorname',
  lastName: 'Nachname',
  middleNames: 'Weitere(r) Vorname(n)',
  noMiddleNames: 'Ich habe keinen weiteren Vornamen',
  fullName: 'Vor- und Nachname',
  title: 'Titel',
  gender: 'Anrede',
  birthName: 'Geburtsname',
  dateOfBirth: 'Geburtsdatum',
  placeOfBirth: 'Geburtsort',
  countryOfBirth: 'Geburtsland',
  nationalityCountry: 'Staatsangehörigkeit',
  taxCountry: 'Steuerland',
  taxIdentNumber: 'Steuer-Identifikationsnummer',
  additionalTaxCountry: 'Weiteres Steuerland',
  additionalTaxIdentNumber: 'Steuernummer für weiteres Steuerland',
};

export const contactInfo = {
  streetName: 'Straße',
  streetNumber: 'Hausnummer',
  streetNameAndNumber: 'Straße und Hausnr.',
  postalCode: 'Postleitzahl',
  town: 'Ort',
  countryCode: 'Land',
  germany: 'Deutschland',
  mobilePhoneNumber: 'Telefonnummer (Mobil)',
  landlinePhoneNumber: 'Telefonnummer (Festnetz)',
  prefix: '+49',
};

export const bankInfo = {
  bankAccountHolder: 'Kontoinhaber',
  bankAccountIban: 'IBAN',
  info: 'Wir übertragen Ihre Sparvorhaben von Ihrem Konto auf Ihr Depot. Dafür benötigen wir ein Referenzkonto von Ihnen. Ihre Bankverbindung wird nach erfolgreicher Legitimation an unsere Partnerbank, die FFB, weitergeleitet, die Ihre Sparbeträge und/oder Einmalzahlung abbucht.',
};

export const initialInvestment = {
  initialOrder: 'Einmalanlage',
  initialSavingsRate: 'Monatliche Sparrate',
};

export const changeEmail = {
  description: 'Bitte geben Sie Ihre neue E-Mail-Adresse an.',
  confirmDescription: 'Bitte bestätigen Sie Ihre neue E-Mail-Adresse durch Angabe Ihres Passworts',
  submit: 'E-Mail-Adresse ändern',
};

export const savingsRate = {
  ratePlaceholder: 'Monatliche Sparrate',
};

export const legal = {
  contractAgreementTitle: 'Schlusserklärung',
  contractAgreement: 'Ich bestätige, dass das Depot auf eigene Rechnung geführt wird.',
  contractAgreementLink: 'Gesamte AGB lesen',
  personalDataTitle: 'Datenweitergabe',
  personalData: 'Ich bin damit einverstanden, dass die FIL Fondsbank GmbH meine angegebenen Daten zur Kundenbetreuung nutzt (z.B. um mich bei Rückfragen kontaktieren zu können) und zur Prüfung meiner Identität an die Deutsche Post weiterleitet. Kommt kein Vertrag zustande, werden meine Daten nach drei Monaten gelöscht.',
  personalDataLink: 'Klausel zur Datenweitergabe lesen',
  rightOfWithdrawalTitle: 'Widerrufsrecht',
  rightOfWithdrawal: '',
  rightOfWithdrawalLink: 'Widerrufsrecht lesen',
  sepaDebitTermTitle: 'SEPA-Lastschriftmandat',
  sepaDebitTerm: 'Ich ermächtige die FFB, Zahlungen von meinem Konto mittels Lastschrift einzuziehen.',
  sepaDebitTermLink: 'SEPA-Mandat anzeigen',
  dfgAgreementsTitle: 'Allgemeine Geschäftsbedingungen der DFG',
  dfgAgreements: 'Ich habe die Allgemeinen Geschäftsbedingungen der DFG gelesen.',
  dfgAgreementsLink: 'Allgemeine Geschäftsbedingungen der DFG lesen',
  dfgRightOfWithdrawalTitle: 'Widerrufsbelehrung der DFG',
  dfgRightOfWithdrawal: 'Ich habe die Widerrufsbelehrung der DFG gelesen.',
  dfgRightOfWithdrawalLink: 'Widerrufsbelehrung der DFG lesen',
  dfgPrivacyPolicyTitle: 'Datenschutzhinweise der DFG',
  dfgPrivacyPolicy: 'Ich habe die Datenschutzhinweise der DFG gelesen.',
  dfgPrivacyPolicyLink: 'Datenschutzhinweise der DFG lesen',
  dfgContractInfoTitle: 'Wichtige vorvertragliche Informationen der DFG',
  dfgContractInfo: 'Ich habe die vorvertragliche Informationen der DFG gelesen.',
  dfgContractInfoLink: 'vorvertragliche Informationen der DFG lesen',
  dfgInvestmentAdviceTitle: 'Hinweis: Keine Anlageberatung / keine Angemessenheitsprüfung',
  dfgInvestmentAdvice: '',
  dfgInvestmentAdviceLink: 'Hinweis lesen',

  dfgAgreementSeparatorHeadline: 'Einverständniserklärungen der DFG',
  ffbAgreementSeparatorHeadline: 'Bitte lesen und bestätigen Sie die FFB Geschäftsbedingungen',

  info: 'Bitte lesen und bestätigen Sie unsere Geschäftsbedingungen. Wir haben Ihnen bereits alle wichtigen Unterlagen per E-Mail zugesendet.',
  legend: 'Geschäftsbedingungen',
};

export const payInPayOut = {
  payInAmount: 'Einzahlungsbetrag',
  payOutAmount: 'Auszahlungsbetrag',
  submitPayIn: 'Betrag einzahlen',
  submitPayOut: 'Betrag auszahlen',
};

export const activationCode = {
  activationCodeEmail: 'E-Mail',
  submitActivationCode: 'Neuen Aktivierungscode zuschicken',
};

export const activationModal = {
  confirmationToken: 'Aktivierungscode',
  confirmAccountText:
    'Wir haben Ihnen einen Code per E-Mail an %(interpolation)s geschickt. Bitte geben Sie diesen Code unten ein:',
};

export const orderConfirmation = {
  yourPass: 'Ihr Passwort',
};
