let index = 0;

export function getNext() {
    index += 1;
    return index;
}

export function reset() {
    index = 0;
}
