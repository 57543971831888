export const FETCHED_USER_INFO = 'request/FETCHED_USER_INFO';
export const FETCHED_USER_PORTFOLIO = 'request/FETCHED_USER_PORTFOLIO';
export const FETCHED_ORDER_HISTORY = 'request/FETCHED_ORDER_HISTORY';
export const FETCH_FORM_OPTIONS = 'request/FETCH_FORM_OPTIONS';
export const FETCHED_FORM_OPTIONS = 'request/FETCHED_FORM_OPTIONS';
export const FETCHED_SAVINGS_RATE = 'request/FETCHED_SAVINGS_RATE';
export const SET_MESSAGE_ID = 'request/SET_MESSAGE_ID';
export const FETCHED_MESSAGE = 'request/FETCHED_MESSAGE';
export const FETCHED_MESSAGE_ATTACHMENT = 'request/FETCHED_MESSAGE_ATTACHMENT';
export const FETCHED_MESSAGES = 'request/FETCHED_MESSAGES';
export const FETCHED_LEGAL_AGREEMENTS = 'request/FETCHED_LEGAL_AGREEMENTS';
export const FETCHED_LEGAL_AGREEMENT_STATUS = 'request/FETCHED_LEGAL_AGREEMENT_STATUS';
export const FETCHED_GRAPH_SETTINGS = 'request/FETCHED_GRAPH_SETTINGS';

export const VALIDATE_IBAN = 'request/VALIDATE_IBAN';
