import { combineReducers } from 'redux';

import all from './all';
import detail from './detail';

export default combineReducers({
  all,
  detail,
});

export * from './all';
export * from './detail';
