import React from 'react';
import PropTypes from 'prop-types';
import * as texts from "@/constants/texts";

function leadingZero(number) {
  return (number < 10 ? '0' : '') + number;
}

function secondsToMinutes(time) {
  return Math.floor(time / 60) + ' min ' + leadingZero(Math.floor(time % 60)) + ' sek';
}

const SessionTimer = ({ time }) => (
  <div className='session-timer'>
    <img
      className='session-timer__image'
      src='/static/images/timeout.svg'
      alt='timeout'
    />
    {texts.otherTexts.sessionTimer}
    <span className='session-timer__count'>{secondsToMinutes(time)}</span>
  </div>
);

SessionTimer.propTypes = {
  time: PropTypes.number,
};

export default SessionTimer;
