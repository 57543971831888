import * as t from '@/actions/types';
import { userSessionTimeout } from '../../constants/numbers';

const initialState = userSessionTimeout;

export default(state = initialState, action) => {
  switch(action.type) {
  case t.USER_SESSION_TIMER_TICK:
    return Math.max(state - 1, 0);
  case t.USER_SESSION_TIMER_RESET:
    return initialState;
  default:
    return state;
  }
};
