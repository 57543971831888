import * as t from '@/actions/types';

const initialState = [];

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.ADD_ERROR:
      return [...state, payload.error];

    case t.REMOVE_ERROR:
      const stateClone = [...state];
      stateClone[payload.id] = undefined;
      return stateClone;

    default:
      return state;
  }
};

export function getGlobalErrors(state) {
  return state.ui.errors.global;
}
