import React from 'react';
import PropTypes from 'prop-types';

import { orderTypes } from '@/constants/texts';
import * as texts from '@/constants/texts';

import classnames from 'classnames';
import { formatDateString } from '@/helpers/dates';
import * as currency from '@/helpers/currency';

const DepositHistory = ({
  orders = [],
  limit,
  labels = false,
}) => (
  <div className='deposit-history'>
    <ul className='deposit-history__list'>
      {orders.slice(0, limit).map((order, index) => {
        const panelClasses = classnames('deposit-history__list-item', {
          'deposit-history__list-item--buy': order.orderType === 'buy' || order.orderType === 'saving',
          'deposit-history__list-item--sell': (order.orderType === 'sell' || order.orderType === 'totalSell'),
        });

        const orderValue = currency.formatDisplayValue(order.orderValue);
        const orderPrefix = (
          order.orderType.indexOf('sell') !== -1 || order.orderType.indexOf('totalSell') !== -1) ? '-' : '';
        return (
          <li key={`${order.id}-${index}`} className={panelClasses}>
            <div className='deposit-history__column'>
              {labels ?
                <ul className='deposit-history__date deposit-history__date--listed'>
                  <li key="createdAt">
                    {texts.orders.createdAt}:
                    <time dateTime={order.createdAt}> {formatDateString(order.createdAt)}</time>
                  </li>
                  <li key="valutaDate">
                    {texts.orders.valutaDate}:
                    <time dateTime={order.valutaDate}>
                      {order.valutaDate ? ' ' + formatDateString(order.valutaDate) : ' -'}
                    </time>
                  </li>
                </ul>
              :
                <time className='deposit-history__date' dateTime={order.createdAt}>
                  {formatDateString(order.createdAt)}
                </time>
              }
            </div>
            <dl className='deposit-history__column'>
              <dt className='deposit-history__type'>{orderTypes[order.orderType]}</dt>
              <dd className='deposit-history__amount'>{orderPrefix + orderValue}</dd>
            </dl>
          </li>
      );})}
    </ul>
  </div>
);

DepositHistory.propTypes = {
  orders: PropTypes.array,
  limit: PropTypes.number,
  labels: PropTypes.bool,
};

export default DepositHistory;
