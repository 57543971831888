import React from 'react';
import PropTypes from 'prop-types';

const AgreementsSeparator = ({
  children, headline,
}) =>
  (
    <div className='agreements-separator'>
      <div className='agreements-separator__headline'>{headline}</div>
      <div className='agreements-separator__wrapper'>
        {children}
      </div>
    </div>
  );

export default AgreementsSeparator;

AgreementsSeparator.propTypes = {
  headline: PropTypes.string,
  children: PropTypes.node,
};
