/*
  Usage: get standardized strings of type XX,YY €
*/
export function formatDisplayValue(value, appendCurrencySymbol = true) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = value.toString();

    value = value.replace(',', '.');
    // round to 2 decimal places
    value = (Math.round(value * 100) / 100).toString();

    let valueParts;
    if (value.indexOf('.') !== -1) {
      valueParts = value.split('.');
    } else {
      valueParts = value.split(',');
    }

    if (valueParts.length === 1) {
      valueParts.push('00');
    }

    while (valueParts[1].length < 2) {
      valueParts[1] += '0';
    }

    let valueString = valueParts.join(',');
    if (appendCurrencySymbol) {
      valueString += ' €';
    }

    return valueString;
  } else {
    return '';
  }
}

/*
  Usage: redux-form currency fields in combination with input type number
*/
export function normalizeInput(value, previousValue) {
  const commaValue = value.replace('.', ',');

  return /^((\d)+(,)?(\d){0,2})?$/.test(commaValue) ? commaValue : previousValue;
}

export function normalizeNoDecimalPlaces(value, previousValue) {
  return /^(\s*|[0-9][0-9]*)$/.test(value) ? value.trim() : previousValue;
}
