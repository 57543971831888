import React from "react";
import LabeledInput from '@/components/generic/LabeledInput';
import { Field } from 'redux-form';

const TextInput = props => {
  return (
    <div className='visibility-input'>
      <Field
        name='email2'
        type='text'
        label='email2'
        required={false}
        component={LabeledInput}
        hasEmpty={true}
        className={'visibility-input'}
      />
    </div>
  );
};

export default TextInput;
