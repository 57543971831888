import React from 'react';
import PropTypes from 'prop-types';
import '@/stylesheets/manifest.scss';

const InputContext = ({
  hint = '',
  errors = [],
  children,
}) => {

  return (
    <div className='input-context'>
      {children}

      {errors.length > 0 &&
        <div className='input-context__error'>
          {errors[0]}
        </div>
      }

      {hint &&
        <div className='input-context__hint'>
          {hint}
        </div>
      }
    </div>
  );
};

InputContext.propTypes = {
  hint: PropTypes.string,
  errors: PropTypes.array,
  children: PropTypes.element,
};

export default InputContext;
