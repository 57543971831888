import React from 'react';
import PropTypes from 'prop-types';

const Form = ({
  onSubmit,
  legend = '',
  info = '',
  errors = {},
  children,
}) =>
  (
    <form onSubmit={onSubmit} className='form' noValidate={true}>
      <fieldset className='form__fieldset'>
        {legend &&
          <legend className='form__legend'>{legend}</legend>
        }
        {info &&
          <p className='form__info'>{info}</p>
        }
        {errors.global &&
          <div className='form__global-errors'>{errors.global}</div>
        }
        {children}
      </fieldset>
    </form>
  );

export default Form;

Form.propTypes = {
  onSubmit: PropTypes.func,
  legend: PropTypes.string,
  info: PropTypes.string,
  errors: PropTypes.object,
  children: PropTypes.node,
};
