import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as actions from '@/actions/creators';
import { changeEmail as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';
import * as selectors from '@/reducers/selectors';

import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import Button from '@/components/generic/Button';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'confirmChangedEmail'),
  initialValues: {
    password: '',
    confirmationToken: state.location.query.confirmation_token,
    email: state.location.query.email,
  },
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.confirmChangedEmail());
  },
});

const ConfirmChangedEmailForm = ({ onSubmit, errors = {} }) => (
  <Form onSubmit={onSubmit} legend={texts.headings.others.confirmEmail}>
    <Field
      name='password'
      type='password'
      label={labels.confirmDescription}
      required={true}
      component={LabeledInput}
      errors={errors.password}
    />
    <Field
      name='confirmationToken'
      type='hidden'
      component='input'
    />
    <RequiredHint />
    <Field
      name='email'
      type='hidden'
      component='input'
    />
    <Button type='submit'>{labels.submit}</Button>
  </Form>
);

ConfirmChangedEmailForm.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'confirmChangedEmail',
  }),
)(ConfirmChangedEmailForm);
