import * as t from '@/actions/types';

const initialState = {
  orderType: '',
  message: null,
  isTotalSellConfirmed: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.SET_ORDER_VALIDATION:
      return Object.assign({}, state, payload);

    case t.CONFIRM_TOTAL_SELL:
      return Object.assign({}, state, { isTotalSellConfirmed: true });

    case t.RESET_ORDER_VALIDATION:
      return initialState;

    default:
      return state;
  }
};

export function getOrderValidation(state) {
  return state.finance.orderValidation;
}

export function isTotalSellConfirmed(state) {
  return state.finance.orderValidation.isTotalSellConfirmed;
}
