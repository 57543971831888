import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as selectors from '@/reducers/selectors';

import AccountLayout from '@/components/specific/AccountLayout';
import InboxLayout from '@/components/specific/InboxLayout';
import InboxList from '@/components/specific/InboxList';
import InboxDetail from '@/components/specific/InboxDetail';

const mapStateToProps = (state) => {
  return {
    messages: selectors.getAllUserMessages(state),
  };
};

const Inbox = ({ messages }) => (
  <AccountLayout>
    <InboxLayout>
      <InboxList messages={messages} />
      <InboxDetail />
    </InboxLayout>
  </AccountLayout>
);

Inbox.propTypes = {
  messages: PropTypes.array,
};

export default connect(mapStateToProps)(Inbox);
