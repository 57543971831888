import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import * as actions from '@/actions/creators';

import { orderRestrictions as errorTexts } from '@/constants/errors';
import * as texts from '@/constants/texts';
import { savingsRate as labels } from '@/constants/forms';

import * as currency from '@/helpers/currency';
import * as selectors from '@/reducers/selectors';

import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import LabeledSelect from "@/components/generic/LabeledSelect";
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const savingsRate = selectors.getSavingsRate(state);
  const formValues = getFormValues('savingsRate')(state) || {};
  const dayOfMonth = savingsRate.dayOfMonth || 1;
  const monthlyRate = (savingsRate.monthlyRate || '').toString();

  return {
    hasSetSavingsRate: parseInt(savingsRate.monthlyRate, 10) !== 0,
    formattedCurrentRate: currency.formatDisplayValue(savingsRate.monthlyRate),
    formInput: formValues.monthlyRate,
    errors: selectors.getFormErrors(state, 'savingsRate'),
    canCreateOrders: selectors.getUserCanCreateOrders(state),
    initialValues: {
      dayOfMonth,
      monthlyRate,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch((dispatch, getState) => {
      const {monthlyRate, dayOfMonth} = getFormValues('savingsRate')(getState());

      dispatch(actions.resetSavingsRateValidation());
      dispatch(actions.validateSavingsRate(
        actions.setSavingsRateValidation,
        actions.showOrderConfirmationModal.bind({}, 'savingsRate', monthlyRate, dayOfMonth)));
    });
  },
  onCancel: () => dispatch(actions.redirectDashboard()),
});

const SavingsRate = ({
  hasSetSavingsRate,
  canCreateOrders = true,
  formattedCurrentRate,
  formInput,
  errors = {},
  onSubmit,
  onCancel,
}) => (
  <Form
    onSubmit={onSubmit}
    legend={texts.headings.others.savingsRate}
    info={texts.hints.rateInfo}
    key='savingsRateForm'
  >
    {!canCreateOrders && <p>{errorTexts.savingsRate}</p>}
    <Field
      name='monthlyRate'
      type='text'
      normalize={currency.normalizeNoDecimalPlaces}
      prefix='€'
      icon='pencil'
      label={labels.ratePlaceholder}
      required={true}
      component={LabeledInput}
      errors={errors.monthlyRate}
      disabled={!canCreateOrders}
    />
    <Field
      key='dayOfMonth'
      name='dayOfMonth'
      label={texts.savingsRate.transactionDate}
      required={true}
      component={LabeledSelect}
      options={[{label: 'am 1. eines Monats', value: 1}, {label: 'am 15. eines Monats', value: 15}]}
      hint={texts.hints.savingsRateDayOfMonth}
    />
    <RequiredHint />
    <Button type='submit' disabled={!canCreateOrders || (!formInput && formInput !== '')}>
      {texts.savingsRate.adjustSavingsRate}
    </Button>
    <BackButton onClick={onCancel}>
      {texts.otherTexts.cancel}
    </BackButton>
  </Form>
);

SavingsRate.propTypes = {
  hasSetSavingsRate: PropTypes.bool,
  canCreateOrders: PropTypes.bool,
  formattedCurrentRate: PropTypes.string,
  formInput: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'savingsRate',
    enableReinitialize: true,
  }),
)(SavingsRate);
