export function isServerError(error) {
  if (!Array.isArray(error)) return true;

  if (!error[1]) return true;

  if (!error[1].status) return true;

  if (error[1].status === 500) return true;

  return false;
};
