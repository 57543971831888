import React from 'react';
import PropTypes from 'prop-types';

import InputContext from '@/components/generic/InputContext';

const Select = ({
  required = false,
  hasEmpty = false,
  disabled = false,
  label = '',
  options = [],
  errors = [],
  hint = '',
  meta = {},
  input = {},
}) => (
  <InputContext hint={hint} errors={errors}>
    <div className='labeled-select'>
      <label htmlFor={input.name} className='labeled-select__label'>{label} {required && ' *'}</label>
      <select
        id={input.name}
        className='labeled-select__select'
        disabled={disabled}
        {...input}
      >
        {hasEmpty && <option value='' />}
        {options.map(option => (
          <option key={`${option.value}-${option.label}`} value={option.value}>{option.label}</option>
        ))}
      </select>
      <div
        className={`labeled-select__select-wrapper ${errors.length > 0 && 'labeled-select__select-wrapper--error'}`}
      />
    </div>
  </InputContext>
);
Select.propTypes = {
  required: PropTypes.bool,
  hasEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classNamePrefix: PropTypes.string,
  options: PropTypes.array,
  errors: PropTypes.array,
  hint: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

export default Select;
