import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      return payload.userInfo.bankInformation;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserBankInfo(state) {
  return state.user.bankInfo;
}
