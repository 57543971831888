import { combineReducers } from 'redux';

import resetPasswordToken from './resetPasswordToken';
import role from './role';
import token from './token';
import timerReducer from './timer';

export default combineReducers({
  resetPasswordToken,
  role,
  token,
  timer: timerReducer,
});

export * from './resetPasswordToken';
export * from './role';
export * from './token';
