import * as t from '@/actions/types';
import * as emailSelectors from '@/reducers/user/email';

const initialState = {
  streetName: '',
  streetNumber: '',
  postalCode: '',
  town: '',
  countryCode: 'DE',
  mobilePhoneNumber: '',
  landlinePhoneNumber: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      // Ignore empty contactInformation
      if (payload.userInfo.contactInformation.streetName === null) {
        return initialState;
      }
      return payload.userInfo.contactInformation;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserContactInfo(state) {
  return state.user.contactInfo;
}

export function getFormattedContactInfo(state) {
  const contactInfo = getUserContactInfo(state);
  return {
    ...contactInfo,
    country: 'Deutschland',
    email: emailSelectors.getUserEmail(state),
    unconfirmedEmail: emailSelectors.getUserUnconfirmedEmail(state),
  };
}
