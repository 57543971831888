import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';
import HelpText from '@/components/generic/HelpText';
import ConfirmActivationCodeForm from '@/components/forms/ConfirmActivationCodeForm';
import { confirmActivationCode as texts } from '@/constants/texts';

const ConfirmActivationCode = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={0} />
      <HelpText>{texts.help}</HelpText>
    </HelpArea>
    <ConfirmActivationCodeForm />
  </OnboardingLayout>
);

export default ConfirmActivationCode;
