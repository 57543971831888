import axios from 'axios';
import { convertCasing } from '@/helpers/casing';

export default function ajax(url, method = 'get', credentials, data, responseType, client = axios) {
  let config = {
    url,
    method,
    transformRequest: [(data = {}) => {
      const convertedData = convertCasing(data, 'snakeCase', 'camelCase');
      return JSON.stringify(convertedData);
    }],
    transformResponse: [(data = '{}', headers) => {
      if (headers['content-type'] && headers['content-type'].indexOf('json') !== -1) {
        const parsedData = JSON.parse(data);
        const convertedData = convertCasing(parsedData, 'camelCase', 'snakeCase');
        return convertedData;
      }
      return data;
    }],
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (credentials) {
    config.headers['X-User-ID'] = credentials.userId;
    config.headers['X-User-Token'] = credentials.token;
  }

  if (responseType) {
    config.responseType = responseType;
  }

  if (method === 'get' || method === 'delete') {
    config.params = data;
  } else {
    config.data = data;
  }

  return new Promise(async (resolve, reject) => {
    let response;
    try {
      response = await client(config);
      resolve([response.data, response]);
    } catch(error) {
      if (error.response.status < 500) {
        return reject([error.response.data, error.response]);
      }
      return reject({ status: 500, message: 'Server unavailable' });
    }
  });
}
