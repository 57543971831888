import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from "history";
// import createHistory from 'history/createBrowserHistory';
import { connectRoutes } from 'redux-first-router';
import { reducer as formReducer } from 'redux-form';

import routes from '@/router';
import routerOptions from '@/router/options';
import * as reducers from '@/reducers';
import * as middlewareModules from '@/middleware';

const customMiddleware = Object.values(middlewareModules);

// Configuration for redux devtools
const env = process.env.NODE_ENV;

const composeEnhancers =
    env === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    compose;

// Setup redux-first-router
// const history = createHistory();
const history = createBrowserHistory();
const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
} = connectRoutes(history, routes, routerOptions);

// Generate store from reducers and enhancers
const combinedReducers = combineReducers({
    location: routerReducer,
    form: formReducer,
    ...reducers,
});
const middleware = applyMiddleware(routerMiddleware, thunk, ...customMiddleware);
const enhancers = composeEnhancers(routerEnhancer, middleware);
const store = createStore(combinedReducers, enhancers);

export default store;