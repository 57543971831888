import React from 'react';
import PropTypes from 'prop-types';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';

import ConfirmChangedEmailForm from '@/components/forms/ConfirmChangedEmailForm';

const ConfirmChangedEmail = ({ onSubmit, errors = {} }) => (
  <OnboardingLayout>
    <HelpArea />
    <ConfirmChangedEmailForm />
  </OnboardingLayout>
);

ConfirmChangedEmail.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ConfirmChangedEmail;
