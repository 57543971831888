import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'redux-first-router-link';

import classnames from 'classnames';
import * as actions from '@/actions/creators';

import * as selectors from '@/reducers/selectors';
import { DASHBOARD, USER_INFO, DELETE_ACCOUNT, INBOX_OVERVIEW, INBOX_DETAIL } from '@/router';

import * as texts from '@/constants/texts';

import LogoutButton from '@/components/generic/LogoutButton';

const mapStateToProps = (state) => ({
  unreadMessageCount: selectors.getUserMessageUnreadCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.submitLogout()),
});

class AccountLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
  }

  handleExpand(expand) {
    this.setState({
      isExpanded: expand,
    });
  }

  render() {
    const accountNavClasses = classnames('account-layout__nav', {
      'account-layout__nav--active': this.state.isExpanded,
    });

    return (
      <div className='account-layout'>
        <LogoutButton onClick={this.props.onLogout}>Logout</LogoutButton>

        <div className={accountNavClasses}
          onMouseEnter={this.handleExpand.bind(this, true)}
          onMouseLeave={this.handleExpand.bind(this, false)}
          onFocus={this.handleExpand.bind(this, true)}
          onBlur={this.handleExpand.bind(this, false)}
        >
          <nav>
            {
              // eslint-disable-next-line jsx-a11y/heading-has-content
            }<h2 className='account-layout__nav-headline'>Navigation</h2>
            <ul className='account-layout__nav-list'>
              <li className='account-layout__nav-item'>
                <NavLink
                  activeClassName='account-layout__link--active'
                  className='account-layout__link account-layout__link--home'
                  to={{ type: DASHBOARD }}
                  isActive={(match, location) => location.type === DASHBOARD}
                >
                  <span className='account-layout__label'>Benutzerkonto</span>
                </NavLink>
              </li>

              {!this.props.isViewingData &&
                <li className='account-layout__nav-item'>
                  <NavLink
                    activeClassName='account-layout__link--active'
                    className='account-layout__link account-layout__link--profile'
                    to={{ type: USER_INFO }}
                    isActive={(match, location) => location.type === USER_INFO || location.type === DELETE_ACCOUNT}
                  >
                    <span className='account-layout__label'>{texts.otherTexts.myData}</span>
                  </NavLink>
                </li>
              }

              <li className='account-layout__nav-item'>
                <NavLink
                  activeClassName='account-layout__link--active'
                  className='account-layout__link account-layout__link--inbox'
                  to={{ type: INBOX_OVERVIEW }}
                  isActive={(match, location) => location.type === INBOX_OVERVIEW || location.type === INBOX_DETAIL}
                >
                  <span className='account-layout__label'>
                    {texts.inbox.title}
                  </span>

                  {this.props.unreadMessageCount > 0 &&
                    <span className='account-layout__unread-message'>
                      <span className='account-layout__bracket'> (</span>
                      {this.props.unreadMessageCount}
                      <span className='account-layout__bracket'>)</span>
                    </span>
                  }
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>

        <main className='account-layout__main'>
          {this.props.children}
        </main>

        <footer className='account-layout__footer'>
          <a
            className='account-layout__footer-link'
            href='https://www.der-zukunftsfonds.de/impressum'
          >
            {texts.otherTexts.imprint}
          </a>
        </footer>
      </div>
    );
  }
}

AccountLayout.propTypes = {
  unreadMessageCount: PropTypes.number,
  children: PropTypes.node,
  isViewingData: PropTypes.bool,
  onLogout: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);
