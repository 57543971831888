import { format, isValid } from 'date-fns';
import de from 'date-fns/locale/de';
// TODO: use date-fns ES modules as soon as they are ready to decrease the package size

export function formatDateString(date, useMonthName = false) {
  const parsedDate = new Date(date);
  const formatting = useMonthName ? 'D. MMMM YYYY' : 'DD.MM.YYYY';

  if (!isValid(parsedDate)) return '';

  return format(parsedDate, formatting, { locale: de });
}
