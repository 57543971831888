export const VALIDATION_ERROR = 'errors/VALIDATION_ERROR';
export const RESET_ERRORS = 'errors/RESET_ERRORS';
export const ADD_ERROR = 'errors/ADD_ERROR';
export const REMOVE_ERROR = 'errors/REMOVE_ERROR';

export const ADD_ALERT = 'alerts/ADD_ALERT';
export const REMOVE_ALERT = 'alerts/REMOVE_ALERT';
export const SHOW_MODAL = 'alerts/SHOW_MODAL';
export const HIDE_MODAL = 'alerts/HIDE_MODAL';

export const SET_LOADING = 'loading/SET_LOADING';
export const RESET_LOADING = 'loading/RESET_LOADING';
export const TOGGLE_LEGAL_DETAILS = 'onboarding/TOGGLE_LEGAL_DETAILS';
export const SHOW_ADDITIONAL_TAX_COUNTRY = 'onboarding/SHOW_ADDITIONAL_TAX_COUNTRY';
