import * as t from './types';

export function track(eventName) {
  return () => ({
    type: t.TRACK_EVENT,
    payload: {
      eventName,
    },
  });
}
