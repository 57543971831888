export function amendValues(formName, values = {}) {
  const updateValues = {};
  switch(formName) {
    case 'contactInfo':
    case 'changeContactInfo':
      if (values.mobilePhoneNumber) {
        updateValues.mobilePhoneNumber = '+49' + values.mobilePhoneNumber;
      }
      if (values.landlinePhoneNumber) {
        updateValues.landlinePhoneNumber = '+49' + values.landlinePhoneNumber;
      }
      break;
    case 'personalInfo':
    case 'changePersonalInfo':
      if (values.userRole === 'customer') {
        /*
          These values are immutable for customers and should not be sent
          to the API.
        */
        updateValues.firstName = undefined;
        updateValues.lastName = undefined;
        updateValues.birthName = undefined;
        updateValues.dateOfBirth = undefined;
        updateValues.placeOfBirth = undefined;
        updateValues.countryOfBirthCode = undefined;
      }
      updateValues.userRole = undefined;
      break;
    case 'payOut':
    case 'payIn':
      if (values.orderValue) {
        updateValues.orderValue = values.orderValue.replace(',', '.');
      } else {
        const order = values.order || { orderValue: '' };
        updateValues.order = {
          ...order,
          orderValue: order.orderValue.replace(',', '.'),
        };
      }
      break;
    case 'savingsRate':
      if (values.monthlyRate) {
        updateValues.monthlyRate = values.monthlyRate.toString().replace(',', '.');
      }
      break;
    case 'initialInvestment':
      values.initialOrder = values.initialOrder.toString();
      values.initialSavingsRate = values.initialSavingsRate.toString();
      updateValues.initialOrder = values.initialOrder.replace(',', '.');
      updateValues.initialSavingsRate = values.initialSavingsRate.replace(',', '.');
      break;
    default:
      break;
  }
  const newFormValues = Object.assign({}, values, updateValues);
  return newFormValues;
}
