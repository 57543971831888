import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SessionTimer from "@/components/generic/SessionTimer";

const DashboardFormLayout = ({ children, timer }) => (
  <div className='dashboard-form-layout'>
    <div className='dashboard-form-layout__session'>
      <SessionTimer time={timer} />
    </div>
    <div className='dashboard-form-layout__content'>
      {children}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  timer: state.login.timer,
});

DashboardFormLayout.propTypes = {
  children: PropTypes.node,
  timer: PropTypes.number,
};

export default connect(mapStateToProps)(DashboardFormLayout);
