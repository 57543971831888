import * as t from '@/actions/types';

const initialState = {
  period: 8,
  interval: 2,
  fee: 1.75,
  returnRate: 4.00,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_GRAPH_SETTINGS:
      return {
        ...state,
        ...payload,
      };

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getGraphSettings(state) {
  return state.onboarding.graphSettings;
}
