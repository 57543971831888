import * as t from '@/actions/types';

const initialState = [];

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_ORDER_HISTORY:
      return payload.orders;
    default:
      return state;
  }
};

export function getUserOrders(state) {
  return state.finance.orders || [];
}
