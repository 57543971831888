import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, isDirty } from 'redux-form';
import { redirect } from 'redux-first-router';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { otherTexts as otherLabels } from '@/constants/texts';
import { personalInfo as labels } from '@/constants/forms';
import { ENTER_CONTACT_INFO } from '@/router';

import LabeledInput from '@/components/generic/LabeledInput';
import LabeledSelect from '@/components/generic/LabeledSelect';
import LabeledCheckbox from '@/components/generic/LabeledCheckbox';
import Button from '@/components/generic/Button';
import Form from '@/components/generic/Form';
import SideBySide from '@/components/generic/SideBySide';
import BackButton from '@/components/generic/BackButton';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const formValues = getFormValues('personalInfo')(state) || {};
  const taxCountries = selectors.getFormOptions(state, 'taxCountryCode') || [];
  const personalInfo = selectors.getUserPersonalInfo(state);
  const userRole = selectors.getLoginRole(state);

  return {
    isDirty: isDirty('personalInfo')(state),
    errors: selectors.getFormErrors(state, 'personalInfo'),
    options: selectors.getFormOptions(state),
    selectedTaxCountry: taxCountries.find(nat => nat.value === formValues.taxCountryCode) || {},
    selectedAdditionalTaxCountry: taxCountries.find(nat => nat.value === formValues.additionalTaxCountryCode) || {},
    hasMiddleNames: formValues.noMiddleNames,
    userRole,
    hasAdditionalTaxCountry: selectors.getShowAdditionalTaxCountry(state),
    initialValues: {
      ...personalInfo,
    },
  };
};

const mapDispatchToProps = (dispatch, { userRole, isUpdate = true }) => ({
  onShowAdditionalTaxCountry: () => dispatch(actions.showAdditionalTaxCountry()),
  onSubmit: (e) => {
    const successActions = [
      actions.setUserDataForm.bind({}, 'personalInfo', false),
      actions.fetchData.bind({}, 'userInfo'),
    ];
    if (!isUpdate) {
      successActions.push(
        actions.fetchOnboardingStatusAndForward,
        actions.track('personalDataFilled'),
      );
    }

    e.preventDefault();
    dispatch(actions.submitPersonalInfo(userRole !== 'customer', isUpdate, successActions));
  },
  onPreviousStep: (isDirty) => {
    if (isDirty) {
      dispatch(actions.showModal({
        heading: otherLabels.previousStep,
        text: otherLabels.previousStepModalText,
        modalAction: redirect.bind({}, { type: ENTER_CONTACT_INFO }),
        showCloseButton: true,
      }));
    } else {
      dispatch(redirect({ type: ENTER_CONTACT_INFO }));
    }
  },
});

const PersonalInfoForm = ({
  showBackButton = false,
  isDirty = true,
  isUpdate = true,
  hasMiddleNames = false,
  hasAdditionalTaxCountry = false,
  userRole,
  legendText = '',
  submitButtonLabel = otherLabels.confirmChanges,
  selectedTaxCountry = {},
  selectedAdditionalTaxCountry = {},
  errors = {},
  options = {},
  onShowAdditionalTaxCountry,
  onSubmit,
  onPreviousStep,
}) => (
  <Form onSubmit={onSubmit} legend={legendText}>
    {isUpdate && [
      <Field
        key='gender'
        name='gender'
        label={labels.gender}
        required={true}
        component={LabeledSelect}
        options={options.gender}
        errors={errors.gender}
      />
      ,
      <Field
        key='title'
        name='title'
        label={labels.title}
        required={false}
        component={LabeledSelect}
        options={options.title}
        errors={errors.title}
        hasEmpty={true}
      />
      ,
      <Field
        key='firstName'
        name='firstName'
        type='text'
        maxLength='35'
        label={labels.firstName}
        required={true}
        component={LabeledInput}
        errors={errors.firstName}
        disabled={userRole === 'customer'}
      />
     ,
      <Field
        key='lastName'
        name='lastName'
        type='text'
        maxLength='35'
        label={labels.lastName}
        required={true}
        component={LabeledInput}
        errors={errors.lastName}
        disabled={userRole === 'customer'}
      />
      ,
    ]}

    <Field
      name='middleNames'
      type='text'
      maxLength='35'
      label={labels.middleNames}
      required={!hasMiddleNames}
      disabled={hasMiddleNames}
      component={LabeledInput}
      errors={errors.middleNames}
    />
    <Field
      name='noMiddleNames'
      label={labels.noMiddleNames}
      component={LabeledCheckbox}
      errors={errors.privacyPolicy}
    />
    <SideBySide>
      <Field
        name='dateOfBirth'
        type='date'
        label={labels.dateOfBirth}
        required={true}
        component={LabeledInput}
        errors={errors.dateOfBirth}
        disabled={userRole === 'customer'}
      />
    </SideBySide>
    <Field
      name='birthName'
      type='text'
      maxLength='40'
      label={labels.birthName}
      required={false}
      component={LabeledInput}
      errors={errors.birthName}
      disabled={userRole === 'customer'}
    />
    <Field
      name='placeOfBirth'
      type='text'
      maxLength='40'
      label={labels.placeOfBirth}
      required={true}
      component={LabeledInput}
      errors={errors.placeOfBirth}
      disabled={userRole === 'customer'}
    />
    <Field
      name='countryOfBirthCode'
      label={labels.countryOfBirth}
      required={true}
      hasEmpty={true}
      component={LabeledSelect}
      options={options.countryOfBirthCode}
      errors={errors.countryOfBirthCode}
      disabled={userRole === 'customer'}
    />
    <Field
      name='nationalityCountryCode'
      label={labels.nationalityCountry}
      required={true}
      hasEmpty={true}
      component={LabeledSelect}
      options={options.nationalityCountryCode}
      errors={errors.nationalityCountryCode}
    />
    <Field
      name='taxCountryCode'
      label={labels.taxCountry}
      required={true}
      hasEmpty={true}
      component={LabeledSelect}
      options={
        options.taxCountryCode
        ? options.taxCountryCode.filter(o => o.value !== selectedAdditionalTaxCountry.value)
        : []
      }
      errors={errors.taxCountryCode}
    />
    <Field
      name='taxIdentNumber'
      type='text'
      label={labels.taxIdentNumber}
      required={(selectedTaxCountry.value && selectedTaxCountry.value !== 'DE')}
      component={LabeledInput}
      errors={errors.taxIdentNumber}
    />
    {userRole !== 'customer' && hasAdditionalTaxCountry ? [
      <Field
        key='additionalTaxCountry'
        name='additionalTaxCountryCode'
        label={labels.additionalTaxCountry}
        component={LabeledSelect}
        hasEmpty={true}
        options={
          options.taxCountryCode
          ? options.taxCountryCode.filter(o => o.value !== selectedTaxCountry.value)
          : []
        }
        errors={errors.additionalTaxCountryCode}
      />,
      selectedAdditionalTaxCountry.value && (
        <Field
          key='additionalTaxIdentNumber'
          name='additionalTaxIdentNumber'
          type='text'
          label={labels.additionalTaxIdentNumber}
          required={(selectedTaxCountry.value && selectedTaxCountry.value !== 'DE')}
          component={LabeledInput}
          errors={errors.additionalTaxIdentNumber}
        />
      ),
    ] : (
      <Button onClick={onShowAdditionalTaxCountry} buttonStyle='plain'>
        {otherLabels.additionalTaxCountry}
      </Button>
    )}
    <RequiredHint />
    <Button type='submit'>
      {submitButtonLabel}
    </Button>
    {showBackButton &&
      <BackButton onClick={() => onPreviousStep(isDirty)}>
        {otherLabels.previousStep}
      </BackButton>
    }
  </Form>
);

PersonalInfoForm.propTypes = {
  showBackButton: PropTypes.bool,
  isDirty: PropTypes.bool,
  isUpdate: PropTypes.bool,
  hasAdditionalTaxCountry: PropTypes.bool,
  userRole: PropTypes.string,
  legendText: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  options: PropTypes.object,
  errors: PropTypes.object,
  hasMiddleNames: PropTypes.bool,
  selectedTaxCountry: PropTypes.object,
  selectedAdditionalTaxCountry: PropTypes.object,
  onShowAdditionalTaxCountry: PropTypes.func,
  onSubmit: PropTypes.func,
  onPreviousStep: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'personalInfo',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
)(PersonalInfoForm);
