import get from 'lodash-es/get';

import * as t from '@/actions/types';
import flow from '@/constants/onboarding';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_ONBOARDING_STATUS:
      return payload.status;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getOnboardingStatus(state) {
  return get(state, 'onboarding.status', {});
}

export function getOnboardingProgress(state) {
  const location = state.location.type;
  const status = getOnboardingStatus(state);
  const progress = {};

  flow.forEach(step => {
    progress[step.key] = {
      label: step.label,
    };
    if (step.routeAction === location) {
      progress[step.key].className = 'active';
    } else if (status[step.key]) {
      progress[step.key].className = 'completed';
    } else {
      progress[step.key].className = '';
    }
  });

  return progress;
}
