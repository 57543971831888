import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';

import { otherTexts as labels } from '@/constants/texts';
import * as texts from '@/constants/texts';
import PersonalInfoForm from '@/components/forms/PersonalInfoForm';
import HelpText from '@/components/generic/HelpText';

const PersonalInfo = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={0} />
      <HelpText>{texts.onboardingHelpTexts.personalInfo}</HelpText>
    </HelpArea>
    <PersonalInfoForm
      isUpdate={false}
      legendText={texts.headings.onboarding.personalInfo}
      submitButtonLabel={labels.nextStep}
      showBackButton={true}
    />
  </OnboardingLayout>
);

export default PersonalInfo;
