/* eslint-disable */

import React from 'react';

//TODO: how to outsource those texts into constants?
const PersonalData = () => (
  <ul>
    <li>
      <p>
        Ich beauftrage die FFB, die sich aus der Geschäftsbeziehung und ihrer Anbahnung ergebenden und erhobenen Daten (z.B. Identifikation, Depotdaten) an die DFG für deren Zwecke zu übermitteln. <a href="//www.ffb.de/public/datenschutzhinweise.html" target="_blank">Hier</a> gibt es mehr Informationen zum Datenschutz bei der FFB.
      </p>
      <p>
        Ich beauftrage die DFG mir den Depoteröffnungsantrag nach erfolgter Legitimation - vorbefüllt mit den von mir genannten Daten - von der FFB entgegenzunehmen und an die von mir angegebene E-Mail-Adresse zu übersenden. Ich bestätige der DFG eine allgemeine Postvollmacht erteilt zu haben.
      </p>
    </li>
  </ul>
);

export default PersonalData;
