import * as t from '@/actions/types';

const initialState = {
  contractAgreement: false,
  personalData: false,
  rightOfWithdrawal: false,
  sepaDebitTerm: false,
  dfgAgreements: false,
  dfgPrivacyPolicy: false,
  dfgContractInfo: false,
  dfgInvestmentAdvice: false,
  dfgRightOfWithdrawal: false,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      return Object.assign({}, state, payload.userInfo.legalAgreements);

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserLegalAgreements(state) {
  return state.user.legalAgreements;
}
