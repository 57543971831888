/* eslint-disable */

import React from 'react';

//TODO: how to outsource those texts into constants?
const DfgContractInfo = () => (
  <ul>
    <li>
      <h4>II.	Informationen zu Fernabsatzgeschäften</h4>
      <h5>1.	Identität, Hauptgeschäftstätigkeit, Anschrift und zuständige Erlaubnisbehörde der DFG</h5>
      <p>
        DFG Deutsche Fondsgesellschaft SE Invest<br/>
        Geschäftsführerin: Dr. Martha Böckenfeld<br />
        Schlüterstr. 40<br />
        10707 Berlin
      </p>
      <p>E-Mail: <a href='mailto:info@der-zukunftsfonds.de'>info@der-zukunftsfonds.de</a></p>
      <p>
        Telefon: +49 (0)30 400 065 39<br/>
        Telefax: +49 (0)30 288 858 97
      </p>
      <p>Die DFG Deutsche Fondsgesellschaft SE Invest (die „DFG“) besitzt eine Erlaubnis als Finanzanlagenvermittler nach § 34f Abs. 1 Satz 1 Nr. 1 GewO für Anteile oder Aktien an inländischen offenen EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen. Die DFG ist in das Vermittlerregister des Deutschen Industrie- und Handelskammertags unter der Registrierungsnummer D-F-107-LCJ5-32 eingetragen.</p>
      <p>Die für die DFG zuständige Erlaubnisbehörde ist das Bezirksamt Friedrichshain-Kreuzberg von Berlin, Petersburger Straße 86-90, 10247 Berlin, <a href='https://www.berlin.de/ba-friedrichshain-kreuzberg/'>https://www.berlin.de/ba-friedrichshain-kreuzberg/</a></p>
      <h5>2.	Wesentliche Merkmale der Finanzdienstleistung und Hinweise zum Vertragsschluss</h5>
      <p>Die DFG betreibt unter der Webadresse „www.der-zukunftsfonds.de“ eine Webseite (die „Webseite“). Über die Webseite bemüht sich die DFG um eine Vermittlung von Geschäften über die Anschaffung und Veräußerung von Anteilen an „Der Zukunftsfonds“, einem als Sondervermögen aufgelegten Organismus für gemeinsame Anlagen in Wertpapieren (WKN: A2DTM6) („Der Zukunftsfonds“). Die DFG richtet sich mit diesem Angebot ausschließlich an Privatanleger, deren Wohnsitz sich ausschließlich in Deutschland befindet.</p>
      <p>Die DFG bietet Kunden zudem die Möglichkeit, sich auf der Webseite zu registrieren und ein Benutzerkonto anzulegen. Die DFG bemüht sich ferner, den Kunden die Eröffnung eines Wertpapierdepots bei einem in Deutschland ansässigen Kreditinstitut, derzeit der FIL Fondsbank GmbH (die „Kooperationsbank“), zu vermitteln.</p>
      <p>Der Vertrag kommt zwischen dem Kunden und der DFG erst zustande, wenn die DFG dem Kunden den erfolgreichen Abschluss des Registrierungsprozesses und die Freischaltung seines Benutzerkontos (das „Benutzerkonto“) bestätigt und die DFG damit sein Angebot auf Abschluss des Vertrages annimmt.</p>
      <h5>3.	Gesamtpreis</h5>
      <p>Die DFG verlangt von Anlegern für die Erbringung ihrer Anlagevermittlungstätigkeit keine Vergütung.</p>
      <p>Die DFG nimmt im Zusammenhang mit der Anlagevermittlung die folgenden Zuwendungen von Dritten an und behält diese:</p>
      <p>Im Zusammenhang mit der Anlagevermittlung von Anteilen an Der Zukunftsfonds erhält die DFG von der Kapitalverwaltungsgesellschaft eine Vertriebsvergütung.</p>
      <p>Diese Vertriebsvergütung, welche die DFG erhält, ergibt sich aus der Differenz zwischen der dem Fonds Der Zukunftsfonds durch die Kapitalverwaltungsgesellschaft belasteten Verwaltungsvergütung und dem davon als Vergütung für die Kapitalverwaltungsgesellschaft errechneten Anteil sowie der Vergütung für die Greiff capital management AG (Portfolioverwalter), an welche die Kapitalverwaltungsgesellschaft die Portfolioverwaltung für Der Zukunftsfonds ausgelagert hat.</p>
      <p>Da sowohl der Anteil der Kapitalverwaltungsgesellschaft an der dem Fonds Der Zukunftsfonds belasteten Verwaltungsgebühr sowie die Vergütung des Portfolioverwalters in ihrer jeweiligen Höhe variabel vom Fondsvolumen des Der Zukunftsfonds abhängen, ist auch die Vertriebsvergütung der DFG hiervon abhängig.</p>
      <p>Abhängig vom jeweiligen Fondsvolumen erhält die DFG eine Vertriebsvergütung zwischen 0,85% und 1,05% des Fondsvolumens pro Jahr. Das Fondsvolumen errechnet sich aus dem Durchschnitt des an jedem Wertermittlungstag eines Quartals ermittelten Inventarwerts des Der Zukunftsfonds. Die Abrechnungsquartale bestimmen sich in Abhängigkeit vom Geschäftsjahr des Der Zukunftsfonds.</p>
      <h5>4.	Gegebenenfalls zusätzlich anfallende Kosten sowie Hinweis auf vom Kunden zu zahlende Steuern und Kosten</h5>
      <p>Im Zusammenhang mit seiner Finanzanlage können dem Kunden weitere Kosten und Steuern entstehen, in Abhängigkeit seiner steuerlichen Veranlagung und dem Hinzuziehen eines Steuerberaters. Eventuell anfallende Steuern richten sich nach der individuellen steuerlichen Veranlagung und können hier nicht konkret benannt werden. Bei Fragen sollte sich der Kunde an die für ihn zuständige Steuerbehörde bzw. seinen steuerlichen Berater wenden. Dies gilt insbesondere, wenn der Kunde im Ausland steuerpflichtig ist.</p>
      <p>Eigene Kosten (z. B. Telefonkosten, Porto) hat der Kunde selber zu tragen.</p>
      <h5>5.	Hinweis auf spezielle Risiken</h5>
      <p>Der Zukunftsfonds ist ein als Sondervermögen aufgelegter Organismus für gemeinsame Anlagen in Wertpapieren (WKN: A2DTM6). Der Wert eines solchen Investments kann während der Laufzeit einer Vielzahl von Marktfaktoren, wie dem Zinsniveau, der Kursentwicklung, der Wertschwankungen, der schwindenden Bonität anderer Marktteilnehmer usw. unterliegen.</p>
      <p>Ein Investmentfonds ist ein Sondervermögen, das nach dem Grundsatz der Risikostreuung in verschiedenen Einzelanlagen (z.B. in Aktien, Anleihen, wertpapierähnlichen Anlagen oder Immobilien) investiert ist. Der Kunde kann mit dem Kauf von Investmentanteilen an der Wertentwicklung sowie den Erträgen des Fondsvermögens teilhaben und trägt anteilig das volle Risiko der durch den Anteilschein repräsentierten Anlagen.</p>
      <p>Bei Investmentfonds, die auch in auf fremde Währung lautende Wertpapiere investieren bzw. in Fremdwährung geführt werden, muss berücksichtigt werden, dass sich neben der normalen Kursentwicklung auch die Währungsentwicklung negativ im Anteilspreis niederschlagen kann und Länderrisiken auftreten können, auch wenn die Wertpapiere, in die der Investmentfonds investiert, an einer deutschen Börse gehandelt werden. Durch die Aufwertung des Euro (Abwertung der Auslandswährung) verlieren die ausländischen Vermögenspositionen – am Maßstab des Euro betrachtet – an Wert. Zum Kursrisiko ausländischer Wertpapiere kommt damit das Währungsrisiko hinzu. Die Währungsentwicklung kann einen Gewinn aufzehren und die erzielte Rendite so stark beeinträchtigen, dass eine Anlage in Euro oder in Vermögenspositionen der Eurozone unter Umständen vorteilhafter gewesen wäre.</p>
      <p>Die Angaben über die bisherige Wertentwicklung stellen keine Prognose für die Zukunft dar. Zukünftige Ergebnisse der Investmentanlage sind insbesondere von den Entwicklungen der Kapitalmärkte abhängig. Investmentfonds unterliegen dem Risiko sinkender Anteilspreise, da sich Kursrückgänge der im Fonds enthaltenen Wertpapiere im Anteilspreis widerspiegeln.</p>
      <p>Eine detaillierte Darstellung der Risiken in Bezug auf Der Zukunftsfonds findet sich in der Aufklärungspflicht nach § 13 FinVermV im Abschnitt III.2. dieses Dokuments, im Verkaufsprospekt des Der Zukunftsfonds und den wesentlichen Anlegerinformationen. Der Verkaufsprospekt und die wesentlichen Anlegerinformationen sind in deutscher Sprache bei der HANSAINVEST Hanseatische Investment-GmbH, Kapstadtring 8, 22297 Hamburg oder im Internet unter <a href='https://www.hansainvest.com/deutsch/fondswelt/fondsdetails.html?fondsid=1442' target='_blank'>https://www.hansainvest.com/deutsch/fondswelt/fondsdetails.html?fondsid=1442</a> erhältlich.</p>
      <h5>6.	Befristung der Gültigkeitsdauer von Informationen</h5>
      <p>Die zur Verfügung gestellten Informationen zum Angebot der DFG unterliegen grundsätzlich keiner Befristung.</p>
      <h5>7.	Zahlung und Erfüllung</h5>
      <p>Die DFG erfüllt ihre Vermittlungsleistung durch die Weiterleitung der Willenserklärung des Kunden.</p>
      <p>Im Verhältnis zur DFG hat der Kunde keine Zahlungsverpflichtungen.</p>
      <h5>8.	Kommunikationskosten</h5>
      <p>Die Webseite www.der-zukunftsfonds.de erhebt keine spezifischen zusätzlichen Kosten, die der Kunde für die Benutzung des Fernkommunikationsmittels zu tragen hat. Aus dem Depotvertrag mit der Kooperationsbank können dem Kunden weitere Kosten für gesonderte, durch den Kunden ausdrücklich in Anspruch genommene Dienstleistungen entstehen, für die die DFG die Kosten nicht übernimmt.</p>
      <h5>9.	Nichtbestehen eines Widerrufsrechts</h5>
      <p>Dem Kunden steht ein Widerrufsrecht gemäß der beigelegten Widerrufsbelehrung zu.</p>
      <p>Für die jeweils einzelnen Fernabsatzgeschäfte (Orders), durch die der Kunde Anteile oder Aktien an Der Zukunftsfonds erwirbt oder veräußert und die von der DFG als Erklärungsbote an die Kooperationsbank weitergeleitet werden, hat der Kunde kein Widerrufsrecht.</p>
      <h5>10.	Mindestlaufzeit der Finanzdienstleistung</h5>
      <p>Der Vertrag wird auf unbestimmte Zeit geschlossen. Der Kunde ist berechtigt, den Vertrag jederzeit unter Einhaltung einer Kündigungsfrist von einem Monat zum Ende des Kalendermonats sowie ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung gegenüber der DFG zu kündigen. Die DFG kann den Vertrag innerhalb der ersten drei Jahre der Vertragslaufzeit nicht ordentlich kündigen. Nach Ablauf der drei Jahre kann die DFG den Vertrag jederzeit unter Einhaltung einer Kündigungsfrist von zwölf (12) Wochen zum Ende des Kalendermonats ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung dem Kunden gegenüber kündigen.</p>
      <h5>11.	Kündigungsbestimmungen und Vertragsstrafen</h5>
      <p>Der Kunde ist berechtigt, den Vertrag jederzeit unter Einhaltung einer Kündigungsfrist von einem Monat zum Ende des Kalendermonats sowie ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung gegenüber der DFG zu kündigen.</p>
      <p>Die DFG kann den Vertrag innerhalb der ersten drei Jahre der Vertragslaufzeit nicht ordentlich kündigen. Nach Ablauf der drei Jahre kann die DFG den Vertrag jederzeit unter Einhaltung einer Kündigungsfrist von zwölf (12) Wochen zum Ende des Kalendermonats ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung dem Kunden gegenüber kündigen.</p>
      <p>Das Recht beider Parteien zur außerordentlichen fristlosen Kündigung aus wichtigem Grund bleibt unberührt. Die DFG ist zur außerordentlichen fristlosen Kündigung aus wichtigem Grund insbesondere berechtigt, wenn</p>
      <p>a)	der Verdacht einer missbräuchlichen oder betrügerischen Verwendung des Benutzerkontos besteht,</p>
      <p>b)	die Teilnahmevoraussetzungen nach diesem Vertrag in der Person des Kunden nicht (mehr) vorliegen,</p>
      <p>c)	trotz entsprechender Anforderung von der DFG erforderliche Auskünfte nicht unverzüglich erteilt und/oder Unterlagen nicht unverzüglich zur Verfügung gestellt wurden,</p>
      <p>d)	der Kunde gegen das Abtretungs- oder Übertragungsverbot verstößt,</p>
      <p>e)	regulatorische Anforderungen oder technische oder wirtschaftliche Schwierigkeiten den Weiterbetrieb der DFG unmöglich oder nur mit für die DFG unzumutbarem Aufwand möglich machen,</p>
      <p>f)	der Kunde für einen Zeitraum von mehr als vier (4) Wochen a) keine Anteile mehr am Produkt Der Zukunftsfonds in seinem Depot hält und b) keinen aktiven Sparplan bezüglich Der Zukunftsfonds hinterlegt hat,</p>
      <p>g)	die DFG dauerhaft seine Dienstleistung über die Webseite einstellt, oder</p>
      <p>h)	die Kooperationsbank oder der Kunde ihre Kooperation beenden.</p>
      <p>Für die Form von Kündigungserklärungen gilt Folgendes: Sofern in diesem Vertrag keine besondere Kommunikationsform (z.B. E-Mail) vereinbart ist, kann die DFG für Benachrichtigungen an den Kunden oder die formwahrende Abgabe von Erklärungen im Zusammenhang die Benachrichtigungsfunktion nutzen, die dem Kunden in seinem persönlichem Bereich auf der Webseite zur Verfügung steht.</p>
      <p>Für den Fall einer Vertragsbeendigung des Vertrages zwischen der DFG und dem Kunden wird der Depotzugang ausschließlich im Verhältnis der Kooperationsbank zum Kunden gewährleistet. In diesem Fall entfällt die Übernahme der Depotführungskosten durch die DFG, sodass der Kunde die sich nach dem Vertragsverhältnis zwischen der Kooperationsbank und dem Kunden richtenden Depotführungsgebühren selber zu tragen hat.</p>
      <h5>12.	Anwendbares Recht und Gerichtsstand</h5>
      <p>Die Vertragsanbahnung unterliegt deutschem Recht unter Ausschluss des UN-Kaufrechts. Für den Vertragsschluss und die gesamte Geschäftsverbindung zwischen dem Kunden und der DFG gilt deutsches Recht. Ein Erfüllungsort ist nicht vereinbart. Gerichtsstand für vermögensrechtliche Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist Berlin, sofern der Kunde als zu verklagende Partei seinen Wohnsitz oder gewöhnlichen Aufenthalt an einen Ort außerhalb der Bundesrepublik Deutschland verlegt hat oder der DFG sein Wohnsitz oder gewöhnlicher Aufenthaltsort im Zeitpunkt der Klageerhebung nicht bekannt ist.</p>
      <h5>13.	Sprachen</h5>
      <p>Maßgebliche Sprache für dieses Vertragsverhältnis und die Kommunikation mit dem Kunden während der Laufzeit des Vertrages ist deutsch.</p>
      <h5>14.	Außergerichtliches Beschwerde- und Rechtsbehelfsverfahren</h5>
      <p>Es wird keine außergerichtliche Beschwerde- oder Rechtsbehelfsstelle für die Beilegung von Streitigkeiten zwischen dem Finanzanlagenvermittler und dem Kunden vorgesehen.</p>
      <h4>III.	Information nach der Finanzanlagenvermittlungsverordnung</h4>
      <h5>1.	Statusbezogene Informationen zur DFG – Deutsche Fondsgesellschaft SE Invest nach § 12 Finanzanlagenvermittlungsverordnung</h5>
      <h5>1.1	Name und Anschrift</h5>
      <p>
        DFG Deutsche Fondsgesellschaft SE Invest<br/>
        Geschäftsführerin: Dr. Martha Böckenfeld<br />
        Schlüterstr. 40<br />
        10707 Berlin
      </p>
      <p>E-Mail: <a href='mailto:info@der-zukunftsfonds.de'>info@der-zukunftsfonds.de</a></p>
      <p>
        Telefon: +49 (0)30 400 065 39<br />
        Telefax: +49 (0)30 288 858 97
      </p>
      <h5>1.2	Eintragung nach § 34f Abs. 5 in Verbindung mit § 11a Gewerbeordnung:</h5>
      <p>Die DFG hat am 6.11.2017 eine Erlaubnis des Bezirksamts Friedrichshain-Kreuzberg, Berlin, zur Finanzanlagevermittlung gemäß § 34f Abs. 1 S. 1 Nr. 1 Gewerbeordnung für Anteile oder Aktien an inländischen offenen Investmentvermögen, offenen EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen, erhalten und am 9. November 2017 einen entsprechenden Registrierungsantrag bei der IHK Berlin gestellt, welcher aktuell in Bearbeitung ist.</p>
      <h5>1.3	Gemeinsame Registerstelle nach § 11 a Abs. 1 GewO und Eintragung im Vermittlerregister:</h5>
      <p>Die für die DFG zuständige Registerstelle ist die:</p>
      <p>
        Deutscher Industrie- und Handelskammertag (DIHK) e.V.<br />
        Breite Str. 29<br />
        10178 Berlin
      </p>
      <p>Telefon: 030 203080</p>
      <p><a href='https://www.vermittlerregister.info' target='_blank'>https://www.vermittlerregister.info</a></p>
      <p>Die Registernummer, unter welcher die Eintragung der im Vermittlerregister (<a href='https://www.vermittlerregister.info' target='_blank'>https://www.vermittlerregister.info</a>) abgerufen werden kann, lautet D-F-107-LCJ5-32.</p>
      <h5>1.4	Erlaubnisbehörde:</h5>
      <p>Die für die DFG zu ständige Erlaubnisbehörde ist das Bezirksamt Friedrichshain-Kreuzberg von Berlin, Petersburger Straße 86-90, 10247 Berlin, <a href='https://www.berlin.de/ba-friedrichshain-kreuzberg/' target='_blank'>https://www.berlin.de/ba-friedrichshain-kreuzberg/</a></p>
      <h5>1.5	Emittenten und Anbieter von Finanzanlagen</h5>
      <p>Die DFG vermittelt derzeit ausschließlich Anteile an dem von der HANSAINVEST Hanseatische Investment-GmbH, Kapstadtring 8, 22297 Hamburg, verwalteten Sondervermögen „Der Zukunftsfonds“, WKN DE0002A2DRM69 / A2DTM6.</p>
      <h5>2.	Informationen über Risiken der angebotenen Finanzanlage</h5>
      <h5>2.1	Risiken der Fondsanlage</h5>
      <h5>2.1.1	Schwankung des Fondsanteilwerts</h5>
      <p>Der Fondsanteilwert berechnet sich aus dem Wert des Der Zukunftsfonds geteilt durch die Anzahl der in den Verkehr gelangten Anteile. Der Wert des Der Zukunftsfonds entspricht dabei der Summe der Marktwerte aller Vermögensgegenstände im Fondsvermögen abzüglich der Summe der Marktwerte aller Verbindlichkeiten des Der Zukunftsfonds. Der Fondsanteilwert ist daher von dem Wert der im Der Zukunftsfonds gehaltenen Vermögensgegenstände und der Höhe der Verbindlichkeiten des Der Zukunftsfonds abhängig. Sinkt der Wert dieser Vermögensgegenstände oder steigt der Wert der Verbindlichkeiten, so fällt der Fondsanteilwert.</p>
      <h5>2.1.2	Beeinflussung der individuellen Performance durch steuerliche Aspekte</h5>
      <p>Die steuerliche Behandlung von Kapitalerträgen hängt von den individuellen Verhältnissen des jeweiligen Anlegers ab und kann künftig Änderungen unterworfen sein. Für Einzelfragen – insbesondere unter Berücksichtigung der individuellen steuerlichen Situation – sollte sich der Anleger an seinen persönlichen Steuerberater wenden.</p>
      <h5>2.1.3	Änderung der Anlagepolitik oder der Anlagebedingungen</h5>
      <p>Die Kapitalverwaltungsgesellschaft kann die Anlagebedingungen mit Genehmigung der BaFin ändern. Dadurch können auch Rechte des Anlegers betroffen sein. Die Kapitalverwaltungsgesellschaft kann etwa durch eine Änderung der Anlagebedingungen die Anlagepolitik des Der Zukunftsfonds ändern oder sie kann die dem Der Zukunftsfonds zu belastenden Kosten erhöhen. Die Kapitalverwaltungsgesellschaft kann die Anlagepolitik zudem innerhalb des gesetzlich und vertraglich zulässigen Anlagespektrums und damit ohne Änderung der Anlagebedingungen und deren Genehmigung durch die BaFin ändern. Hierdurch kann sich das mit dem Der Zukunftsfonds verbundene Risiko verändern.</p>
      <h5>2.1.4	Aussetzung der Anteilrücknahme</h5>
      <p>Die Kapitalverwaltungsgesellschaft darf die Rücknahme der Anteile zeitweilig aussetzen, sofern außergewöhnliche Umstände vorliegen, die eine Aussetzung unter Berücksichtigung der Interessen der Anleger erforderlich erscheinen lassen. Außergewöhnliche Umstände in diesem Sinne können z.B. wirtschaftliche oder politische Krisen, Rücknahmeverlangen in außergewöhnlichem Umfang sein sowie die Schließung von Börsen oder Märkten, Handelsbeschränkungen oder sonstige Faktoren, die die Ermittlung des Anteilwerts beeinträchtigen. Daneben kann die BaFin anordnen, dass die Kapitalverwaltungsgesellschaft die Rücknahme der Anteile auszusetzen hat, wenn dies im Interesse der Anleger oder der Öffentlichkeit erforderlich ist. Der Anleger kann seine Anteile während dieses Zeitraums nicht zurückgeben. Auch im Fall einer Aussetzung der Anteilrücknahme kann der Anteilwert sinken; z.B. wenn die Kapitalverwaltungsgesellschaft gezwungen ist, Vermögensgegenstände während der Aussetzung der Anteilrücknahme unter Verkehrswert zu veräußern. Der Anteilwert nach Wiederaufnahme der Anteilrücknahme kann niedriger liegen, als derjenige vor Aussetzung der Rücknahme. Einer Aussetzung kann ohne erneute Wiederaufnahme der Rücknahme der Anteile direkt eine Auflösung des Sondervermögens folgen, z.B. wenn die Kapitalverwaltungsgesellschaft die Verwaltung des Der Zukunftsfonds kündigt, um den Der Zukunftsfonds dann aufzulösen. Für den Anleger besteht daher das Risiko, dass er die von ihm geplante Haltedauer nicht realisieren kann und dass ihm wesentliche Teile des investierten Kapitals für unbestimmte Zeit nicht zur Verfügung stehen oder insgesamt verloren gehen.</p>
      <h5>2.1.5	Auflösung des Der Zukunftsfonds</h5>
      <p>Der Kapitalverwaltungsgesellschaft steht das Recht zu, die Verwaltung des Der Zukunftsfonds zu kündigen. Die Kapitalverwaltungsgesellschaft kann den Der Zukunftsfonds nach Kündigung der Verwaltung ganz auflösen. Das Verfügungsrecht über den Der Zukunftsfonds geht nach einer Kündigungsfrist von sechs Monaten auf die Verwahrstelle über. Für den Anleger besteht daher das Risiko, dass er die von ihm geplante Haltedauer nicht realisieren kann. Bei dem Übergang des Der Zukunftsfonds auf die Verwahrstelle können dem Der Zukunftsfonds andere Steuern als deutsche Ertragssteuern belastet werden. Wenn die Fondsanteile nach Beendigung des Liquidationsverfahrens aus dem Depot des Anlegers ausgebucht werden, kann der Anleger mit Ertragssteuern belastet werden.</p>
      <h5>2.1.6	Übertragung aller Vermögensgegenstände des Der Zukunftsfonds auf ein anderes offenes Publikums-Investmentvermögen (Verschmelzung)</h5>
      <p>Die Kapitalverwaltungsgesellschaft kann sämtliche Vermögensgegenstände des Der Zukunftsfonds auf einen anderen OGAW übertragen. Der Anleger kann seine Anteile in diesem Fall (i) zurückgeben, (ii) oder behalten mit der Folge, dass er Anleger des übernehmenden OGAW wird, (iii) oder gegen Anteile an einem offenen Publikums-Investmentvermögen mit vergleichbaren Anlagegrundsätzen umtauschen, sofern die Kapitalverwaltungsgesellschaft oder ein mit ihr verbundenes Unternehmen einen solchen Der Zukunftsfonds mit vergleichbaren Anlagegrundsätzen verwaltet. Dies gilt gleichermaßen, wenn die Kapitalverwaltungsgesellschaft sämtliche Vermögensgegenstände eines anderen offenen Publikums-Investmentvermögen auf den „Der Zukunftsfonds“ überträgt. Der Anleger muss daher im Rahmen der Übertragung vorzeitig eine erneute Investitionsentscheidung treffen. Bei einer Rückgabe des Anteils können Ertragssteuern anfallen. Bei einem Umtausch der Anteile in Anteile an einem Fonds mit vergleichbaren Anlagegrundsätzen kann der Anleger mit Steuern belastet werden, etwa wenn der Wert der erhaltenen Anteile höher ist als der Wert der alten Anteile zum Zeitpunkt der Anschaffung.</p>
      <h5>2.1.7	Übertragung des Fonds auf eine andere Kapitalverwaltungsgesellschaft</h5>
      <p>Die Gesellschaft kann die Verwaltung des Der Zukunftsfonds auf eine andere Kapitalverwaltungsgesellschaft übertragen. Der Der Zukunftsfonds bleibt dadurch zwar unverändert, wie auch die Stellung des Anlegers. Der Anleger muss aber im Rahmen der Übertragung entscheiden, ob er die neue Kapitalverwaltungsgesellschaft für ebenso geeignet hält wie die bisherige. Wenn er in den Der Zukunftsfonds unter neuer Verwaltung nicht investiert bleiben möchte, muss er seine Anteile zurückgeben. Hierbei können Ertragssteuern anfallen.</p>
      <h5>2.1.8	Rentabilität und Erfüllung der Anlageziele des Anlegers</h5>
      <p>Es kann nicht garantiert werden, dass der Anleger seinen gewünschten Anlageerfolg erreicht. Der Anteilwert des Der Zukunftsfonds kann fallen und zu Verlusten beim Anleger führen. Es bestehen keine Garantien der Kapitalverwaltungsgesellschaft oder Dritter hinsichtlich einer bestimmten Mindestzahlungszusage bei Rückgabe oder eines bestimmten Anlageerfolgs des Fonds. Anleger könnten einen niedrigeren als den ursprünglich angelegten Betrag zurückzuerhalten. Ein bei Erwerb von Anteilen entrichteter Ausgabeaufschlag kann zudem insbesondere bei nur kurzer Anlagedauer einen Erfolg einer Anlage reduzieren oder sogar aufzehren.</p>
      <h5>2.1.9	Risiken der negativen Wertentwicklung des Fonds (Marktrisiko)</h5>
      <p>Nachfolgend werden die Risiken dargestellt, die mit der Anlage in einzelne Vermögensgegenstände durch den Der Zukunftsfonds einhergehen. Diese Risiken können die Wertentwicklung des Der Zukunftsfonds bzw. der im Der Zukunftsfonds gehaltenen Vermögensgegenstände beeinträchtigen und sich damit nachteilig auf den Anteilwert und auf das vom Anleger investierte Kapital auswirken.</p>
      <h5>2.1.10	Wertveränderungsrisiken</h5>
      <p>Die Vermögensgegenstände, in die die Kapitalverwaltungsgesellschaft für Rechnung des Der Zukunftsfonds investiert, unterliegen Risiken. So können Wertverluste auftreten, indem der Marktwert der Vermögensgegenstände gegenüber dem Einstandspreis fällt oder Kassa- und Terminpreise sich unterschiedlich entwickeln.</p>
      <h5>2.1.11	Kapitalmarktrisiko</h5>
      <p>Die Kurs- oder Marktwertentwicklung von Finanzprodukten hängt insbesondere von der Entwicklung der Kapitalmärkte ab, die wiederum von der allgemeinen Lage der Weltwirtschaft sowie den wirtschaftlichen und politischen Rahmenbedingungen in den jeweiligen Ländern beeinflusst wird. Auf die allgemeine Kursentwicklung insbesondere an einer Börse können auch irrationale Faktoren wie Stimmungen, Meinungen und Gerüchte einwirken. Schwankungen der Kurs- und Marktwerte können auch auf Veränderungen der Zinssätze, Wechselkurse oder der Bonität eines Emittenten zurückzuführen sein.</p>
      <h5>2.1.12	Kursänderungsrisiko von Aktien</h5>
      <p>Aktien unterliegen erfahrungsgemäß starken Kursschwankungen und somit auch dem Risiko von Kursrückgängen. Diese Kursschwankungen werden insbesondere durch die Entwicklung der Gewinne des emittierenden Unternehmens sowie die Entwicklungen der Branche und der gesamtwirtschaftlichen Entwicklung beeinflusst. Das Vertrauen der Marktteilnehmer in das jeweilige Unternehmen kann die Kursentwicklung ebenfalls beeinflussen. Dies gilt insbesondere bei Unternehmen, deren Aktien erst über einen kürzeren Zeitraum an der Börse oder einem anderen organisierten Markt zugelassen sind; bei diesen können bereits geringe Veränderungen von Prognosen zu starken Kursbewegungen führen. Ist bei einer Aktie der Anteil der frei handelbaren, im Besitz vieler Aktionäre befindlichen Aktien (sogenannter Streubesitz) niedrig, so können bereits kleinere Kauf- und Verkaufsaufträge dieser Aktie eine starke Auswirkung auf den Marktpreis haben und damit zu höheren Kursschwankungen führen.</p>
      <h5>2.1.13	Zinsänderungsrisiko</h5>
      <p>Mit der Investition in festverzinsliche Wertpapiere ist die Möglichkeit verbunden, dass sich das Marktzinsniveau ändert, das im Zeitpunkt der Begebung eines Wertpapiers besteht. Steigen die Marktzinsen gegenüber den Zinsen zum Zeitpunkt der Emission, so fallen i.d.R. die Kurse der festverzinslichen Wertpapiere. Fällt dagegen der Marktzins, so steigt der Kurs festverzinslicher Wertpapiere. Diese Kursentwicklung führt dazu, dass die aktuelle Rendite des festverzinslichen Wertpapiers in etwa dem aktuellen Marktzins entspricht. Diese Kursschwankungen fallen jedoch je nach (Rest-)Laufzeit der festverzinslichen Wertpapiere unterschiedlich stark aus. Festverzinsliche Wertpapiere mit kürzeren Laufzeiten haben geringere Kursrisiken als festverzinsliche Wertpapiere mit längeren Laufzeiten. Fest-verzinsliche Wertpapiere mit kürzeren Laufzeiten haben demgegenüber i.d.R. geringere Renditen als festverzinsliche Wertpapiere mit längeren Laufzeiten. Geldmarktinstrumente besitzen aufgrund ihrer kurzen Laufzeit von maximal 397 Tagen tendenziell geringere Kursrisiken. Daneben können sich die Zinssätze verschiedener, auf die gleiche Währung lautender zinsbezogener Finanzinstrumente mit vergleichbarer Restlaufzeit unterschiedlich entwickeln.</p>
      <h5>2.1.14	Risiko von negativen Habenzinsen</h5>
      <p>Die Kapitalverwaltungsgesellschaft legt liquide Mittel des Der Zukunftsfonds bei der Verwahrstelle oder anderen Banken für Rechnung des Der Zukunftsfonds an. Für diese Bankguthaben ist teilweise ein Zinssatz vereinbart, der dem Euribor abzüglich einer bestimmten Marge entspricht. Sinkt der Euribor unter die vereinbarte Marge, so führt dies zu negativen Zinsen auf dem entsprechenden Konto. Abhängig von der Entwicklung der Zinspolitik der Europäischen Zentralbank können sowohl kurz-, mittel- als auch langfristige Bankguthaben eine negative Verzinsung erzielen.</p>
      <h5>2.1.15	Kursänderungsrisiko von Wandel- und Optionsanleihen</h5>
      <p>Wandel- und Optionsanleihen verbriefen das Recht, die Anleihe in Aktien umzutauschen oder Aktien zu erwerben. Die Entwicklung des Werts von Wandel- und Optionsanleihen ist daher abhängig von der Kursentwicklung der Aktie als Basiswert. Die Risiken der Wertentwicklung der zugrunde liegenden Aktien können sich daher auch auf die Wertentwicklung der Wandel- und Optionsanleihe auswirken. Optionsanleihen, die dem Emittenten das Recht einräumen dem Anleger statt der Rückzahlung eines Nominalbetrags eine im Vorhinein festgelegte Anzahl von Aktien anzudienen (Reverse Convertibles), sind in verstärktem Maße von dem entsprechenden Aktienkurs abhängig.</p>
      <h5>2.1.16	Risiken im Zusammenhang mit Derivatgeschäften</h5>
      <p>Die Kapitalverwaltungsgesellschaft darf für den „Der Zukunftsfonds“ Derivatgeschäfte abschließen. Der Kauf und Verkauf von Optionen sowie der Abschluss von Terminkontrakten oder Swaps sind mit folgenden Risiken verbunden:</p>
      <p>·	Durch die Verwendung von Derivaten können Verluste entstehen, die nicht vorhersehbar sind und sogar die für das Derivatgeschäft eingesetzten Beträge überschreiten können.</p>
      <p>·	Kursänderungen des Basiswertes können den Wert eines Optionsrechts oder Terminkontraktes vermindern. Vermindert sich der Wert und wird das Derivat hierdurch wertlos, kann die Kapitalverwaltungsgesellschaft gezwungen sein, die erworbenen Rechte verfallen zu lassen. Durch Wertänderungen des einem Swap zugrunde liegenden Vermögenswertes kann der Der Zukunftsfonds ebenfalls Verluste erleiden.</p>
      <p>·	Durch die Hebelwirkung von Optionen kann der Wert des Fondsvermögens stärker beeinflusst werden, als dies beim unmittelbaren Erwerb der Basiswerte der Fall ist. Das Verlustrisiko kann bei Abschluss des Geschäfts nicht bestimmbar sein.</p>
      <p>·	Ein liquider Sekundärmarkt für ein bestimmtes Instrument zu einem gegebenen Zeitpunkt kann fehlen. Eine Position in Derivaten kann dann unter Umständen nicht wirtschaftlich neutralisiert (geschlossen) werden.</p>
      <p>·	Der Kauf von Optionen birgt das Risiko, dass die Option nicht ausgeübt wird, weil sich die Preise der Basiswerte nicht wie erwartet entwickeln, so dass die vom Fonds gezahlte Optionsprämie verfällt. Beim Verkauf von Optionen besteht die Gefahr, dass der „Der Zukunftsfonds“ zur Abnahme von Vermögenswerten zu einem höheren als dem aktuellen Marktpreis, oder zur Lieferung von Vermögenswerten zu einem niedrigeren als dem aktuellen Marktpreis verpflichtet ist. Der Der Zukunftsfonds erleidet dann einen Verlust in Höhe der Preisdifferenz minus der eingenommenen Optionsprämie.</p>
      <p>·	Bei Terminkontrakten besteht das Risiko, dass die Kapitalverwaltungsgesellschaft für Rechnung des Der Zukunftsfonds verpflichtet ist, die Differenz zwischen dem bei Abschluss zugrunde gelegten Kurs und dem Marktkurs zum Zeitpunkt der Glattstellung bzw. Fälligkeit des Geschäftes zu tragen. Damit würde Der Zukunftsfonds Verluste erleiden. Das Risiko des Verlusts ist bei Abschluss des Terminkontrakts nicht bestimmbar.</p>
      <p>·	Der gegebenenfalls erforderliche Abschluss eines Gegengeschäfts (Glattstellung) ist mit Kosten verbunden.</p>
      <p>·	Die von der Kapitalverwaltungsgesellschaft getroffenen Prognosen über die künftige Entwicklung von zugrunde liegenden Vermögensgegenständen, Zinssätzen, Kursen und Devisenmärkten können sich im Nachhinein als unrichtig erweisen.</p>
      <p>·	Die den Derivaten zugrunde liegenden Vermögensgegenstände können zu einem an sich günstigen Zeitpunkt nicht gekauft bzw. verkauft werden bzw. müssen zu einem ungünstigen Zeitpunkt gekauft bzw. verkauft werden.</p>
      <p>Bei außerbörslichen Geschäften, sogenannten over-the-counter (OTC)-Geschäften, können folgende Risiken auftreten:</p>
      <p>·	Es kann ein organisierter Markt fehlen, so dass die Kapitalverwaltungsgesellschaft die für Rechnung des Der Zukunftsfonds am OTC-Markt erworbenen Finanzinstrumente schwer oder gar nicht veräußern kann.</p>
      <p>·	Der Abschluss eines Gegengeschäfts (Glattstellung) kann aufgrund der individuellen Vereinbarung schwierig, nicht möglich oder mit erheblichen Kosten verbunden sein.</p>
      <h5>2.1.17	Risiken im Zusammenhang mit dem Erhalt von Sicherheiten</h5>
      <p>Die Kapitalverwaltungsgesellschaft erhält für Derivatgeschäfte Sicherheiten. Derivate können im Wert steigen. Die gestellten Sicherheiten könnten dann nicht mehr ausreichen, um den Lieferungs- bzw. Rückübertragungsanspruch der Kapitalverwaltungsgesellschaft gegenüber dem Kontrahenten in voller Höhe abzudecken.</p>
      <p>Die Kapitalverwaltungsgesellschaft kann Barsicherheiten auf Sperrkonten, in Staatsanleihen von hoher Qualität oder in Geldmarktfonds mit kurzer Laufzeitstruktur anlegen. Das Kreditinstitut, bei dem Bankguthaben verwahrt werden, kann jedoch ausfallen. Staatsanleihen oder Geldmarktfonds können sich negativ entwickeln. Bei Beendigung des Geschäfts könnten die angelegten Sicherheiten nicht mehr in voller Höhe verfügbar sein, obwohl sie von der Kapitalverwaltungsgesellschaft für den Der Zukunftsfonds in der ursprünglich gewährten Höhe wieder zurück gewährt werden müssen. Dann müsste Der Zukunftsfonds die bei den Sicherheiten erlittenen Verluste tragen.</p>
      <h5>2.1.18	Risiko bei Verbriefungspositionen ohne Selbstbehalt</h5>
      <p>Der Zukunftsfonds darf Wertpapiere, die Kredite verbriefen (Kreditverbriefungspositionen) und nach dem 1. Januar 2011 emittiert wurden, nur noch erwerben, wenn der Forderungsschuldner mindestens 5 % des Volumens der Verbriefung als sogenannten Selbstbehalt zurückbehält und weitere Vorgaben einhält. Die Kapitalverwaltungsgesellschaft ist daher verpflichtet, im Interesse der Anleger Maßnahmen zur Abhilfe einzuleiten, wenn sich Verbriefungen im Fondsvermögen befinden, die diesen EU-Standards nicht entsprechen. Im Rahmen dieser Abhilfemaßnahmen könnte die Kapitalverwaltungsgesellschaft gezwungen sein, solche Verbriefungspositionen zu veräußern. Aufgrund rechtlicher Vorgaben für Banken, Fondsgesellschaften und Versicherungen besteht das Risiko, dass die Kapitalverwaltungsgesellschaft solche Verbriefungspositionen nicht oder nur unter starken Preisabschlägen bzw. mit großer zeitlicher Verzögerung verkaufen kann. Hierdurch können dem Der Zukunftsfonds Verluste entstehen.</p>
      <h5>2.1.19	Inflationsrisiko</h5>
      <p>Die Inflation beinhaltet ein Abwertungsrisiko für alle Vermögensgegenstände. Dies gilt auch für die im Der Zukunftsfonds gehaltenen Vermögensgegenstände. Die Inflationsrate kann über dem Wertzuwachs des Der Zukunftsfonds liegen.</p>
      <h5>2.1.20	Währungsrisiko</h5>
      <p>Vermögenswerte des Der Zukunftsfonds können in anderen Währungen als der Fondswährung angelegt sein. Der Zukunftsfonds erhält die Erträge, Rückzahlungen und Erlöse aus solchen Anlagen in der jeweiligen Währung. Fällt der Wert dieser Währung gegenüber der Fondswährung, so reduziert sich der Wert solcher Anlagen und somit auch der Wert des Fondsvermögens.</p>
      <h5>2.1.21	Konzentrationsrisiko</h5>
      <p>Erfolgt eine Konzentration der Anlage in bestimmte Vermögensgegenstände oder Märkte, dann ist Der Zukunftsfonds von der Entwicklung dieser Vermögensgegenstände oder Märkte besonders stark abhängig.</p>
      <h5>2.1.22	Risiken im Zusammenhang mit der Investition in Investmentanteile</h5>
      <p>Die Risiken der Zielfonds stehen in engem Zusammenhang mit den Risiken der in diesen Zielfonds enthaltenen Vermögensgegenstände bzw. der von diesen Zielfonds verfolgten Anlagestrategien. Da die Manager der einzelnen Zielfonds voneinander unabhängig handeln, kann es aber auch vorkommen, dass mehrere Zielfonds gleiche oder einander entgegengesetzte Anlagestrategien verfolgen. Hierdurch können bestehende Risiken kumulieren, und eventuelle Chancen können sich gegeneinander aufheben. Es ist der Kapitalverwaltungsgesellschaft im Regelfall nicht möglich, das Management der Zielfonds zu kontrollieren. Deren Anlageentscheidungen müssen nicht zwingend mit den Annahmen oder Erwartungen der Kapitalverwaltungsgesellschaft übereinstimmen. Der Kapitalverwaltungsgesellschaft wird die aktuelle Zusammensetzung der Zielfonds oftmals nicht zeitnah bekannt sein. Entspricht die Zusammensetzung nicht ihren Annahmen oder Erwartungen, so kann sie ggf. erst deutlich verzögert reagieren, indem sie Zielfondsanteile zurückgibt.</p>
      <p>Offene Investmentvermögen, an denen Der Zukunftsfonds Anteile erwirbt, könnten zudem zeitweise die Rücknahme der Anteile aussetzen. Dann ist die Kapitalverwaltungsgesellschaft daran gehindert, die Anteile an dem Zielfonds zu veräußern, indem sie gegen Auszahlung des Rücknahmepreises bei der Verwaltungsgesellschaft oder Verwahrstelle des Zielfonds zurückgibt.</p>
      <h5>2.1.23	Risiken aus dem Anlagespektrum</h5>
      <p>Unter Beachtung der durch das Gesetz und die Anlagebedingungen vorgegebenen Anlagegrundsätze und -grenzen, die für den Der Zukunftsfonds einen sehr weiten Rahmen vorsehen, kann die tatsächliche Anlagepolitik auch darauf ausgerichtet sein, schwerpunktmäßig Vermögensgegenstände z.B. nur weniger Branchen, Märkte oder Regionen/Länder zu erwerben. Diese Konzentration auf wenige spezielle Anlagesektoren kann mit Risiken (z.B. Marktenge, hohe Schwankungsbreite innerhalb bestimmter Konjunkturzyklen) verbunden sein. Über den Inhalt der Anlagepolitik informiert der Jahresbericht nachträglich für das abgelaufene Berichtsjahr.</p>
      <h5>2.2	Risiken der eingeschränkten oder erhöhten Liquidität des Fonds im Zusammenhang mit vermehrten Zeichnungen oder Rückgaben (Liquiditätsrisiko)</h5>
      <p>Nachfolgend werden die Risiken dargestellt, die die Liquidität des Der Zukunftsfonds beeinträchtigen können. Dies kann dazu führen, dass Der Zukunftsfonds seinen Zahlungsverpflichtungen vorübergehend oder dauerhaft nicht nachkommen kann bzw. dass die Kapitalverwaltungsgesellschaft die Rückgabeverlangen von Anlegern vorübergehend oder dauerhaft nicht erfüllen kann. Der Anleger kann gegebenenfalls die von ihm geplante Haltedauer nicht realisieren und ihm kann das investierte Kapital oder Teile hiervon für unbestimmte Zeit nicht zur Verfügung stehen. Durch die Verwirklichung der Liquiditätsrisiken könnte zudem der Wert des Fondsvermögens und damit der Anteilwert sinken, etwa wenn die Gesellschaft gezwungen ist, soweit gesetzlich zulässig, Vermögensgegenstände für den Der Zukunftsfonds unter Verkehrswert zu veräußern. Ist die Kapitalverwaltungsgesellschaft nicht in der Lage, die Rückgabeverlangen der Anleger zu erfüllen, kann dies außerdem zur Aussetzung der Rücknahme und im Extremfall zur anschließenden Auflösung des Der Zukunftsfonds führen.</p>
      <h5>2.2.1	Risiko aus der Anlage in Vermögensgegenstände</h5>
      <p>Für den Der Zukunftsfonds dürfen auch Vermögensgegenstände erworben werden, die nicht an einer Börse zugelassen oder an einen anderen organisierten Markt zugelassen oder in diesen einbezogen sind. Diese Vermögensgegenstände können gegebenenfalls nur mit hohen Preisabschlägen, zeitlicher Verzögerung oder gar nicht weiterveräußert werden. Auch an einer Börse zugelassene Vermögensgegenstände können abhängig von der Marktlage, dem Volumen, dem Zeitrahmen und den geplanten Kosten gegebenenfalls nicht oder nur mit hohen Preisabschlägen veräußert werden. Obwohl für den Der Zukunftsfonds nur Vermögensgegenstände erworben werden dürfen, die grundsätzlich jederzeit liquidiert werden können, kann nicht ausgeschlossen werden, dass diese zeitweise oder dauerhaft nur unter Realisierung von Verlusten veräußert werden können.</p>
      <h5>2.2.2	Risiko durch Kreditaufnahme</h5>
      <p>Die Kapitalverwertungsgesellschaft darf für Rechnung des Der Zukunftsfonds Kredite aufnehmen. Kredite mit einer variablen Verzinsung können sich durch steigende Zinssätze negativ auf das Fondsvermögen auswirken. Muss die Gesellschaft einen Kredit zurückzahlen und kann ihn nicht durch eine Anschlussfinanzierung oder im Der Zukunftsfonds vorhandene Liquidität ausgleichen, ist sie möglicherweise gezwungen, Vermögensgegenstände vorzeitig oder zu schlechteren Konditionen als geplant zu veräußern.</p>
      <h5>2.2.3	Risiken durch vermehrte Rückgaben oder Zeichnungen</h5>
      <p>Durch Kauf- und Verkaufsaufträge von Anlegern fließt dem Fondsvermögen Liquidität zu oder vom Fondsvermögen Liquidität ab. Die Zu- und Abflüsse können nach Saldierung zu einem Nettozu- oder -abfluss der liquiden Mittel des Fonds führen. Dieser Nettozu- oder -abfluss kann den Fondsmanager veranlassen, Vermögensgegenstände zu kaufen oder zu verkaufen, wodurch Transaktionskosten entstehen. Dies gilt insbesondere, wenn durch die Zu- oder Abflüsse eine von der Kapitalverwertungsgesellschaft für den Der Zukunftsfonds vorgesehene Quote liquider Mittel über- bzw. unterschritten wird. Die hierdurch entstehenden Transaktionskosten werden dem Fondsvermögen belastet und können die Wertentwicklung des Der Zukunftsfonds beeinträchtigen. Bei Zuflüssen kann sich eine erhöhte Fondsliquidität belastend auf die Wertentwicklung des Der Zukunftsfonds auswirken, wenn die Gesellschaft die Mittel nicht zu adäquaten Bedingungen anlegen kann.</p>
      <h5>2.3	Kontrahentenrisiko inklusive Kredit- und Forderungsrisiko</h5>
      <p>Nachfolgend werden die Risiken dargestellt, die sich für den Der Zukunftsfonds im Rahmen einer Geschäftsbeziehung mit einer anderen Partei (sogenannte Gegenpartei) ergeben können. Dabei besteht das Risiko, dass die Gegenpartei ihren vereinbarten Verpflichtungen nicht mehr nachkommen kann. Dies kann die Wertentwicklung des Der Zukunftsfonds beeinträchtigen und sich damit auch nachteilig auf den Anteilwert und auf das vom Anleger investierte Kapital auswirken.</p>
      <h5>2.3.1	Adressenausfallrisiko / Gegenpartei-Risiken (außer zentrale Kontrahenten)</h5>
      <p>Durch den Ausfall eines Emittenten oder eines Kontrahenten gegen den der Der Zukunftsfonds Ansprüche hat, können für den Der Zukunftsfonds Verluste entstehen. Das Emittentenrisiko beschreibt die Auswirkung der besonderen Entwicklungen des jeweiligen Emittenten, die neben den allgemeinen Tendenzen der Kapitalmärkte auf den Kurs eines Wertpapiers einwirken. Auch bei sorgfältiger Auswahl der Wertpapiere kann nicht ausgeschlossen werden, dass Verluste durch Vermögensverfall von Emittenten eintreten. Die Partei eines für Rechnung des Fonds geschlossenen Vertrags kann teilweise oder vollständig ausfallen (Kontrahentenrisiko). Dies gilt für alle Verträge, die für Rechnung eines Fonds geschlossen werden.</p>
      <h5>2.3.2	Risiko durch zentrale Kontrahenten</h5>
      <p>Ein CCP tritt als zwischengeschaltete Institution in bestimmte Geschäfte für den Der Zukunftsfonds ein, insbesondere in Geschäfte über derivative Finanzinstrumente. In diesem Fall wird er als Käufer gegenüber dem Verkäufer und als Verkäufer gegenüber dem Käufer tätig. Ein CCP sichert sich gegen das Risiko, dass seine Geschäftspartner die vereinbarten Leistungen nicht erbringen können, durch eine Reihe von Schutzmechanismen ab, die es ihm jederzeit ermöglichen, Verluste aus den eingegangen Geschäften auszugleichen (z.B. durch Besicherungen). Es kann trotz dieser Schutzmechanismen nicht ausgeschlossen werden, dass ein CCP seinerseits überschuldet wird und ausfällt, wodurch auch Ansprüche der Kapitalverwaltungsgesellschaft für den Der Zukunftsfonds betroffen sein können. Hierdurch können Verluste für den „Der Zukunftsfonds“ entstehen.</p>
      <h5>2.4	Operationelle und sonstige Risiken des Der Zukunftsfonds</h5>
      <p>Im Folgenden werden Risiken dargestellt, die sich beispielsweise aus menschlichem oder Systemversagen bei der Kapitalverwaltungsgesellschaft oder externen Dritten ergeben können. Diese Risiken können die Wertentwicklung des Der Zukunftsfonds beeinträchtigen und sich damit auch nachteilig auf den Anteilwert und auf das vom Anleger investierte Kapital auswirken.</p>
      <h5>2.4.1	Risiken durch kriminelle Handlungen, Missstände oder Naturkatastrophen</h5>
      <p>Der Der Zukunftsfonds kann Opfer von Betrug oder anderen kriminellen Handlungen werden. Er kann Verluste durch Missverständnisse oder Fehler von Mitarbeitern der Kapitalverwaltungsgesellschaft oder externer Dritter erleiden oder durch äußere Ereignisse wie z.B. Naturkatastrophen geschädigt werden.</p>
      <h5>2.4.2	Länder- oder Transferrisiko</h5>
      <p>Es besteht das Risiko, dass ein ausländischer Schuldner trotz Zahlungsfähigkeit aufgrund fehlender Transferfähigkeit der Währung oder -bereitschaft seines Sitzlandes, oder aus anderen Gründen, Leistungen nicht fristgerecht, überhaupt nicht oder nur in einer anderen Währung erbringen kann. So können z.B. Zahlungen, auf die die Kapitalverwaltungsgesellschaft für Rechnung des Der Zukunftsfonds Anspruch hat, ausbleiben, in einer Währung erfolgen, die aufgrund von Devisenbeschränkungen nicht (mehr) konvertierbar ist, oder in einer anderen Währung erfolgen. Zahlt der Schuldner in einer anderen Währung, so unterliegt diese Position dem oben dargestellten Währungsrisiko.</p>
      <h5>2.4.3	Rechtliche und politische Risiken</h5>
      <p>Für den Der Zukunftsfonds dürfen Investitionen in Rechtsordnungen getätigt werden, bei denen deutsches Recht keine Anwendung findet bzw. im Fall von Rechtsstreitigkeiten der Gerichtsstand außerhalb Deutschlands ist. Hieraus resultierende Rechte und Pflichten der Kapitalverwaltungsgesellschaft für Rechnung des Der Zukunftsfonds können von denen in Deutschland zum Nachteil des Der Zukunftsfonds bzw. des Anlegers abweichen. Politische oder rechtliche Entwicklungen einschließlich der Änderungen von rechtlichen Rahmenbedingungen in diesen Rechtsordnungen können von der Kapitalverwaltungsgesellschaft nicht oder zu spät erkannt werden oder zu Beschränkungen hinsichtlich erwerbbarer oder bereits erworbener Vermögensgegenstände führen. Diese Folgen können auch entstehen, wenn sich die rechtlichen Rahmenbedingungen für die Kapitalverwaltungsgesellschaft und/oder die Verwaltung des Der Zukunftsfonds in Deutschland ändern.</p>
      <h5>2.4.4	Änderung der steuerlichen Rahmenbedingungen, steuerliches Risiko</h5>
      <p>Eine Änderung fehlerhaft festgestellter Besteuerungsgrundlagen des Der Zukunftsfonds für vorangegangene Geschäftsjahre (z.B. aufgrund von steuerlichen Außenprüfungen) kann bei einer für Anleger steuerlich grundsätzlich nachteiligen Korrektur zur Folge haben, dass der Anleger die Steuerlast aus der Korrektur für vorangegangene Geschäftsjahre zu tragen hat, obwohl er unter Umständen zu diesem Zeitpunkt nicht in dem Der Zukunftsfonds investiert war. Umgekehrt kann für den Anleger der Fall eintreten, dass ihm eine steuerlich grundsätzlich vorteilhafte Korrektur für das aktuelle und für vorangegangene Geschäftsjahre, in denen er an dem Der Zukunftsfonds beteiligt war, nicht mehr zugutekommt, weil er seine Anteile vor Umsetzung der Korrektur zurückgegeben oder veräußert hat.</p>
      <p>Zudem kann eine Korrektur von Steuerdaten dazu führen, dass steuerpflichtige Erträge bzw. steuerliche Vorteile in einem anderen als eigentlich zutreffenden Veranlagungszeitraum tatsächlich steuerlich erfasst werden und sich dies beim einzelnen Anleger negativ auswirkt.</p>
      <h5>2.4.5	Schlüsselpersonenrisiko</h5>
      <p>Fällt das Anlageergebnis des Der Zukunftsfonds in einem bestimmten Zeitraum sehr positiv aus, hängt dieser Erfolg möglicherweise auch von der Eignung der handelnden Personen und damit den richtigen Entscheidungen des Managements ab. Die personelle Zusammensetzung des Fondsmanagements kann sich jedoch verändern. Neue Entscheidungsträger können dann möglicherweise weniger erfolgreich agieren.</p>
      <h5>2.4.6	Verwahrrisiko</h5>
      <p>Mit der Verwahrung von Vermögensgegenständen insbesondere im Ausland ist ein Verlustrisiko verbunden, das aus Insolvenz, Sorgfaltspflichtverletzungen des Verwahrers bzw. höherer Gewalt resultieren kann.</p>
      <h5>2.4.7	Risiken aus Handels- und Clearingmechanismen (Abwicklungsrisiko)</h5>
      <p>Bei der Abwicklung von Wertpapiergeschäften besteht das Risiko, dass eine der Vertragsparteien verzögert oder nicht vereinbarungsgemäß zahlt oder die Wertpapiere nicht fristgerecht liefert. Dieses Abwicklungsrisiko besteht entsprechend auch beim Handel mit anderen Vermögensgegenständen für den „Der Zukunftsfonds“.</p>
      <h5>2.5	Erläuterung des Risikoprofils</h5>
      <p>Die Wertentwicklung des Der Zukunftsfonds wird insbesondere von folgenden Faktoren beeinflusst, aus denen sich Chancen und Risiken ergeben:</p>
      <p>·	Entwicklung auf den internationalen Aktienmärkten.</p>
      <p>·	Unternehmensspezifische Entwicklungen.</p>
      <p>·	Wechselkursveränderungen von Nicht-Euro-Währungen gegenüber dem Euro.</p>
      <p>·	Renditeveränderungen bzw. Kursentwicklungen auf den Rentenmärkten.</p>
      <p>·	Entwicklung der Renditedifferenzen zwischen Staatspapieren und Unternehmensanleihen (Spread-Entwicklung).</p>
      <p>·	Der Fonds kann seine Anlagen zeitweilig mehr oder weniger stark auf bestimmte Sektoren, Länder oder Marktsegmente konzentrieren. Auch daraus können sich Chancen und Risiken ergeben.</p>
      <h5>2.6	Leverage-Wirkung</h5>
      <p>Leverage bezeichnet jede Methode, mit der die Kapitalverwaltungsgesellschaft den Investitionsgrad des Der Zukunftsfonds erhöht (Hebelwirkung). Solche Methoden sind insbesondere Kreditaufnahmen sowie der Erwerb von Derivaten mit eingebetteter Hebelfinanzierung. Die Kapitalverwaltungsgesellschaft kann solch Methoden für den Der Zukunftsfonds in dem im Verkaufsprospekt beschriebenen Umfang nutzen. Die Möglichkeit der Nutzung von Derivaten wird im Abschnitt „Anlageinstrumente im Einzelnen – Derivate“ des Verkaufsprospekts dargestellt.</p>
      <p>Die Kapitalverwaltungsgesellschaft darf durch die vorstehend beschriebenen Methoden das Marktrisiko des „Der Zukunftsfonds“ höchstens verdoppeln (Marktrisikogrenze). Kurzfristige Kreditaufnahmen werden bei der Berechnung dieser Grenze nicht berücksichtigt. Sie beschränkt den Einsatz von Leverage im Der Zukunftsfonds.</p>
      <p>Der Leverage wird berechnet, indem das Gesamtexposure des Der Zukunftsfonds durch dessen Nettoinventarwert dividiert wird. Zur Berechnung des Gesamtexposures wird der Nettoinventarwert des Der Zukunftsfonds mit allen Nominalbeträgen der im Der Zukunftsfonds eingesetzten Derivatgeschäfte aufsummiert. Abhängig von den Marktbedingungen kann die Hebelwirkung jedoch schwanken, so dass es trotz der ständigen Überwachung durch die Kapitalverwaltungsgesellschaft zu Überschreitungen der angestrebten Marke kommen kann. Derivate können von der Gesellschaft mit unterschiedlicher Zielsetzung eingesetzt werden, etwa zur Absicherung oder zur Optimierung der Rendite. Die Berechnung des Gesamtexposures unterscheidet jedoch nicht zwischen den unterschiedlichen Zielsetzungen des Derivateeinsatzes. Aus diesem Grund ist die Summe der Nominalbeträge kein Indikator für den Risikogehalt des Der Zukunftsfonds.</p>
      <h5>2.7	Das Ausmaß der Schwankungen des Preises (Volatilität) der betreffenden Finanzanlagen und etwaige Beschränkungen des für solche Finanzanlagen verfügbaren Marktes</h5>
      <h5>2.7.1	Volatilität</h5>
      <p>Der Zukunftsfonds weist aufgrund seiner Zusammensetzung und seiner Anlagepolitik eine erhöhte Volatilität auf, d.h. die Anteilpreise können auch innerhalb kurzer Zeiträume erheblichen Schwankungen nach oben und nach unten unterworfen sein.</p>
      <h5>2.7.2	Beschränkungen des Marktes für die Finanzanlage</h5>
      <p>Die Anteile des Der Zukunftsfonds sind nicht zum (amtlichen) Handel an Börsen zugelassen. Es kann jedoch nicht ausgeschlossen werden, dass die Anteile ohne Zustimmung der Kapitalverwaltungsgesellschaft an Börsen oder an anderen Märkten gehandelt werden.</p>
      <p>Der dem Börsenhandel oder Handel in sonstigen Märkten zugrunde liegende Marktpreis wird nicht ausschließlich durch den Wert der im Der Zukunftsfonds gehaltenen Vermögensgegenstände, sondern auch durch Angebot und Nachfrage bestimmt. Daher kann dieser Marktpreis von dem ermittelten Anteilpreis abweichen.</p>
      <h5>3.	Informationen über Kosten und Nebenkosten</h5>
      <h5>3.1	Kosten bei Ausgabe und Rücknahme der Anteile</h5>
      <p>Die Ausgabe und Rücknahme der Anteile durch die Kapitalverwaltungsgesellschaft bzw. durch die Verwahrstelle erfolgt zum Ausgabepreis (Anteilwert) bzw. Rücknahmepreis (Anteilwert) ohne Berechnung zusätzlicher Kosten.</p>
      <p>Werden Anteile über Dritte zurückgegeben, so können Kosten bei der Rücknahme der Anteile anfallen. Bei Vertrieb von Anteilen über Dritte können auch höhere Kosten als der Ausgabepreis berechnet werden.</p>
      <h5>3.2	Verwaltungs- und sonstige Kosten</h5>
      <p>Die Kapitalverwaltungsgesellschaft erhält für die Verwaltung des Der Zukunftsfonds eine vierteljährlich zahlbare Vergütung in Höhe von 1,75 % p.a. des Durchschnittswertes des Der Zukunftsfonds, der aus den Werten eines jeden Bewertungstages errechnet wird. Es steht der Kapitalverwaltungsgesellschaft frei, für den Der Zukunftsfonds oder für eine oder mehrere Anteilklassen eine niedrigere Vergütung zu berechnen oder von der Berechnung einer Vergütung abzusehen.</p>
      <p>Die Kapitalverwaltungsgesellschaft kann in den Fällen, in denen für den Der Zukunftsfonds gerichtlich oder außergerichtlich streitige Ansprüche durchgesetzt werden, eine Vergütung von bis zu 5 % der für den Der Zukunftsfonds – nach Abzug und Ausgleich der aus diesem Verfahren für den Der Zukunftsfonds entstandenen Kosten – vereinnahmten Beträge berechnen.</p>
      <p>Die Kapitalverwaltungsgesellschaft kann sich bei der Umsetzung des Anlagekonzeptes einer Anlageberatungs- oder Asset Management-Gesellschaft bedienen. Die Vergütung der Anlageberatungs- oder Asset Management-Gesellschaft wird von der Verwaltungsvergütung abgedeckt.</p>
      <p>Die Kapitalverwaltungsgesellschaft kann sich für die und bei der Verwaltung von Derivate-Geschäften und Sicherheiten für Derivate-Geschäfte der Dienste Dritter bedienen. In diesem Fall erhalten diese Dritten zusammen eine vierteljährlich zahlbare Vergütung in Höhe von 0,10 % p.a. des Durchschnittswertes des Der Zukunftsfonds, der aus den Werten eines jeden Bewertungstages errechnet wird. Es steht der Kapitalverwaltungsgesellschaft frei, der Der Zukunftsfonds oder eine oder mehrere Anteilklassen mit einer niedrigeren Vergütung zu belasten oder von der Belastung mit einer solchen Vergütung abzusehen. Diese Vergütungen werden von der Verwaltungsvergütung nicht abgedeckt und somit von der Kapitalverwaltungsgesellschaft dem Der Zukunftsfonds zusätzlich belastet.</p>
      <p>Der Betrag, der jährlich aus dem Der Zukunftsfonds als Verwaltungsvergütung und Vergütung für Dritte bei der Verwaltung von Derivategeschäften und Sicherheiten für Derivategeschäfte entnommen wird, kann insgesamt bis zu 1,85 % p.a. des Durchschnittswertes des Der Zukunftsfonds, der aus den Werten eines jeden Bewertungstages errechnet wird, betragen.</p>
      <p>Die Verwahrstelle erhält für ihre Tätigkeit eine vierteljährlich zahlbare Vergütung in Höhe von 0,07 % p.a. (mindestens EUR 25.000 p.a.) des Durchschnittswertes des Der Zukunftsfonds, der aus den Werten eines jeden Bewertungstages errechnet wird. Es steht der Verwahrstelle frei, für das Der Zukunftsfonds oder für eine oder mehrere Anteilklassen eine niedrigere Vergütung in Rechnung zu stellen oder von der Berechnung einer Vergütung abzusehen. Neben den vorgenannten Vergütungen gehen die folgenden Aufwendungen zu Lasten des Der Zukunftsfonds:</p>
      <p>·	bankübliche Depot- und Kontogebühren, ggf. einschließlich der banküblichen Kosten für die Verwahrung ausländischer Vermögensgegenstände im Ausland;</p>
      <p>·	Kosten für den Druck und Versand der für die Anleger bestimmten gesetzlich vorgeschriebenen Verkaufsunterlagen (Jahres- und Halbjahresberichte, Verkaufsprospekt, wesentliche Anlegerin-formationen);</p>
      <p>·	Kosten der Bekanntmachung der Jahres- und Halbjahresberichte, der Ausgabe- und Rücknahmepreise und ggf. der Ausschüttungen oder Thesaurierungen und des Auflösungsberichtes;</p>
      <p>·	Kosten der Erstellung und Verwendung eines dauerhaften Datenträgers, außer im Fall der Informationen über Fondsverschmelzungen und der Informationen über Maßnahmen im Zusammenhang mit Anlagegrenzverletzungen oder Berechnungsfehlern bei der Anteilwertermittlung;</p>
      <p>·	Kosten für die Prüfung des Der Zukunftsfonds durch den Abschlussprüfer des Der Zukunftsfonds;</p>
      <p>·	Kosten für die Bekanntmachung der Besteuerungsgrundlagen und der Bescheinigung, dass die steuerlichen Angaben nach den Regeln des deutschen Steuerrechts ermittelt wurden;</p>
      <p>·	Kosten für die Geltendmachung und Durchsetzung von Rechtsansprüchen durch die Kapitalverwaltungsgesellschaft für Rechnung des Der Zukunftsfonds sowie der Abwehr von gegen die Kapitalverwaltungsgesellschaft zu Lasten des Der Zukunftsfonds erhobenen Ansprüchen;</p>
      <p>·	Gebühren und Kosten, die von staatlichen Stellen in Bezug auf den Der Zukunftsfonds erhoben werden;</p>
      <p>·	Kosten für Rechts- und Steuerberatung im Hinblick auf das Der Zukunftsfonds;</p>
      <p>·	Kosten für die Beauftragung von Stimmrechtsbevollmächtigten;</p>
      <p>·	Kosten für die Analyse des Anlageerfolges des Der Zukunftsfonds durch Dritte;</p>
      <p>·	im Zusammenhang mit den an die Kapitalverwaltungsgesellschaft, die Verwahrstelle und Dritte zu zahlenden Vergütungen sowie den vorstehend genannten Aufwendungen anfallende Steuern einschließlich der im Zusammenhang mit der Verwaltung und Verwahrung entstehenden Steuern.</p>
      <p>Neben den vorgenannten Vergütungen und Aufwendungen werden dem Der Zukunftsfonds die in Zusammenhang mit dem Erwerb und der Veräußerung von Vermögensgegenständen entstehenden Kosten belastet.</p>
      <p>Für die oben genannten Aufwendungen können folgende Erläuterungen bezüglich der dem Der Zukunftsfonds zu belastenden Beträge gegeben werden:</p>
      <p>·	Die Vergütung des Abschlussprüfers für die Prüfung des Der Zukunftsfonds setzt sich aus einem Basishonorar und weiteren Zuschlägen, die insbesondere von der Anzahl der Segmente und Anteilklassen des Der Zukunftsfonds sowie von dem Fondsvolumen des Der Zukunftsfonds abhängen, zusammen und kann maximal einen Betrag von EUR 15.000 zzgl. MwSt. erreichen.</p>
      <p>·	Die Kosten für die Bekanntmachung der Besteuerungsgrundlagen und der Bescheinigung, dass die steuerlichen Angaben nach den Regeln des deutschen Steuerrechts ermittelt wurden betragen pro Geschäftsjahr des Der Zukunftsfonds EUR 1.500.</p>
      <p>·	In Fällen, in denen für den Der Zukunftsfonds im Rahmen von Sammelklagen gerichtlich oder außergerichtlich der Abschluss eines Vergleiches oder ein Urteil erzielt wurde, kann die dafür beauftragte Rechtsanwaltskanzlei eine Vergütung in Höhe von bis zu 5 % der diesbezüglich für den Der Zukunftsfonds vereinnahmten Beträge erhalten. Für die aktive Teilnahme an einer Sammelklage als führender Kläger, für Privatklagen oder sonstigen Klage- oder Verwaltungsverfahren können hiervon abweichende Konditionen gelten bzw. vereinbart werden. Die hierfür beauftragte Rechtsanwaltskanzlei kann in diesen Fällen bis zu 30 % der vereinnahmten Beträge erhalten.</p>
      <p>·	Für die Genehmigung der Anlagebedingungen des Der Zukunftsfonds, die Genehmigung der Verwahrstelle, für die Änderung von Anlagebedingungen sowie weitere Amtshandlungen bezogen auf den Der Zukunftsfonds kann die BaFin Gebühren oder Kosten erheben, welche von dem Der Zukunftsfonds getragen werden. Die Höhe dieser Beträge können der Verordnung über die Umlegung von Kosten nach dem Finanzdienstleistungsaufsichtsgesetz in seiner jeweils aktuellen Fassung entnommen werden. Die jeweils aktuelle Fassung dieser Verordnung ist auf der Internetseite der BaFin unter www.bafin.de erhältlich.</p>
      <p>·	Für Beauftragung eines Stimmrechtsbevollmächtigten für die Abwicklung von Hauptversammlungen fällt ein Entgelt in Höhe von EUR 130 pro Hauptversammlung an. Sofern die Abwicklung für mehrere Investmentvermögen erfolgt, erfolgt eine anteilige Berechnung für den Der Zukunftsfonds. Die Anzahl der Hauptversammlungen, welche der Stimmrechtsbevollmächtigte für den Der Zukunftsfonds abwickelt ist von der jeweils aktuellen Portfoliozusammensetzung abhängig. Ein im Voraus festgelegter oder abschätzbarer Höchstbetrag hierfür besteht daher nicht.</p>
      <p>·	Die Höhe der im Zusammenhang mit dem Erwerb und der Veräußerung von Vermögensgegenständen entstehenden Kosten hängt von der Anzahl der tatsächlich durchgeführten Transaktionen ab. Die Kapitalverwaltungsgesellschaft geht für den Zeitraum eines Geschäftsjahres des Der Zukunftsfonds von einem Höchstbetrag von 2 % des durchschnittlichen Volumens des „Der Zukunftsfonds“ aus. Die Transaktionskosten können in diesem Zeitraum tatsächlich niedriger oder auch höher sein. Der vorgenannte Prozentsatz ist daher lediglich eine Prognose.</p>
      <p>·	Im Hinblick auf die sonstigen oben genannten Aufwendungen werden die jeweils tatsächlich angefallenen Aufwendungen dem Der Zukunftsfonds belastet. Da die Höhe dieser Aufwendungen u.a. von dem Volumen des Der Zukunftsfonds, der Portfoliozusammensetzung bzw. der Anzahl der in dem Der Zukunftsfonds investierten Anleger abhängt, besteht ein im Voraus festgelegter oder abschätzbarer Höchstbetrag für diese Aufwendungen nicht.</p>
      <p>·	Die Kapitalverwaltungsgesellschaft gibt im Regelfall Teile ihrer Verwaltungsvergütung an vermittelnde Stellen weiter. Dies erfolgt zur Abgeltung von Vertriebsleistungen. Dabei kann es sich auch um wesentliche Teile handeln. Verwahrstelle und Anlageberatungs- oder Asset Management-Gesellschaft können aus ihren vereinnahmten Vergütungen Vertriebsmaßnahmen der Vermittler unterstützen, deren Berechnung in der Regel auf der Grundlage vermittelter Bestände erfolgt.</p>
      <p>·	Kapitalverwaltungsgesellschaft, Verwahrstelle und Anlageberatungs- oder Asset Management-Gesellschaft können nach ihrem freien Ermessen mit einzelnen Anlegern die teilweise Rückzahlung von vereinnahmten Vergütungen an diese Anleger vereinbaren. Dies kommt insbesondere dann in Betracht, wenn institutionelle Anleger direkt Großbeträge nachhaltig investieren.</p>
      <p>·	Die Kapitalverwaltungsgesellschaft kann im Zusammenhang mit Geschäften für Rechnung des Der Zukunftsfonds geldwerte Vorteile (Broker research, Finanzanalysen, Markt- und Kursinformationssysteme) verwenden, die sie im Interesse der Anleger bei den Anlageentscheidungen nutzt. Der Kapitalverwaltungsgesellschaft fließen keine Rückvergütungen der aus dem Der Zukunftsfonds an die Verwahrstelle und an Dritte geleisteten Vergütungen und Aufwandserstattungen zu. Im Übrigen wird auf die entsprechenden Jahresberichte verwiesen.</p>
      <h5>3.3	Besonderheiten und Kosten beim Erwerb von Investmentanteilen</h5>
      <p>Neben der Vergütung zur Verwaltung des Der Zukunftsfonds wird eine Verwaltungsvergütung für die im Der Zukunftsfonds gehaltenen Anteile an Investmentvermögen (Zielfonds) berechnet.</p>
      <p>Sofern Der Zukunftsfonds einen erheblichen Teil seines Wertes in Investmentanteile anlegt, werden bei der Berechnung der Gesamtkostenquote (siehe unten) sämtliche Verwaltungsvergütungen berücksichtigt.</p>
      <p>Der Anleger sollte darüber hinaus berücksichtigen, dass dem „Der Zukunftsfonds“ beim Erwerb von anderen Investmentanteilen gegebenenfalls Ausgabeaufschläge beziehungsweise Rücknahmegebühren berechnet werden, die den Der Zukunftsfonds belasten. Neben diesen Kosten sind auch die für den jeweiligen Zielfonds anfallenden Gebühren, Kosten, Steuern, Provisionen und sonstigen Aufwendungen in Bezug auf Investmentanteile, in die Der Zukunftsfonds investiert, mittelbar von den Anlegern des Der Zukunftsfonds zu tragen. Der Fonds darf auch in Investmentanteile anlegen, die eine andere Gebührenstruktur (z.B. Pauschalgebühr, erfolgsabhängige Vergütung) aufweisen oder für die zusätzliche Arten von Gebühren belastet werden dürfen.</p>
      <p>Soweit ein Zielfonds direkt oder indirekt von der Kapitalverwaltungsgesellschaft oder einem anderen Unternehmen verwaltet wird, mit dem die Kapitalverwaltungsgesellschaft durch eine wesentliche unmittelbare oder mittelbare Beteiligung verbunden ist, darf die Kapitalverwaltungsgesellschaft oder das andere Unternehmen für den Erwerb oder die Rücknahme der Investmentanteile der Zielfonds keine Ausgabeaufschläge und Rücknahmeabschläge zu Lasten des Der Zukunftsfonds berechnen.</p>
      <p>Im Jahres- und Halbjahresbericht werden die Ausgabeaufschläge und Rücknahmeabschläge offengelegt, die dem Der Zukunftsfonds für den Erwerb und die Rücknahme von Anteilen an anderen Investmentvermögen berechnet worden sind. Ferner wird die Vergütung offen gelegt, die dem Der Zukunftsfonds von einer in- oder ausländischen Kapitalverwaltungsgesellschaft oder einer Kapitalverwaltungsgesellschaft, mit der die Kapitalverwaltungsgesellschaft durch Beteiligung verbunden ist, als Verwaltungsvergütung für die im Der Zukunftsfonds gehaltenen Anteile berechnet wurde.</p>
      <h5>3.4	Gesamtkostenquote</h5>
      <p>Im Jahresbericht werden die im Geschäftsjahr zu Lasten des Der Zukunftsfonds angefallenen Verwaltungskosten offen gelegt und als Quote des durchschnittlichen Fondsvolumens ausgewiesen („Gesamtkostenquote“). Diese setzt sich zusammen aus der Vergütung für die Verwaltung des Der Zukunftsfonds, der Vergütung der Verwahrstelle sowie den Aufwendungen, die dem „Der Zukunftsfonds“ zusätzlich belastet werden können (siehe oben). Ausgenommen sind die Nebenkosten und die Kosten, die beim Erwerb und der Veräußerung von Vermögensgegenständen entstehen (Transaktionskosten). Die Gesamtkostenquote wird in den wesentlichen Anlegerinformationen als sogenannte „laufende Kosten“ veröffentlicht.</p>
      <h5>3.5	Abweichender Kostenausweis durch Vertriebsstellen</h5>
      <p>Wird der Anleger beim Erwerb von Anteilen durch Dritte beraten oder vermitteln diese den Kauf, weisen sie ihm gegebenenfalls Kosten oder Kostenquoten aus, die nicht mit den Kostenangaben im Verkaufsprospekt und in den wesentlichen Anlegerinformationen deckungsgleich sind und die hier beschriebene Gesamtkostenquote übersteigen können. Grund dafür kann insbesondere sein, dass der Dritte die Kosten seiner eigenen Tätigkeit (z.B. Vermittlung, Beratung oder Depotführung) zusätzlich berücksichtigt. Darüber hinaus berücksichtigt er ggf. auch einmalige Kosten wie Ausgabeaufschläge und benutzt in der Regel andere Berechnungsmethoden oder auch Schätzungen für die auf Fondsebene anfallenden Kosten, die insbesondere die Transaktionskosten des Der Zukunftsfonds mit umfassen.</p>
      <p>Abweichungen im Kostenausweis können sich sowohl bei Informationen vor Vertragsschluss ergeben als auch bei regelmäßigen Kosteninformationen über die bestehende Fondsanlage im Rahmen einer dauerhaften Kundenbeziehung.</p>
      <h5>4.	Informationen über Vergütungen und Zuwendungen nach § 12a und § 17 Finanzanlagenvermittlungsverordnung</h5>
      <p>Die DFG verlangt von Anlegern für die Erbringung ihrer Anlagevermittlungstätigkeit keine Vergütung.</p>
      <p>Die DFG nimmt im Zusammenhang mit der Anlagevermittlung die folgenden Zuwendungen von Dritten an und behält diese:</p>
      <p>Im Zusammenhang mit der Anlagevermittlung von Anteilen an Der Zukunftsfonds erhält die DFG von der Kapitalverwaltungsgesellschaft eine Vertriebsvergütung.</p>
      <p>Diese Vertriebsvergütung, welche die DFG erhält, ergibt sich aus der Differenz zwischen der dem Fonds Der Zukunftsfonds durch die Kapitalverwaltungsgesellschaft belasteten Verwaltungsvergütung und dem davon als Vergütung für die Kapitalverwaltungsgesellschaft errechneten Anteil sowie der Vergütung für den Portfolioverwalter, an welche die Kapitalverwaltungsgesellschaft die Portfolioverwaltung für Der Zukunftsfonds ausgelagert hat.</p>
      <p>Da sowohl der Anteil der Kapitalverwaltungsgesellschaft an der dem Fonds Der Zukunftsfonds belasteten Verwaltungsgebühr sowie die Vergütung des Portfolioverwalters in ihrer jeweiligen Höhe variabel vom Fondsvolumen des Der Zukunftsfonds abhängen, ist auch die Vertriebsvergütung der DFG hiervon abhängig.</p>
      <p>Abhängig vom jeweiligen Fondsvolumen erhält die DFG eine Vertriebsvergütung zwischen 0,85% und 1,05% des Fondsvolumens pro Jahr. Das Fondsvolumen errechnet sich aus dem Durchschnitt des an jedem Wertermittlungstag eines Quartals ermittelten Inventarwerts des Der Zukunftsfonds. Die Abrechnungsquartale bestimmen sich in Abhängigkeit vom Geschäftsjahr des Der Zukunftsfonds.</p>
      <h5>5.	Informationen zu Interessenkonflikten</h5>
      <p>Im Rahmen der Erbringung der Finanzanlagenvermittlung können sich auf Seiten der DFG Vorteile ergeben, die sich für den Kunden nachteilig auswirken können. Dies kann beispielsweise der Fall sein, wenn die DFG</p>
      <p>·	selbst in eine Finanzanlage wie einen Fondsanteil investiert hat und daher ein Interesse an weiteren Investoren hat,</p>
      <p>·	eine Kapitalbeteiligung an einem Produktgeber besitzt und aus diesem Grund an der weiteren Verbreitung von dessen Finanzanlagen interessiert ist,</p>
      <p>·	erfolgsbezogene Vergütungen und Zuwendungen erhält oder</p>
      <p>·	persönliche Beziehungen zu einer Person pflegt, die aus einem Kauf oder Verkauf einer bestimmten Finanzanlage einen Vorteil erlangen würde.</p>
      <p>Die gleichen Interessenskonflikte können zwischen den Mitarbeitern der DFG und dem Kunden bestehen. Dabei kommt es einschränkend nur auf diejenigen Mitarbeiter an, die unmittelbar bei der Finanzanlagenberatung mitwirken.</p>
      <p>Die DFG erhält eine Vertriebsvergütung von der Kapitalverwaltungsgesellschaft für die Vermittlung von Anteilen an Der Zukunftsfonds. Diese Vergütung steigt mit dem Fondsvolumen des Der Zukunftsfonds.</p>
      <p>Vor diesem Hintergrund hat die DFG ein wirtschaftliches Interesse an einem erfolgreichen Vertrieb des Der Zukunftsfonds. Rein vorsorglich wird darauf hingewiesen, dass die DFG insoweit kein neutraler Anlagevermittler ist.</p>
      <p>Das Potenzial eines Interessenskonflikts wird im Rahmen der von der DFG angebotenen Dienstleistung dadurch verringert, dass die DFG keine Beratung erbringt, sondern ausschließlich die von Kunden selbständig getroffenen Entscheidungen zur Abgabe bestimmter Orders weiterleitet.</p>
    </li>
  </ul>
);

export default DfgContractInfo;
