import * as t from '@/actions/types';

const initialState = '';

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      return payload.userInfo.accountInformation.customerNumber || '';

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getCustomerNumber(state) {
  return state.user.customerNumber;
}
