import camelCase from 'camel-case';
import snakeCase from 'snake-case';

import whiteList from '@/constants/casingWhiteList';

const c = { camelCase, snakeCase };

export function convertCasing(input, toCase, fromCase) {
  if (Array.isArray(input)) {
    return convertArrayCasing(input, toCase, fromCase);
  } else if (typeof input === 'object' && input !== null) {
    return convertObjectCasing(input, toCase, fromCase);
  } else if (typeof input === 'string') {
    return convertStringCasing(input, toCase, fromCase);
  }
  return input;
}

function convertArrayCasing(input, toCase, fromCase) {
  return input.map(i => {
    return convertCasing(i, toCase, fromCase);
  });
}

function convertObjectCasing(input, toCase, fromCase) {
  const output = {};
  Object.entries(input).forEach(entry => {
    const convertedKey = c[toCase](entry[0]);
    const doChangeValue = whiteList.some(e => convertedKey === e);

    if (typeof entry[1] === 'string' && !doChangeValue) {
      output[convertedKey] = entry[1];
    } else {
      output[convertedKey] = convertCasing(entry[1], toCase, fromCase);
    }
  });

  return output;
}

function convertStringCasing(input, toCase, fromCase) {
  if (c[fromCase](input) === input) {
    return c[toCase](input);
  }
  return input;
}
