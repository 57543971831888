import * as t from '@/actions/types';
import * as currency from '@/helpers/currency';

const initialState = {
    initialOrder: 0,
    initialSavingsRate: 0,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case t.FETCHED_USER_INFO:
      return payload.userInfo.initialInvestment;
    case t.RESET_SESSION:
      return initialState;
    default:
      return state;
  }
};

export function getUserInitialInvestment(state) {
  return state.user.initialInvestment;
}

export function getFormattedInitialInvestment(state) {
  const initialInvestment = getUserInitialInvestment(state);
  return {
    initialOrder: currency.formatDisplayValue(initialInvestment.initialOrder || '0'),
    initialSavingsRate: currency.formatDisplayValue(initialInvestment.initialSavingsRate || '0'),
  };
}
