import React from 'react';

import * as texts from '@/constants/texts';

const ChangeBankInfo = () => (
  <div className='change-bank-info'>
    <h1 className='change-bank-info__title'>{texts.headings.others.changeBankInfo}</h1>
    <div className='change-bank-info__text'>
      <p>{texts.changeBankInfo.generalInfo}</p>
      <p>{texts.changeBankInfo.stepsInfo}</p>
      <ol>
        <li>
          {texts.changeBankInfo.first}
          <a
            href='/static/pdf/Anlegen_Aendern_Referenzkonto.pdf'
            target='_blank'
            rel='noopener noreferrer'
            className='change-bank-info__link'
          >
            {texts.changeBankInfo.linkTextForFirst}
          </a>.
        </li>
        <li>{texts.changeBankInfo.second}</li>
        <li>{texts.changeBankInfo.third}</li>
        <li>{texts.changeBankInfo.fourth}</li>
      </ol>
    </div>
  </div>
);

export default ChangeBankInfo;
