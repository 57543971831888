import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_MESSAGE:
      return payload.message;

    case t.FETCHED_MESSAGE_ATTACHMENT:
      return {
        ...state,
        attachment: payload.attachment,
      };

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getMessageDetail(state) {
  return state.messages.detail;
}
