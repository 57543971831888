import { getFormValues } from 'redux-form';

import ajax from '@/adapters/ajax';
import * as t from '@/actions/types';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import * as routes from '@/router';
import getEndpoint from '@/constants/endpoints';
import { isServerError } from '@/helpers/errors';
import { serverError } from '@/constants/errors';

export default (store, client = ajax, collector = getFormValues, getters = selectors) => next => async action => {
  const { type } = action;

  if (type === t.SUBMIT_NEW_PASSWORD) {
    const endpoint = getEndpoint('newPassword');
    const newPasswordForm = collector('newPassword')(store.getState()) || {};
    const requestData = { user: newPasswordForm };

    try {
      store.dispatch(actions.activateLoading('isSubmittingForm'));
      const response = await client(endpoint, 'put', undefined, requestData);

      store.dispatch(actions.resetErrors(null, 'newPassword'));
      store.dispatch(actions.addAlert(response[0].message));
      store.dispatch({ type: routes.START });

    } catch(error) {
      if (isServerError(error)) {
        return store.dispatch(actions.addError(serverError));
      }

      const errorBody = error[0] || {};
      const errors = errorBody.errors || {};

      if (errors.reset_password_token) {
        store.dispatch({ type: routes.START });
        store.dispatch(actions.addError(errors.reset_password_token[0]));
      } else {
        store.dispatch(actions.validationError('newPassword', errors));
      }

    } finally {
      store.dispatch(actions.deactivateLoading('isSubmittingForm'));
    }
  } else {
    return next(action);
  }
};
