import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, isDirty, getFormValues } from 'redux-form';
import { redirect } from 'redux-first-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import Form from '@/components/generic/Form';
import SideBySide from '@/components/generic/SideBySide';
import LabeledInput from '@/components/generic/LabeledInput';
import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';

import { otherTexts as otherLabels, initialInvestment as texts } from '@/constants/texts';
import { initialInvestment as labels } from '@/constants/forms';
import { ENTER_PERSONAL_INFO } from '@/router';
import * as currency from '@/helpers/currency';

const mapStateToProps = (state) => {
  const initialInvestment = selectors.getUserInitialInvestment(state);
  const { initialOrder, initialSavingsRate } = getFormValues('initialInvestment')(state) || {};
  return {
    errors: selectors.getFormErrors(state, 'initialInvestment'),
    isDirty: isDirty('initialInvestment')(state),
    graphSettings: selectors.getGraphSettings(state),
    initialValues: {
      initialOrder: initialInvestment.initialOrder || '',
      initialSavingsRate: initialInvestment.initialSavingsRate || '',
    },
    initialOrder: initialOrder ? parseFloat(initialOrder) : 0,
    initialSavingsRate: initialSavingsRate ? parseFloat(initialSavingsRate) : 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    const successActions = [
      actions.setUserDataForm.bind({}, 'initialInvestment', false),
      actions.fetchData.bind({}, 'userInfo'),
      actions.track('EEaddToCart'),
    ];
    e.preventDefault();
    return dispatch(actions.submitForm('initialInvestment', 'initialInvestment', true, successActions));
  },
  onPreviousStep: (isDirty) => {
    if (isDirty) {
      dispatch(actions.showModal({
        heading: otherLabels.previousStep,
        text: otherLabels.previousStepModalText,
        modalAction: redirect.bind({}, { type: ENTER_PERSONAL_INFO }),
        showCloseButton: true,
      }));
    } else {
      dispatch(redirect({ type: ENTER_PERSONAL_INFO }));
    }
  },
});

const InitialInvestmentForm = ({
  showBackButton = false,
  isDirty = false,
  initialOrder,
  initialSavingsRate,
  legendText = '',
  info = '',
  submitButtonLabel = otherLabels.confirmChanges,
  graphSettings = {},
  errors = {},
  onSubmit,
  onPreviousStep,
}) => (
  <Form legend={legendText} onSubmit={onSubmit} info={texts.info} errors={errors}>
    <SideBySide>
      <Field
        name='initialOrder'
        label={labels.initialOrder}
        type='text'
        normalize={currency.normalizeNoDecimalPlaces}
        prefix='€'
        icon='pencil'
        component={LabeledInput}
        errors={errors.initialOrder}
      />
      <Field
        name='initialSavingsRate'
        label={labels.initialSavingsRate}
        type='text'
        normalize={currency.normalizeNoDecimalPlaces}
        prefix='€'
        icon='pencil'
        affixClassName='appended-affix'
        component={LabeledInput}
        errors={errors.initialSavingsRate}
      />
    </SideBySide>
    <Button type='submit'>
      {submitButtonLabel}
    </Button>
    {showBackButton &&
      <BackButton onClick={() => onPreviousStep(isDirty)}>
        {otherLabels.previousStep}
      </BackButton>
    }
  </Form>
);

InitialInvestmentForm.propTypes = {
  showBackButton: PropTypes.bool,
  isDirty: PropTypes.bool,
  initialOrder: PropTypes.number,
  initialSavingsRate: PropTypes.number,
  legendText: PropTypes.string,
  info: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  graphSettings: PropTypes.object,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onPreviousStep: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'initialInvestment',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }),
)(InitialInvestmentForm);
