import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import { orderRestrictions as errorTexts } from '@/constants/errors';
import { payInPayOut as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import * as currency from '@/helpers/currency';

import LabeledInput from '@/components/generic/LabeledInput';
import Form from '@/components/generic/Form';
import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const orderValue = getFormValues('payIn')(state).orderValue;
  return {
    formattedOrderValue: currency.formatDisplayValue(orderValue),
    errors: selectors.getFormErrors(state, 'payIn'),
    canCreateOrders: selectors.getUserCanCreateOrders(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch((dispatch, getState) => {
      const orderValue = getFormValues('payIn')(getState()).orderValue;
      const validAction = actions.showOrderConfirmationModal.bind({}, 'payIn', orderValue);

      dispatch(actions.validateOrder('payIn', validAction));
    });
  },
  onCancel: () => dispatch(actions.redirectDashboard()),
});

const PayInForm = ({
  canCreateOrders = true,
  formattedOrderValue = 0,
  errors = {},
  onSubmit,
  onCancel,
}) => (

  <Form onSubmit={onSubmit} legend={texts.headings.others.payIn} info={texts.hints.payIn}>
    {!canCreateOrders && <p>{errorTexts.buy}</p>}
    <Field
      name='orderValue'
      type='text'
      normalize={currency.normalizeInput}
      prefix='€'
      icon='pencil'
      label={labels.payInAmount}
      required={true}
      component={LabeledInput}
      errors={errors.orderValue}
      disabled={!canCreateOrders}
    />
    <RequiredHint />
    <Button type='submit' disabled={!canCreateOrders || !formattedOrderValue}>
      {labels.submitPayIn}
    </Button>
    <BackButton onClick={onCancel}>
      {texts.otherTexts.cancel}
    </BackButton>
  </Form>
);

PayInForm.propTypes = {
  canCreateOrders: PropTypes.bool,
  formattedOrderValue: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export { PayInForm };
export default compose(
  reduxForm({
    form: 'payIn',
    initialValues: {
      orderValue: null,
      orderType: 'buy', // no Field needed for orderType - only for API
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(PayInForm);
