import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';
import { confirmIdentity as labels } from '@/constants/texts';
import * as selectors from '@/reducers/selectors';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';
import HelpText from '@/components/generic/HelpText';
import ButtonLink from '@/components/generic/ButtonLink';

const mapStateToProps = (state) => {
  const onboardingStatus = selectors.getOnboardingStatus(state) || {};
  const loading = selectors.getLoadingState(state, 'isCheckingDepotRequestStatus');
  const { identificationUrl } = onboardingStatus;
  const timeoutReached = !identificationUrl && !loading;
  return { identificationUrl, timeoutReached };
};

const mapDispatchToProps = (dispatch) => ({
  onOpenIdentification: () => dispatch(actions.redirectOnboardingStatus()),
});

const ConfirmIdentity = ({ identificationUrl = '', onOpenIdentification, timeoutReached }) => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={1} />
      <HelpText>{labels.helpText}</HelpText>
    </HelpArea>
    <div className='confirm-identity'>
      <h1 className='confirm-identity__headline'>{labels.headline}</h1>
      <p className='confirm-identity__text'>{labels.info}</p>
      <div className='confirm-identity__methods'>
        <div className='confirm-identity__panel'>
          <img className='confirm-identity__image-post' src='/static/images/verify-id-post.svg' alt='' />
          <h2 className='confirm-identity__title'>{labels.postIdentification.heading}</h2>
          <p>{labels.postIdentification.text}</p>
        </div>

        <div className='confirm-identity__panel'>
          <div className='confirm-identity__recommendation'>{labels.videoIdentification.recommendation}</div>
          <img className='confirm-identity__image-hand' src='/static/images/verify-id-hand.svg' alt='' />
          <h2 className='confirm-identity__title'>{labels.videoIdentification.heading}</h2>
          <ul className='confirm-identity__list'>
            {labels.videoIdentification.list.map((item) => (
              <li className='confirm-identity__list-item' key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>

      {(!identificationUrl && !timeoutReached) &&
        <ButtonLink
          to={['']}
          linkStyle='loading'
          disabled={true}
        >
          {labels.waitButtonText}
        </ButtonLink>
      }

      {timeoutReached &&
        <p className='confirm-identity__time-out'>{labels.timeoutReachedText}</p>
      }

      {identificationUrl &&
        <ButtonLink
          to={identificationUrl}
          target='_blank'
          onClick={onOpenIdentification}
        >
          {labels.buttonText}
        </ButtonLink>
      }
    </div>
  </OnboardingLayout>
);

ConfirmIdentity.propTypes = {
  identificationUrl: PropTypes.string,
  onOpenIdentification: PropTypes.func,
  timeoutReached: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdentity);
