import React from 'react';

import FrontFormLayout from '@/components/specific/FrontFormLayout';
import LoginForm from '@/components/forms/LogInForm';

const LogIn = () => (
  <FrontFormLayout>
    <LoginForm />
  </FrontFormLayout>
);

export { LogIn };
export default LogIn;
