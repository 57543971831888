import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import classnames from 'classnames';

const ButtonLink = ({ to, children, linkStyle, disabled, target, onClick}) => {
  const classes = classnames('button-link', {
    'button-link--outlined': linkStyle === 'outlined',
    'button-link--loading': linkStyle === 'loading',
    'button-link--disabled': disabled,
  });

  return (
    <Link className={classes} to={to} target={target} onClick={onClick}>
      {linkStyle === 'loading' &&
        <img className='button-link__spinner' src='/static/images/loading.svg' alt='loading spinner' />
      }
      {children}
    </Link>
  );
};

ButtonLink.propTypes = {
  children: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.object]).isRequired,
  linkStyle: PropTypes.string,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonLink;
