import { getFormValues } from 'redux-form';

import * as actions from '@/actions/creators';
import * as t from '@/actions/types';
import * as selectors from '@/reducers/selectors';

export function fetchData(dataType, entity = '') {
  const options = {
    method: 'get',
    useToken: true,
    successDispatch: [],
    errorDispatch: [],
  };

  switch (dataType) {
    case 'userInfo':
      options.endpointName = 'userInfo';
      options.successDispatch.push(actions.fetchedUserInfo);
      break;

    case 'userPortfolio':
      options.endpointName = 'userPortfolio';
      options.successDispatch.push(actions.fetchedUserPortfolio);
      break;

    case 'orderHistory':
      options.endpointName = 'orderHistory';
      options.successDispatch.push(actions.fetchedOrderHistory);
      break;

    case 'legalAgreements':
      options.useToken = false;
      options.endpointName = 'legalAgreements';
      options.successDispatch.push(actions.fetchedLegalAgreements);
      break;

    case 'options':
      options.useToken = false;
      options.endpointName = 'formOptions';
      options.successDispatch.push(actions.fetchedFormOptions);
      break;

    case 'savingsRate':
      options.endpointName = 'savingsRate';
      options.successDispatch.push(actions.fetchedSavingsRate);
      break;

    case 'messages':
      options.endpointName = 'messages';
      options.successDispatch.push(actions.fetchedMessages);
      break;

    case 'message':
      options.endpointName = 'messages';
      options.endpointEntity = entity;
      options.successDispatch.push(actions.fetchedMessage);
      options.errorDispatch.push(actions.redirectInboxOverview);
      break;

    case 'attachment':
      options.endpointName = 'messages';
      options.endpointEntity = entity;
      options.responseType = 'blob';
      options.successDispatch.push(actions.fetchedAttachment);
      break;

    case 'graphSettings':
      options.endpointName = 'graphSettings';
      options.successDispatch.push(actions.fetchedGraphSettings);
      break;

    default:
      return { type: '' };
  }

  return actions.sendRequest(options);
}

export function fetchedUserInfo(response) {
  return {
    type: t.FETCHED_USER_INFO,
    payload: { userInfo: response.user },
  };
}

export function fetchedUserPortfolio(response) {
  return {
    type: t.FETCHED_USER_PORTFOLIO,
    payload: { portfolio: response.portfolio },
  };
}

export function fetchedOrderHistory(response) {
  return {
    type: t.FETCHED_ORDER_HISTORY,
    payload: { orders: response.transactions },
  };
}

export function fetchedLegalAgreements(response) {
  return {
    type: t.FETCHED_LEGAL_AGREEMENTS,
    payload: { legalAgreements: response },
  };
}

export function fetchFormOptions(response) {
  return {
    type: t.FETCH_FORM_OPTIONS,
    payload: { options: response },
  };
}

export function fetchedFormOptions(options, formName) {
  return {
    type: t.FETCHED_FORM_OPTIONS,
    payload: { options, formName },
  };
}

export function fetchedSavingsRate(response) {
  return {
    type: t.FETCHED_SAVINGS_RATE,
    payload: { ...response },
  };
}

export function fetchedMessage(response) {
  return async function(dispatch) {
    const fetchedMessage = dispatch({
      type: t.FETCHED_MESSAGE,
      payload: { ...response },
    });

    if (response.message.attachmentPath) {
      const attachment = response.message.attachmentPath.split('/').pop();
      await dispatch(actions.fetchData('attachment', attachment));
    }

    return fetchedMessage;
  };
}

export function fetchedMessages(response) {
  return {
    type: t.FETCHED_MESSAGES,
    payload: { ...response },
  };
}

export function fetchedGraphSettings(response) {
  return {
    type: t.FETCHED_GRAPH_SETTINGS,
    payload: { ...response },
  };
}

export function markMessageRead(messageId) {
  return actions.sendRequest({
    method: 'put',
    useToken: true,
    endpointName: 'messages',
    endpointEntity: messageId,
    endpointAddition: 'read',
  });
}

export function fetchedAttachment(response) {
  return {
    type: t.FETCHED_MESSAGE_ATTACHMENT,
    payload: {
      attachment: response,
    },
  };
}

export function validateIban(iban) {
  return actions.sendRequest({
    method: 'post',
    useToken: true,
    endpointName: 'validateIban',
    requestData: { iban },
    successDispatch: [actions.resetErrors.bind({}, null, 'bankInfo')],
    errorDispatch: [actions.showIbanValidationError],
  });
}

export function showIbanValidationError(response) {
  return {
    type: t.VALIDATION_ERROR,
    payload: {
      formName: 'bankInfo',
      error: response.errors,
    },
  };
}

export function updateUserInfo(formName) {
  return (dispatch, getState, collectors = getFormValues) => {
    const formValues = collectors(formName)(getState());

    const action = actions.sendRequest({
      method: 'put',
      useToken: true,
      endpointName: formName,
      requestData: {
        user: formValues,
      },
      showSuccessMessage: true,
      successDispatch: [actions.redirectUserInfo],
      errorDispatch: [actions.showFormValidationError.bind({}, formName)],
    });

    return dispatch(action);
  };
}

export function resendActivationCode() {
  return (dispatch, getState, getters = selectors) => {
    const email = getters.getUserEmail(getState());

    const action = actions.sendRequest({
      method: 'post',
      useToken: true,
      showSuccessMessage: true,
      endpointName: 'activationCode',
      requestData: {
        user: {
          email,
        },
      },
    });

    return dispatch(action);
  };
}

export function confirmSignupEmail(confirmationToken, ...successDispatch) {
  const options = {
    method: 'post',
    useToken: true,
    endpointName: 'confirmSignupEmail',
    requestData: {
      confirmationToken,
    },
    showSuccessMessage: true,
    errorMessageKey: 'confirmationToken',
    successDispatch: [...successDispatch],
  };

  return (dispatch, getState, collectors = getFormValues) => {
    if (!confirmationToken) {
      const formValues = collectors('confirmSignupEmail')(getState()) || {};
      const tokenFromForm = formValues.confirmationToken;

      options.requestData.confirmationToken = tokenFromForm;
    }

    const action = actions.sendRequest(options);

    return dispatch(action);
  };
}

export function unlockAccount(unlockToken) {
  return actions.sendRequest({
    method: 'put',
    useToken: false,
    endpointName: 'unlockAccount',
    requestData: {
      unlockToken,
    },
    showSuccessMessage: true,
    successDispatch: [actions.redirectStart],
    errorMessageKey: 'unlockToken',
  });
}

export function confirmChangedEmail() {
  return (dispatch, getState, collectors = getFormValues) => {
    const formValues = collectors('confirmChangedEmail')(getState());

    const action = actions.sendRequest({
      method: 'put',
      useToken: false,
      endpointName: 'confirmChangedEmail',
      requestData: {
        user: formValues,
      },
      showSuccessMessage: true,
      errorMessageKey: 'global',
      successDispatch: [actions.redirectStart],
      errorDispatch: [actions.showFormValidationError.bind({}, 'confirmChangedEmail')],
    });

    return dispatch(action);
  };
}

export function showFormValidationError(formName, response) {
  return {
    type: t.VALIDATION_ERROR,
    payload: {
      formName,
      error: response.errors,
    },
  };
}
