import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeDataInfo as texts, letters } from '@/constants/texts';
import { buildDepotInfo } from '@/helpers/strings';
import * as selectors from '@/reducers/selectors';

const mapStateToProps = (state) => {
  const portfolioNumber = selectors.getUserPortfolioNumber(state);
  const customerNumber = selectors.getCustomerNumber(state);

  return {
    depotInfo: buildDepotInfo(null, portfolioNumber, customerNumber),
  };
};

const UserDataInfo = ({ formName, depotInfo }) => (
  <div className='user-data-info'>
    <h2 className='user-data-info__title'>{texts[formName].title} {texts.change}</h2>
    <div className='user-data-info__text'>
      <p>{texts[formName].headline}</p>
      <p>{texts.sendTo}</p>
      <p>
        {letters.addressFfb.map(line => [
          <span key='line'>{line}</span>,
          <br key='br' />,
        ])}
      </p>
      <p>{texts.changeInfo}</p>
      <ul>
        {depotInfo.split(', ').map(i => <li key={i}>{i}</li>)}
        <li>{texts.nameAddress}</li>
        <li>{texts.sign}</li>
      </ul>
      {formName === 'personalInfo' &&
        <p><strong>{texts.changeName}</strong> {texts.addDocuments}</p>
      }
    </div>
  </div>
);

UserDataInfo.propTypes = {
  formName: PropTypes.string,
  depotInfo: PropTypes.string,
};

export default connect(mapStateToProps)(UserDataInfo);
