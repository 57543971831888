import * as actions from '@/actions/creators';

export function submitForm(formName, wrapperName, isOnboarding = false, onSuccess = []) {
  const options = {
    useToken: true,
    formName,
    wrapperName,
    showSuccessMessage: false,
    successDispatch: [...onSuccess],
  };

  if (isOnboarding) {
    options.successDispatch.push(actions.fetchOnboardingStatusAndForward);
  }

  return actions.submit(options);
}

export function submitSignUp() {
  return actions.submit({
    useToken: false,
    formName: 'signUp',
    wrapperName: 'user',
    showSuccessMessage: true,
    successDispatch: [
      actions.fetchOnboardingStatusAndForward,
      actions.track('registrationSuccess'),
    ],
  });
}

export function submitLogin() {
  return actions.submit({
    useToken: false,
    formName: 'logIn',
    wrapperName: 'user',
    showSuccessMessage: false,
    successDispatch: [
      actions.fetchOnboardingStatus,
      actions.track('loginSuccess'),
    ],
  });
}

export function changeSignupEmail() {
  return actions.submit({
    useToken: true,
    formName: 'changeSignupEmail',
    wrapperName: 'user',
    method: 'put',
    showSuccessMessage: true,
    successDispatch: [
      actions.fetchOnboardingStatusAndForward,
    ],
  });
}

export function submitChangePassword() {
  return actions.submit({
    useToken: true,
    wrapperName: 'user',
    formName: 'changePassword',
    method: 'put',
    showSuccessMessage: true,
    successDispatch: [
      actions.resetSession,
      actions.redirectStart,
    ],
  });
}

export function submitPersonalInfo(isOnboarding = false, isUpdate = false, onSuccess = []) {
  return actions.submit({
    useToken: true,
    wrapperName: 'personalInformation',
    formName: 'personalInfo',
    endpointName: isOnboarding ? 'personalInfo' : 'changePersonalInfo',
    method: isUpdate ? 'put' : 'post',
    showSuccessMessage: isUpdate,
    successDispatch: [...onSuccess],
  });
}

export function submitContactInfo(isUpdate = false, onSuccess = []) {
  return actions.submit({
    useToken: true,
    wrapperName: 'contactInformation',
    formName: 'contactInfo',
    method: isUpdate ? 'put' : 'post',
    showSuccessMessage: isUpdate,
    successDispatch: [...onSuccess],
  });
}

export function submitBankInfo(isUpdate = false, onSuccess = []) {
  return actions.submit({
    useToken: true,
    wrapperName: 'bankInformation',
    formName: 'bankInfo',
    method: 'post',
    showSuccessMessage: isUpdate,
    successDispatch: [...onSuccess],
  });
}

export function submitLegalAgreements(onSuccess = []) {
  return actions.submit({
    useToken: true,
    wrapperName: 'legalAgreements',
    formName: 'legalAgreements',
    method: 'post',
    showSuccessMessage: false,
    successDispatch: [...onSuccess],
  });
}
