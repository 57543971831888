import * as selectors from '@/reducers/selectors';
import ajax from '@/adapters/ajax';
import getEndpoints from '@/constants/endpoints';

export const validator = {
  isPortfolioOrderPending: async (state, lStorage = window.localStorage, client = ajax) => {
    let portfolioOrderStatus = null;
    const onboardingStatus = selectors.getOnboardingStatus(state);
    if (onboardingStatus === {}) {
      portfolioOrderStatus = onboardingStatus.portfolioOrderStatus;
    } else {
      const token = lStorage.getItem('token');
      const userId = lStorage.getItem('userId');
      const endpoint = getEndpoints('onboardingStatus');

      try {
        const response = await client(endpoint, 'get', { token, userId });
        if (response[1].status === 200) {
          portfolioOrderStatus = response[0].onboardingStatus.portfolioOrderStatus;
        }

      } catch (error) {}
    }

    return 'pending' === portfolioOrderStatus;
  },

  validateLogin: async (lStorage = window.localStorage, client = ajax) => {
    const token = lStorage.getItem('token');
    const userId = lStorage.getItem('userId');

    if (!token) {
      return { isValid: false };
    }

    const endpoint = getEndpoints('loginValidation');

    try {
      const response = await client(endpoint, 'get', { token, userId });
      if (response[1].status === 200) {

        let role = response[0].user.role;
        let isDeleted = false;
        if (role === 'marked_for_deletion') {
          role = 'customer';
          isDeleted = true;
        }

        return { isValid: true, token, role, isDeleted, userId };
      }

      lStorage.removeItem('token');
      lStorage.removeItem('userId');
      return { isValid: false };

    } catch(error) {
      lStorage.removeItem('token');
      lStorage.removeItem('userId');
      return { isValid: false };
    }
  },
};
