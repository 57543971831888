export const ACCOUNT_ACTIVATION = {
  key: 'emailConfirmed',
  routeAction: 'router/ACTIVATE_ACCOUNT',
  label: 'Konto aktiviert',
  step: 1,
};
export const CONTACT_INFORMATION = {
  key: 'contactInformation',
  routeAction: 'router/ENTER_CONTACT_INFO',
  label: 'Kontaktdaten',
  step: 2,
};
export const PERSONAL_INFORMATION = {
  key: 'personalInformation',
  routeAction: 'router/ENTER_PERSONAL_INFO',
  label: 'Persönliche Daten',
  step: 3,
};
export const INITIAL_INVESTMENT = {
  key: 'initialInvestment',
  routeAction: 'router/ENTER_INITIAL_INVESTMENT',
  label: 'Initialer Sparplan',
  step: 4,
};
export const BANK_INFORMATION = {
  key: 'bankInformation',
  routeAction: 'router/ENTER_BANK_INFO',
  label: 'Bankdaten',
  step: 5,
};
export const ACCEPT_LEGAL_AGREEMENTS = {
  key: 'legalAgreements',
  routeAction: 'router/ACCEPT_LEGAL_AGREEMENTS',
  label: 'Geschäftsbedingungen',
  step: 6,
};
export const ONBOARDING_SUMMARY = {
  key: 'portfolioRequest',
  routeAction: 'router/ONBOARDING_SUMMARY',
  label: 'Zusammenfassung',
  step: 7,
};
export const CONFIRM_IDENTITY = {
  key: 'identificationMethod',
  routeAction: 'router/CONFIRM_IDENTITY',
  label: 'Identifizierung',
  step: 8,
};

export const steps = [
  'Benutzerkonto anlegen',
  'Sparvorhaben gestalten',
  'Identität bestätigen',
];

export default [
  ACCOUNT_ACTIVATION,
  CONTACT_INFORMATION,
  PERSONAL_INFORMATION,
  INITIAL_INVESTMENT,
  BANK_INFORMATION,
  ACCEPT_LEGAL_AGREEMENTS,
  ONBOARDING_SUMMARY,
  CONFIRM_IDENTITY,
];
