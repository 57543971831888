import React from 'react';
import Link from 'redux-first-router-link';

import { START } from '@/router';

import * as texts from '@/constants/texts';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';

const NotFound = () => (
  <OnboardingLayout>
    <HelpArea>
      <div className='not-found__aside'>
        <img className='not-found__image' src='/static/images/safe-open.svg' alt='' />
      </div>
    </HelpArea>
    <div>
      <h1 className='not-found__headline'>{texts.notFound.fourOhFour}</h1>
      <h2 className='not-found__subheadline'>{texts.notFound.pageNotFound}</h2>
      <p className='not-found__text'>{texts.notFound.text}</p>
      <Link className='not-found__return' to={{ type: START }}>{texts.backLinks.backToStart}</Link>
    </div>
  </OnboardingLayout>
);

export default NotFound;
