/* eslint-disable */

import React from 'react';

//TODO: how to outsource those texts into constants?
const DfgAgreements = () => (
  <ul>
    <li>
      <p>Diese Allgemeinen Geschäftsbedingungen regeln das Vertragsverhältnis („VERTRAG“) zwischen der DFG Deutsche Fondsgesellschaft SE Invest, Schlüterstr. 40, 10707 Berlin („DFG“) und einem von der DFG für die Nutzung der Dienstleistungen auf www.der-zukunftsfonds.de angebotenen Dienstleistungen registrierten und von der DFG freigeschalteten Teilnehmer ("KUNDE"). Der VERTRAG kommt zwischen dem KUNDEN und der DFG erst zustande, wenn die DFG dem KUNDEN den erfolgreichen Abschluss des Registrierungsprozesses und die Freischaltung des Benutzerkontos (das „BENUTZERKONTO“) bestätigt und die DFG damit das Angebot des KUNDEN auf Abschluss des VERTRAGES annimmt. Ein Anspruch auf Abschluss des VERTRAGES besteht nicht.</p>
      <h5>1.	Einleitung</h5>
      <h5>1.1	Die DFG besitzt eine Erlaubnis als Finanzanlagenvermittler nach § 34f Abs. 1 Satz 1 Nr. 1 GewO für Anteile oder Aktien an inländischen offenen EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen. Die DFG ist in das Vermittlerregister des Deutschen Industrie- und Handelskammertags unter der Registrierungsnummer D-F-107-LCJ5-32 eingetragen.</h5>
      <h5>1.2	Die DFG betreibt unter der Webadresse „www.der-zukunftsfonds.de“ eine WEBSEITE (die „WEBSEITE“). Über die WEBSEITE bemüht sich die DFG um eine Vermittlung von Geschäften über die Anschaffung und Veräußerung von Anteilen an „Der Zukunftsfonds“, einem als Sondervermögen aufgelegten Organismus für gemeinsame Anlagen in Wertpapieren (WKN: A2DTM6) (der „DER ZUKUNFTSFONDS“). Die DFG richtet sich mit diesem Angebot ausschließlich an Anleger, deren Wohnsitz sich zum Zeitpunkt des Vertragsschlusses ausschließlich in Deutschland befindet.</h5>
      <h5>1.3	Die DFG bietet KUNDEN zudem die Möglichkeit, sich auf der WEBSEITE zu registrieren und ein BENUTZERKONTO anzulegen Die DFG bemüht sich ferner, den KUNDEN die Eröffnung eines Wertpapierdepots bei einem in Deutschland ansässigen Kreditinstitut (die „KOOPERATIONSBANK“) zu vermitteln. Die gegenwärtige KOOPERATIONSBANK kann unter http://www.der-zukunftsfonds.de/partnerbank eingesehen werden und wird dem KUNDEN im konkreten Fall auch im Rahmen des Registrierungsprozesses offen gelegt. Die KOOPERATIONSBANK führt Anweisungen des KUNDEN zum Kauf- und Verkauf von Anteilen an DER ZUKUNFTSFONDS im Rahmen des Finanzkommissionsgeschäft aus, das heißt die KOOPERATIONSBANK handelt bei der Anschaffung und Veräußerung der Fondsanteile im eigenen Namen für Rechnung des KUNDEN. In dem Wertpapierdepot bei der KOOPERATIONSBANK kann der KUNDE die erworbenen Anteile an DER ZUKUNFTSFONDS verwahren lassen. Die gesamte Vertragsbeziehung zwischen der KOOPERATIONSBANK und dem KUNDEN richtet sich allein nach dem gesondert abzuschließenden Vertrag zwischen dem KUNDEN und der KOOPERATIONSBANK, insbesondere den Allgemeinen Geschäftsbedingungen sowie das Preis- & Leistungsverzeichnis der KOOPERATIONSBANK.</h5>
      <h5>1.4	Über die WEBSEITE hat der KUNDE die Möglichkeit, bestimmte Informationen über sein bei der KOOPERATIONSBANK geführtes Depot abzurufen und bestimmte vordefinierte Anweisungen zum Kauf und Verkauf sowie zur Einrichtung von Fondssparplänen, bei denen der Anleger regelmäßig zu einem vorher festgelegten Betrag Fondsanteile erwirbt, an die KOOPERATIONSBANK zu übermitteln.</h5>
      <h5>2.	Vermittlung eines Wertpapierdepots zur Verwahrung von Investmentanteilen</h5>
      <h5>2.1	Vermittlung und Kostenübernahme für die Depotführung</h5>
      <h5>2.1.1	Die DFG leitet den Antrag des KUNDEN zum Abschluss eines Depotvertrags mit der KOOPERATIONSBANK an die KOOPERATIONSBANK weiter. Die DFG haftet nicht für die Erfüllung der KOOPERATIONSBANK ihrer Verpflichtungen unter dem separat zwischen KOOPERATIONSBANK und KUNDEN abgeschlossenen Depotvertrag.</h5>
      <h5>2.1.2	Kommt ein Depotvertrag zwischen dem KUNDEN und der KOOPERATIONSBANK nach Vermittlung unter Ziffer 2.1.1 zustande, übernimmt die DFG für den KUNDEN die laufenden Depotführungsgebühren. Aus dem Depotvertrag mit der KOOPERATIONSBANK können dem KUNDEN weitere Kosten für gesonderte, durch den KUNDEN ausdrücklich in Anspruch genommene Dienstleistungen entstehen, für welche die DFG die Kosten nicht trägt. Die Kostentragungspflicht der DFG nach diesem Absatz steht unter der Bedingung, dass sowohl das Kooperationsverhältnis zwischen der DFG und der KOOPERATIONSBANK als auch dieser VERTRAG zwischen der DFG und dem KUNDEN fortbesteht.</h5>
      <h5>2.2	Wechsel der KOOPERATIONSBANK</h5>
      <h5>2.2.1	Um dieses Angebot für den KUNDEN bereitzustellen, hat die DFG einen Kooperationsvertrag mit der KOOPERATIONSBANK abgeschlossen. Für den Fall, dass diese Kooperation zwischen der DFG und der KOOPERATIONSBANK endet, wird sich die DFG bemühen, dass der KUNDE die Möglichkeit erhält, bei einem anderen Kreditinstitut ein Depot zu eröffnen. Auch für dieses ersatzweise zu eröffnende Depot wird die DFG die Depotführungsgebühren nach Maßgabe von Ziffer 2.1.2 tragen.</h5>
      <h5>2.2.2	Um bei einem Wechsel der KOOPERATIONSBANK auch weiterhin das Depot ohne Depotführungsgebühren in Anspruch nehmen zu können, ist es erforderlich, dass der KUNDE bei dem Abschluss des Depotvertrags mit der neuen KOOPERATIONSBANK mitwirkt. Dies umfasst insbesondere die Zurverfügungstellung von Informationen im Rahmen der Vertragsanbahnung (inklusive geldwäscherechtlicher Prüfungen), die Antragstellung auf Eröffnung eines Depots bei der neuen Bank sowie den Antrag an die KOOPERATIONSBANK, die Bestände des Depots an die neue KOOPERATIONSBANK zu übertragen.</h5>
      <h5>2.2.3	Dem KUNDEN ist bewusst, dass im Fall der Beendigung der Kooperation zwischen der KOOPERATIONSBANK und der DFG die Übernahme der Depotführungskosten durch die DFG entfällt, sodass der KUNDE die sich nach dem Vertragsverhältnis zwischen der KOOPERATIONSBANK und dem KUNDEN richtenden Depotführungsgebühren selber zu tragen hat.</h5>
      <h5>3.	Vermittlung von Geschäften über die Anschaffung und Veräußerung von Investmentanteilen</h5>
      <h5>3.1	Keine Anlageberatung / keine Angemessenheitsprüfung (Execution only)</h5>
      <h5>3.1.1	Das Angebot der DFG richtet sich an Anleger, die sich über die Chancen und Risiken einer Anlage in DER ZUKUNFTSFONDS selbst informieren, und beschränkt sich auf die bloße Weiterleitung des durch den KUNDEN selbständig erteilten Auftrages an die KOOPERATIONSBANK. Die DFG handelt bei der Übermittlung des durch den KUNDEN erteilten Auftrags als Erklärungsbote des KUNDEN.</h5>
      <h5>3.1.2	Die DFG prüft weder, ob eine Anlage in DER ZUKUNFTSFONDS mit den Anlagezielen des KUNDEN übereinstimmt, noch ob der KUNDE über die erforderlichen Kenntnisse und Erfahrungen verfügt, um die Risiken seiner eigenverantwortlich getroffenen Anlageentscheidung angemessen beurteilen zu können.</h5>
      <h5>3.1.3	Die DFG erbringt keine Anlageberatung gegenüber dem KUNDEN. Von der DFG zur Verfügung gestellte Informationen stellen keine Empfehlung zum Kauf oder Verkauf von Anteilen an DER ZUKUNFTSFONDS dar.</h5>
      <h5>3.1.4	Die Ausführung der durch den KUNDEN erteilten Aufträgen liegt in der alleinigen Verantwortung der KOOPERATIONSBANK und richtet sich nach dem Vertragsverhältnis zwischen KUNDE und der KOOPERATIONSBANK. Die DFG hat auf diesen Vorgang keinen Einfluss und übernimmt für die ordnungsgemäße Ausführung der Kundenaufträge keine Haftung.</h5>
      <h5>3.2	Vorvertragliche Aufklärungspflichten</h5>
      <h5>3.2.1	Die DFG stellt dem KUNDEN rechtzeitig vor dem Erwerb von Anteilen an DER ZUKUNFTSFONDS alle relevanten Anlegerinformationen über eine Anlage in DER ZUKUNFTSFONDS, insbesondere, soweit einschlägig,</h5>
      <p>-	die mit Finanzanlagen der betreffenden Art einhergehenden Risiken, BENUTZERKONTO einschließlich einer Erläuterung der Hebelwirkung und ihrer Effekte sowie des Risikos des Verlustes der gesamten Kapitalanlage sowie</p>
      <p>-	das Ausmaß der Schwankungen des Preises (Volatilität) der betreffenden Finanzanlagen und etwaige Beschränkungen des für solche Finanzanlagen verfügbaren Marktes, in Form einer E-Mail als dauerhaften Datenträger zur Verfügung.</p>
      <h5>3.2.2	Hinsichtlich der Kosten und den damit einhergehenden Offenlegungspflichten stellt die DFG dem KUNDEN alle relevanten Informationen, insbesondere, soweit einschlägig,</h5>
      <p>-	Angaben zu dem Gesamtpreis, den der KUNDE im Zusammenhang mit der Finanzanlage und den Dienstleistungen der DFG zu zahlen hat, einschließlich aller damit verbundenen Gebühren, Provisionen, Entgelte und Auslagen, oder, wenn die genaue Preisangabe nicht möglich ist, die Grundlage für die Berechnung des Gesamtpreises, damit der KUNDE diesen überprüfen kann; die vom Gewerbetreibenden in Rechnung gestellten Provisionen sind separat aufzuführen; falls ein Teil des Gesamtpreises in einer Fremdwährung zu zahlen oder in einer anderen Währung als in Euro dargestellt ist, müssen die betreffende Währung und der anzuwendende Wechselkurs sowie die damit verbundenen Kosten oder, wenn die genaue Angabe des Wechselkurses nicht möglich ist, die Grundlage für seine Berechnung angegeben werden,</p>
      <p>-	einen Hinweis auf die Möglichkeit, dass dem KUNDEN aus Geschäften im Zusammenhang mit der Finanzanlage weitere Kosten und Steuern entstehen können, sowie</p>
      <p>-	Bestimmungen über die Zahlung oder sonstige Gegenleistungen in Form einer E-Mail als dauerhaften Datenträger zur Verfügung.</p>
      <h5>3.2.3	Es obliegt dem KUNDEN, alle nach den Ziffern 3.2.1 und 3.2.2 zur Verfügung gestellten Dokumente sorgfältig zu lesen und gegebenenfalls den Rat eines Anlageberaters einzuholen.</h5>
      <h5>4.	BENUTZERKONTO</h5>
      <h5>4.1	Teilnahmevoraussetzungen</h5>
      <h5>4.1.1	KUNDEN können ausschließlich natürliche Personen sein, die das 18. Lebensjahr vollendet haben, uneingeschränkt geschäftsfähig sind, einen Wohnsitz in Deutschland aufweisen und</h5>
      <p>-	diesen VERTRAG akzeptiert haben,</p>
      <p>-	eine postalische Privatanschrift (keine Firmenanschrift) hinterlegt haben, unter welcher ihnen Post- und Briefsendungen zugehen (insbesondere keine Retouren erfolgen),</p>
      <p>-	den Registrierungsprozess des BENUTZERKONTOS erfolgreich abgeschlossen haben und für die Teilnahme freigeschaltet wurden.</p>
      <h5>4.1.2	Die DFG kann die Teilnahme des KUNDEN am BENUTZERKONTO von weiteren Voraussetzungen abhängig machen, wie beispielsweise (a) der erfolgreichen Überprüfung der Identität des KUNDEN durch die KOOPERATIONSBANK oder (b) von Voraussetzungen, die erforderlich sind, um rechtlichen, gerichtlichen oder behördlichen Vorgaben zu entsprechen.</h5>
      <h5>4.1.3	Soweit zur Erfüllung gesetzlicher Vorschriften, einschließlich solcher zur Bekämpfung von Geldwäsche, Terrorismusfinanzierung oder Betrug, sowie gerichtlicher oder behördlicher Anordnungen zusätzliche persönliche Angaben oder Unterlagen vom KUNDEN oder eine Identifizierung seiner Person erforderlich sind oder werden, wird der KUNDE auf entsprechende Anforderung der DFG unverzüglich die erforderlichen Auskünfte erteilen und Unterlagen zur Verfügung stellen.</h5>
      <h5>4.1.4	Das gleiche gilt auf entsprechende Anforderung der DFG für sonstige weitere Auskünfte und Unterlagen, soweit die DFG im Rahmen der Leistungserbringung nach Maßgabe dieses VERTRAGES ein berechtigtes Interesse am Erhalt dieser Auskünfte oder Unterlagen hat, insbesondere im Zusammenhang mit Fragestellungen zu der Bonität des KUNDEN und bei Vorliegen von Betrugs- oder Missbrauchsverdacht.</h5>
      <h5>4.2	Verfügbarkeit, Funktionalität und Qualität</h5>
      <h5>4.2.1	Die DFG bietet dem KUNDEN die Möglichkeit zur Nutzung der WEBSEITE mit den Funktionalitäten und der Qualität, welche die WEBSEITE zum jeweiligen Zeitpunkt aufweist. Angaben zur Funktionalität und Funktionsweise auf der WEBSEITE oder in Werbematerialien der DFG sind nicht Bestandteil dieses VERTRAGES.</h5>
      <h5>4.2.2	Die DFG ist berechtigt, die WEBSEITE jederzeit und ohne Vorankündigung zu ändern, einschließlich einer Anpassung von Funktionalitäten, soweit die Funktionalität des BENUTZERKONTOS grundsätzlich beibehalten bleibt; die DFG ist jedoch zu solchen Änderungen und Anpassungen nicht verpflichtet. Änderungen und Anpassungen können dazu führen, dass der KUNDE zur Weiternutzung der WEBSEITE geeignete technische Maßnahmen treffen muss.</h5>
      <h5>4.2.3	Im Falle eines Wechsels der KOOPERATIONSBANK besteht die Möglichkeit, dass die Nutzbarkeit der WEBSEITE und des BENUTZERKONTOS im Zusammenhang mit dem Depot bei dem vorherigen Kooperationspartner nicht länger gewährleistet werden kann.</h5>
      <h5>4.2.4	Die Verfügbarkeit des BENUTZERKONTOS und der WEBSEITE können durch Wartungsarbeiten vorübergehend eingeschränkt sein. Geplante Wartungsarbeiten werden grundsätzlich zu dafür angemessenen Zeiten durchgeführt und dem KUNDEN rechtzeitig durch die DFG vorher bekanntgegeben.</h5>
      <h5>4.3	Passwort, Nutzungsbeschränkungen und Abtretungs- sowie Übertragungsverbot</h5>
      <h5>4.3.1	Vor Abschluss dieses VERTRAGES vergibt der KUNDE im Rahmen des Registrierungsprozesses ein Passwort zum BENUTZERKONTO über die WEBSEITE, das der KUNDE anhand der entsprechenden Funktion der WEBSEITE ändern kann.</h5>
      <h5>4.3.2	Das Passwort für das BENUTZERKONTO muss mindestens acht Zeichen lang sein und mindestens drei Zeichenklassen enthalten, d.h. mindestens eine Zahl und jeweils einen Groß- und Kleinbuchstaben.</h5>
      <h5>4.3.3	Nur der KUNDE ist zur Nutzung des BENUTZERKONTOS unter Verwendung seines Passwortes berechtigt.</h5>
      <h5>4.3.4	Der KUNDE verpflichtet sich, die WEBSEITE und das BENUTZERKONTO ausschließlich für persönliche, familiäre oder private Zwecke und nicht für geschäftliche, berufliche oder gewerbliche Zwecke zu nutzen.</h5>
      <h5>4.3.5	Der KUNDE darf Rechte und Pflichten aus diesem VERTRAG nicht ohne schriftliche Zustimmung der DFG an Dritte abtreten oder auf Dritte übertragen.</h5>
      <h5>4.4	Änderungen von Angaben, Benachrichtigungen und Form von Erklärungen</h5>
      <h5>4.4.1	Der KUNDE ist verpflichtet, der DFG unverzüglich Änderungen der bei der DFG im Rahmen des Registrierungsprozesses angegebenen Daten mitzuteilen, insbesondere der hinterlegten Postanschrift.</h5>
      <h5>4.4.2	Sofern in diesem VERTRAG keine besondere Kommunikationsform (z.B. E-Mail) vereinbart ist, kann die DFG für Benachrichtigungen an den KUNDEN oder die formwahrende Abgabe von Erklärungen im Zusammenhang die Benachrichtigungsfunktion nutzen, die dem KUNDEN in seinem persönlichen Bereich zu seinem BENUTZERKONTO auf der WEBSEITE zur Verfügung steht.</h5>
      <h5>4.5	Sorgfalts- und Mitwirkungspflichten des Kunden</h5>
      <h5>4.5.1	Der KUNDE ist verpflichtet, eine Weitergabe oder Zugänglichmachung des Passwortes an Dritte zu unterlassen. Der KUNDE ist außerdem verpflichtet, das Passwort sorgfältig aufzubewahren und vor unbefugtem Zugriff durch Dritte zu schützen. Insbesondere bei der Eingabe des Passwortes am Zugangsgerät hat der KUNDE dafür Sorge zu tragen, dass Dritte keine Kenntnis von dem Passwort erlangen oder diese ausspähen können. Das Passwort darf nicht auf dem Zugangsgerät vermerkt oder auf andere Weise zusammen mit dem Zugangsgerät aufbewahrt werden. Die DFG weist darauf hin, dass jede Person, die das Passwort kennt und im Besitz des Zugangsgeräts ist, die WEBSEITE verwenden und das durch den KUNDEN hinterlegte Konto belasten kann.</h5>
      <h5>4.5.2	Sofern der KUNDE Grund zu der Annahme hat, dass (a) Dritte Kenntnis von dem Passwort erlangt haben, (b) sein Zugangsgerät, auf dem relevante Applikationen installiert sind, verloren gegangen ist oder gestohlen wurde oder (c) missbräuchliche Verfügungen über sein Konto vorgenommen wurden, hat der KUNDE unverzüglich von der DFG die Sperrung des Kontos zu verlangen und im Fall von (a) auf der WEBSEITE ein neues Passwort zu vergeben.</h5>
      <h5>4.5.3	Wurde das Passwort zurückgesetzt, setzt die weitere Nutzung des BENUTZERKONTOS zur Sicherheit des KUNDEN voraus, dass der KUNDE die notwendigen Angaben neu eingibt.</h5>
      <h5>4.6	Sperrung des BENUTZERKONTOS</h5>
      <h5>4.6.1	Die DFG darf die Nutzung des BENUTZERKONTOS für den KUNDEN sperren,</h5>
      <p>-	sofern dies zum Schutz berechtigter Interessen der DFG oder der KOOPERATIONSBANK erforderlich ist,</p>
      <p>-	im Falle einer missbräuchlichen oder betrügerischen Verwendung des BENUTZERKONTOS oder wenn der Verdacht einer missbräuchlichen oder betrügerischen Verwendung des BENUTZERKONTOS besteht,</p>
      <p>-	sofern die Teilnahmevoraussetzungen nach diesem VERTRAG in seiner Person nicht (mehr) vorliegen,</p>
      <p>-	sofern das Passwort dreimal hintereinander falsch eingegeben wurde,</p>
      <p>-	sofern trotz entsprechender Anforderung von der DFG Auskünfte nicht unverzüglich erteilt und/oder Unterlagen nicht unverzüglich zur Verfügung gestellt wurden,</p>
      <p>-	bei Verstoß gegen das Abtretungs- und Übertragungsverbot gemäß Ziffer 3.4,</p>
      <p>-	wenn dieser VERTRAG beendet wird oder</p>
      <p>-	auf Verlangen des KUNDEN.</p>
      <h5>4.6.2	Eine Sperrung des BENUTZERKONTOS hat für den KUNDEN zur Folge, dass der KUNDE keinen Depotzugang bei der KOOPERATIONSBANK mehr über die WEBSEITE erhält. Über eine durch die DFG vorgenommene Sperrung wird die DFG den KUNDEN möglichst vor, spätestens aber unverzüglich nach der Sperrung unter Angabe des Grundes informieren.</h5>
      <h5>4.6.3	Über eine Aufhebung der Sperrung entscheidet die DFG; auch über eine Aufhebung der Sperrung wird die DFG den KUNDEN informieren.</h5>
      <h5>5.	Erteilung einer Empfangsvollmacht</h5>
      <h5>5.1	Der KUNDE erteilt der DFG hiermit eine Empfangsvollmacht zum Empfang von Willenserklärungen und Mitteilungen der KOOPERATIONSBANK, welche die KOOPERATIONSBANK über das BENUTZERKONTO auf der WEBSEITE an den KUNDEN richtet.</h5>
      <h5>5.2	Dem Kunden ist bekannt, dass Willenserklärungen, welche die KOOPERATIONSBANK an das durch die DFG zur Verfügung gestellte BENUTZERKONTO abgibt, im Rechtsverkehr als dem KUNDEN zugegangen gelten.</h5>
      <h5>5.3	Diese Empfangsvollmacht gilt entsprechend für den Empfang von Willenserklärungen, die durch eine neue KOOPERATIONSBANK an den KUNDEN gerichtet werden, wenn die KOOPERATIONSBANK gemäß Ziffer 2.2 ersetzt wurde.</h5>
      <h5>5.4	Die Vollmacht erlischt, wenn sie durch den KUNDEN widerrufen wird oder dieser VERTRAG endet.</h5>
      <h5>5.5	Dem KUNDEN ist bekannt, dass er Mitteilungen, die über sein BENUTZERKONTO an ihn gerichtet werden, selbst über die WEBSEITE zur Kenntnis nehmen muss. Die DFG wird den KUNDEN per E-Mail über den Eingang neuer Mitteilungen in seinem BENUTZERKONTO informieren. Es obliegt dem KUNDEN, seine empfangenen E-Mails unter der angegebenen E-Mail-Adresse regelmäßig abzurufen. .</h5>
      <h5>6.	Haftung</h5>
      <h5>6.1	Bei Vorsatz und grober Fahrlässigkeit, auch seiner gesetzlichen Vertreter und Erfüllungsgehilfen, haftet die DFG unbeschränkt.</h5>
      <h5>6.2	Die DFG haftet bei durch die DFG, deren gesetzlichen Vertreter oder Erfüllungsgehilfen schuldhaft verursachten Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, bei Schäden, die durch das Fehlen einer garantierten Beschaffenheit verursacht werden, sowie im Falle arglistig verschwiegener Mängel unbeschränkt.</h5>
      <h5>6.3	Bei durch die DFG, deren gesetzlichen Vertreter oder Erfüllungsgehilfen durch einfache Fahrlässigkeit verursachten Sach- und Vermögensschäden ist die Haftung beschränkt auf Fälle der Verletzung einer wesentlichen Vertragspflicht (sog. Kardinalpflicht), jedoch der Höhe nach begrenzt auf den bei Vertragsschluss vorhersehbaren und vertragstypischen Schaden. Wesentliche Vertragspflichten sind solche, deren Erfüllung die ordnungsgemäße Durchführung dieses VERTRAGS überhaupt erst ermöglichen und auf deren Einhaltung der KUNDE regelmäßig vertrauen darf.</h5>
      <h5>6.4	Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</h5>
      <h5>6.5	Im Übrigen ist die Haftung der DFG ausgeschlossen.</h5>
      <h5>7.	Vertragsdauer und Vertragsbedingungen</h5>
      <h5>7.1	Der VERTRAG wird auf unbestimmte Zeit geschlossen.</h5>
      <h5>7.2	Der KUNDE ist berechtigt, den VERTRAG jederzeit unter Einhaltung einer Kündigungsfrist von einem Monat zum Ende des Kalendermonats sowie ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung gegenüber der DFG zu kündigen.</h5>
      <h5>7.3	Die DFG kann den VERTRAG innerhalb der ersten drei Jahre der Vertragslaufzeit nicht ordentlich kündigen. Nach Ablauf der drei Jahre kann die DFG den VERTRAG jederzeit unter Einhaltung einer Kündigungsfrist von zwölf (12) Wochen zum Ende des Kalendermonats ohne Vorliegen und Angabe eines Grundes durch eine Kündigungserklärung dem KUNDEN gegenüber kündigen.</h5>
      <h5>7.4	Das Recht beider Parteien zur außerordentlichen fristlosen Kündigung aus wichtigem Grund bleibt unberührt. Die DFG ist zur außerordentlichen fristlosen Kündigung aus wichtigem Grund insbesondere berechtigt, wenn</h5>
      <p>a)	der Verdacht einer missbräuchlichen oder betrügerischen Verwendung des BENUTZERKONTOS besteht,</p>
      <p>b)	die Teilnahmevoraussetzungen nach diesem VERTRAG in der Person des KUNDEN nicht (mehr) vorliegen,</p>
      <p>c)	trotz entsprechender Anforderung durch die DFG erforderliche Auskünfte nicht unverzüglich erteilt und/oder Unterlagen nicht unverzüglich zur Verfügung gestellt wurden,</p>
      <p>d)	der KUNDE gegen das Abtretungs- oder Übertragungsverbot gemäß Ziffer 4.3.5 verstößt,</p>
      <p>e)	regulatorische Anforderungen oder technische oder wirtschaftliche Schwierigkeiten den Weiterbetrieb der WEBSEITE unmöglich oder nur mit für die DFG unzumutbarem Aufwand möglich machen,</p>
      <p>f)	der KUNDE für einen Zeitraum von mehr als vier (4) Wochen a) keine Anteile mehr am Produkt DER ZUKUNFTSFONDS in seinem Depot bei der KOOPERATIONSBANK hält und b) keinen aktiven Sparplan bezüglich DER ZUKUNFTSFONDS hinterlegt hat,</p>
      <p>g)	die DFG ihre Dienstleistung über die WEBSEITE dauerhaft einstellt,</p>
      <p>h)	die Empfangsvollmacht der DFG bezüglich der Erklärungen der KOOPERATIONSBANK nach Ziffer 5 durch den KUNDEN widerrufen wird.</p>
      <h5>7.5	Für die Form von Kündigungserklärungen gilt Ziffer 4.4.2.</h5>
      <h5>7.6	Für den Fall einer Beendigung des VERTRAGS zwischen der DFG und dem KUNDEN wird der Depotzugang ausschließlich im Verhältnis der KOOPERATIONSBANK zum KUNDEN gewährleistet. In diesem Fall entfällt die Übernahme der Depotführungskosten durch die DFG, sodass der KUNDE die sich nach dem Vertragsverhältnis zwischen der KOOPERATIONSBANK und dem KUNDEN richtenden Depotführungsgebühren selber zu tragen hat.</h5>
      <h5>8.	Änderungen des Vertrags</h5>
      <h5>8.1	Änderungen dieses VERTRAGS werden dem KUNDEN spätestens zwei (2) Monate vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens durch die DFG angeboten.</h5>
      <h5>8.2	Die Zustimmung zu durch die DFG vorgeschlagenen Änderungen dieses VERTRAGS gilt als vom KUNDEN erteilt, wenn der KUNDE die Ablehnung nicht vor dem vorgeschlagenen Zeitpunkt des Wirksamwerdens der Änderungen angezeigt hat. Auf diese Genehmigungswirkung wird die DFG den KUNDEN in dem jeweiligen Angebot für Änderungen dieses VERTRAGS besonders hinweisen. Die rechtzeitige Absendung der Ablehnung genügt.</h5>
      <h5>8.3	Wenn der KUNDE die Änderungen ablehnt, kann die DFG und kann der KUNDE den VERTRAG ohne Einhaltung einer Kündigungsfrist kündigen. Die DFG wird den KUNDEN in dem jeweiligen Angebot für Änderungen dieses VERTRAGS auf dieses Kündigungsrecht besonders hinweisen.</h5>
      <h5>8.4	Die DFG wird die relevanten Änderungen des VERTRAGS jeweils gesondert hervorheben und dem KUNDEN den Grund für die Änderung mitteilen. Änderungen erfolgen ausschließlich aus dem mitgeteilten Grund.</h5>
      <h5>8.5	Eine Änderung des VERTRAGS ist nur aus triftigen Gründen möglich. Solche Gründe sind insbesondere eine Änderung der Gesetzeslage oder der höchstrichterlichen Rechtsprechung, eine Änderung der Marktgegebenheiten, die Beseitigung von Auslegungszweifeln oder die Erfüllung zwingend gesetzlicher oder behördlicher Anforderungen.</h5>
      <h5>9.	Erhebung, Verarbeitung und Nutzungen personenbezogener Daten des KUNDEN</h5>
      <p>Die DFG kann dem KUNDEN nur dann den erfolgreichen Abschluss des Registrierungsprozesses bestätigen und den KUNDEN für das BENUTZERKONTO freischalten, wenn im Rahmen des Registrierungsprozesses bestimmte seiner personenbezogenen Daten von der DFG in bestimmter Art und Weise erhoben, verarbeitet, übermittelt und/oder genutzt werden. Details zu den insoweit betroffenen personenbezogenen Daten und den erforderlichen Erhebungs-, Verarbeitungs-, Übermittlungs- und Nutzungsvorgängen kann der KUNDE den unter http://www.der-zukunftsfonds.de/datenschutz abrufbaren Datenschutzhinweisen entnehmen.</p>
      <h5>10.	Anwendbares Recht</h5>
      <p>Auf diesen VERTRAG findet deutsches Recht unter Ausschluss des UN-Kaufrechts Anwendung.</p>
      <h5>11.	Unwirksamkeit</h5>
      <p>Sollte eine Bestimmung dieses VERTRAGS nichtig, unwirksam oder nicht durchsetzbar sein oder werden, so wird die Gültigkeit dieses VERTRAGS im Übrigen nicht berührt.</p>
      <p>Stand: 17. Mai 2018</p>
      <p>
        DFG Deutsche Fondsgesellschaft SE Invest<br/>
        Schlüterstraße 40<br/>
        10707 Berlin
      </p>
      <p>Amtsgericht Charlottenburg, Berlin, HRB 192688</p>
    </li>
  </ul>
);

export default DfgAgreements;
