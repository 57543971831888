import ajax from '@/adapters/ajax';
import * as actions from '@/actions/creators';
import getEndpoint from '@/constants/endpoints';
import { serverError } from '@/constants/errors';
import { isServerError } from '@/helpers/errors';
import * as selectors from '@/reducers/selectors';
import { amendValues } from '@/helpers/forms';

const submissionRunning = {};

function queue(dispatch, actions) {
  return actions.reduce((prev, action) => prev.then(() => dispatch(action)), Promise.resolve());
}

export function sendRequest(options) {
  const {
    useToken,
    endpointName,
    endpointEntity,
    endpointAddition,
    method,
    requestData,
    responseType,
    showSuccessMessage,
    errorMessageKey,
    submissionKey,
    successDispatch = [],
    errorDispatch = [],
    finallyDispatch = [],
  } = options;

  return async function (dispatch, getState) {
    if (!endpointName) {
      console.error('No endpoint specified!'); // missing params in configuration
      return;
    }

    // TODO: Instead, simply check & update state to prevent "double submission"
    if (submissionKey) {
      if (submissionRunning[submissionKey]) return;
      submissionRunning[submissionKey] = true;
    }

    const state = getState();

    const credentials = useToken ? selectors.getAuthCredentials(state) : null;

    if (useToken && !credentials) return;

    const endpoint = getEndpoint(endpointName, endpointEntity, endpointAddition);
    const data = amendValues(endpointName, requestData);

    await dispatch(actions.activateLoading());

    try {
      const response = await ajax(endpoint, method, credentials, data, responseType);
      const result = response[0];

      if (showSuccessMessage) await dispatch(actions.addAlert(result.message));

      await queue(dispatch, successDispatch.map(creator => creator(result)));
    } catch(error) {
      if (isServerError(error)) {
        await dispatch(actions.addError(serverError));
      } else {
        const errorBody = error[0] || {};
        const errors = errorBody.errors || {};

        if (errorMessageKey && errors[errorMessageKey]) {
          const errorMessage = Array.isArray(errors[errorMessageKey])
            ? errors[errorMessageKey][0]
            : errors[errorMessageKey];

          await dispatch(actions.addError(errorMessage));
        }

        await queue(dispatch, errorDispatch.map(creator => creator(errorBody)));
      }
    } finally {
      await queue(dispatch, finallyDispatch.map(creator => creator()));

      await dispatch(actions.deactivateLoading());

      if (submissionKey) {
        submissionRunning[submissionKey] = false;
      }
    }
  };
}
