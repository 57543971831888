import React from 'react';
import { otherTexts as labels } from '@/constants/texts';

const FfbDisclaimer = () => (
  <div className='ffb-disclaimer'>
    <img className='ffb-disclaimer__image' src='/static/images/ffb.svg' alt='FIL Fondsbank GmbH' />
    <p className='ffb-disclaimer__text'>{labels.disclaimerText}</p>
  </div>
);

export default FfbDisclaimer;
