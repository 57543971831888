import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';

import NewPasswordForm from '@/components/forms/NewPasswordForm';

const NewPassword = () => (
  <OnboardingLayout>
    <HelpArea />
    <NewPasswordForm />
  </OnboardingLayout>
);

export { NewPassword };
export default NewPassword;
