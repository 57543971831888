import React from 'react';
import { welcome as texts } from '@/constants/texts';

export default function WelcomeText() {
  return (
    <div className='welcome-text'>
      <div className='welcome-text__wrapper'>
        <h1 className='welcome-text__headline'>{texts.shapeFinancialFuture}</h1>
        <p className='welcome-text__text'>{texts.registerWithin3Steps}</p>
        <p className='welcome-text__text'>{texts.letsStart}</p>
        <div className='welcome-text__image-list'>
          <img src='/static/images/door.png' alt='door' className='welcome-text__image' />
          <ol className='welcome-text__list'>
            <li className='welcome-text__list-item welcome-text__list-item--active'>{texts.account}</li>
            <li className='welcome-text__list-item'>{texts.savingsRate}</li>
            <li className='welcome-text__list-item'>{texts.identity}</li>
          </ol>
        </div>
      </div>
    </div>
  );
}
