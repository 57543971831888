import React from 'react';
import { getFormValues } from 'redux-form';

import * as t from '@/actions/types';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { orderConfirmation as orderLabels, otherTexts } from '@/constants/texts';
import { formatDisplayValue } from '@/helpers/currency';

import OrderConfirmation from '@/components/specific/orders/OrderConfirmation';
import OrderConfirmationText from "@/components/specific/orders/OrderConfirmationText";
import * as currency from '@/helpers/currency';

export function validateOrder(formName, ...validActions) {
  return actions.submit({
    useToken: true,
    formName,
    wrapperName: 'order',
    endpointName: 'orderValidation',
    showSuccessMessage: false,
    successDispatch: [...validActions],
    errorMessageKey: 'global',
  });
}

export function validateSavingsRate(...validActions) {
  return actions.submit({
    useToken: true,
    formName: 'savingsRate',
    wrapperName: 'savingsRate',
    endpointName: 'savingsRateValidation',
    showSuccessMessage: false,
    successDispatch: [...validActions],
    errorMessageKey: 'global',
  });
}

export function createOrder(formName, isTotalSell = false) {
  return (dispatch, getState, collectors = getFormValues) => {
    const { orderValue, orderType, password } = collectors(formName)(getState()) || {};

    const action = actions.sendRequest({
      method: 'post',
      useToken: true,
      endpointName: formName,
      requestData: {
        order: {
          orderValue: isTotalSell ? selectors.getAdjustedDepositsValue(getState()) : orderValue,
          orderType: isTotalSell ? 'totalSell' : orderType,
        },
        password,
      },
      showSuccessMessage: true,
      errorMessageKey: 'global',
      submissionKey: 'createOrder',
      successDispatch: [actions.redirectDashboard],
      errorDispatch: [actions.showFormValidationError.bind({}, formName)],
    });

    return dispatch(action);
  };
}

export function setSavingsRate(monthlyRate, dayOfMonth) {
  return (dispatch, getState, collectors = getFormValues) => {
    const password = collectors('savingsRate')(getState()).password || {};

    const action = actions.sendRequest({
      method: 'post',
      useToken: true,
      endpointName: 'savingsRate',
      requestData: {
        savingsRate: {
          monthlyRate,
          dayOfMonth,
        },
        password,
      },
      showSuccessMessage: true,
      errorMessageKey: 'global',
      submissionKey: 'setSavingsRate',
      successDispatch: [actions.redirectDashboard],
      errorDispatch: [actions.showFormValidationError.bind({}, 'savingsRate')],
    });

    return dispatch(action);
  };
}

export function setSavingsRateValidation(response) {
  return {
    type: t.SET_SAVINGS_RATE_VALIDATION,
    payload: {
      validation: {
        firstTransactionDate: response.firstTransactionDate,
        pendingTransactionDate: response.pendingTransactionDate,
        nextFfbBankingDate: response.nextFfbBankingDate,
        estimatedNextWithdrawal: response.estimatedNextWithdrawal,
      },
    },
  };
}

export function resetSavingsRateValidation() {
  return {
    type: t.RESET_SAVINGS_RATE_VALIDATION,
  };
}

export function setOrderValidation(response) {
  return {
    type: t.SET_ORDER_VALIDATION,
    payload: {
      orderType: response.orderType,
      message: response.message,
    },
  };
}

export function resetOrderValidation() {
  return {
    type: t.RESET_ORDER_VALIDATION,
  };
}

export function confirmTotalSell(orderType, orderValue) {
  return (dispatch) => {
    dispatch({
      type: t.CONFIRM_TOTAL_SELL,
    });
    dispatch(actions.showOrderConfirmationModal(orderType, orderValue));
  };
}

export function showConfirmTotalSellModal(orderValue, message) {
  const texts = orderLabels['confirmTotalSell'];
  return (dispatch, getState) => {
    const formattedDepositValue = formatDisplayValue(selectors.getAdjustedDepositsValue(getState()));
    dispatch({
      type: t.SHOW_MODAL,
      payload: {
        heading: `${orderValue} ${texts.heading}`,
        text: message,
        modalAction: actions.confirmTotalSell.bind({}, 'totalSell', formattedDepositValue),
        keepOnConfirm: true,
        showCloseButton: true,
        closeButtonLabel: texts.adjustAmount,
        ctaButtonLabel: texts.ctaButtonLabel,
        buttons: [{
          label: otherTexts.cancel,
          action: () => {
            dispatch(actions.resetOrderValidation());
            dispatch(actions.redirectDashboard());
          },
        }],
      },
    });
  };
}

export function showOrderConfirmationModal(orderType, orderValue, dayOfMonth) {
  const texts = orderLabels[orderType];
  const formattedOrderValue = currency.formatDisplayValue(orderValue);
  let modalAction;

  switch(orderType) {
    case 'savingsRate':
      modalAction = actions.setSavingsRate.bind({}, orderValue, dayOfMonth);
      break;
    case 'totalSell':
      modalAction = actions.createOrder.bind({}, 'payOut', true);
      break;
    default:
      modalAction = actions.createOrder.bind({}, orderType);
  }

  return (dispatch, getState) => {
    const orderValidation = selectors.getOrderValidation(getState());
    const isTotalSellConfirmed = selectors.isTotalSellConfirmed(getState());
    const portfolioNumber = selectors.getUserPortfolioNumber(getState());
    const savingsRateValidation = selectors.getSavingsRateValidation(getState());

    if (orderValidation.orderType === 'totalSell' && !isTotalSellConfirmed) {
      dispatch(actions.showConfirmTotalSellModal(formattedOrderValue, orderValidation.message));
    } else {
      dispatch({
        type: t.SHOW_MODAL,
        payload: {
          heading: texts.heading,
          modalAction,
          keepOnConfirm: true,
          showCloseButton: true,
          closeButtonLabel: otherTexts.back,
          ctaButtonLabel: texts.ctaButtonLabel,
          buttons: [{
            label: otherTexts.cancel,
            action: () => {
              dispatch(actions.resetOrderValidation());
              dispatch(actions.redirectDashboard());
            },
          }],
          children: [
            <OrderConfirmationText
              key='text'
              orderType={orderType}
              orderValue={formattedOrderValue}
              portfolioNumber={portfolioNumber}
              savingsRateValidation={savingsRateValidation}
            />,
            <OrderConfirmation key='order' formName={orderType === 'totalSell' ? 'payOut' : orderType} />,
          ],
        },
      });
    }
  };
}
