import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import { orderRestrictions as errorTexts } from '@/constants/errors';
import { payInPayOut as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import * as currency from '@/helpers/currency';

import LabeledInput from '@/components/generic/LabeledInput';
import Form from '@/components/generic/Form';
import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const orderValue = getFormValues('payOut')(state).orderValue;
  const depositValue = selectors.getAdjustedDepositsValue(state);
  return {
    formattedOrderValue: currency.formatDisplayValue(orderValue),
    formattedDepositValue: currency.formatDisplayValue(depositValue),
    errors: selectors.getFormErrors(state, 'payOut'),
    canCreateOrders: selectors.getUserCanCreateOrders(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch((dispatch, getState) => {
      const orderValue = getFormValues('payOut')(getState()).orderValue;

      dispatch(actions.resetOrderValidation());
      dispatch(actions.validateOrder('payOut',
        actions.setOrderValidation,
        actions.showOrderConfirmationModal.bind({}, 'payOut', orderValue),
      ));
    });
  },
  onCancel: () => dispatch(actions.redirectDashboard()),
});
const PayOutForm = ({
  canCreateOrders = true,
  formattedOrderValue = 0,
  formattedDepositValue = 0,
  errors = {},
  onSubmit,
  onCancel,
}) => {
  const formInfo = texts.hints.payOut.replace("%(interpolation)", formattedDepositValue);

  return (
    <Form onSubmit={onSubmit} legend={texts.headings.others.payOut} info={formInfo}>
      {!canCreateOrders && <p>{errorTexts.sell}</p>}
      <Field
        name='orderValue'
        type='text'
        normalize={currency.normalizeInput}
        prefix='€'
        label={labels.payOutAmount}
        required={true}
        component={LabeledInput}
        errors={errors.orderValue}
        disabled={!canCreateOrders}
      />
      <RequiredHint />
      <Button type='submit' disabled={!canCreateOrders || !formattedOrderValue}>
        {formattedOrderValue + ' '}
        {labels.submitPayOut}
      </Button>
      <BackButton type='button' onClick={onCancel}>
        {texts.otherTexts.cancel}
      </BackButton>
    </Form>
  );
};

PayOutForm.propTypes = {
  canCreateOrders: PropTypes.bool,
  formattedOrderValue: PropTypes.string,
  formattedDepositValue: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export { PayOutForm };
export default compose(
  reduxForm({
    form: 'payOut',
    initialValues: {
      orderValue: null,
      orderType: 'sell', // no Field needed for orderType - only for API
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(PayOutForm);
