import * as t from '@/actions/types';

const initialState = {
  isFetchingData: 0,
  isSubmittingForm: 0,
  isOther: 0,
  isOnboarding: 0,
  isRequestingDepot: 0,
  isCheckingDepotRequestStatus: 0,
};

export default (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  switch(type) {
    case t.SET_LOADING:
      const loadingType = Object.keys(payload)[0];
      const isLoading = payload[loadingType];
      let counter = state[loadingType];
      counter = isLoading ? counter + 1 : counter -1;
      if (counter < 0) counter = 0;

      return Object.assign({}, state, { [loadingType]: counter });
    default:
      return state;
  }
};

export function getLoadingState(state, loadingState) {
  if (!loadingState) {
    return Object.values(state.ui.loading).findIndex(val => val > 0) !== -1;
  }
  return state.ui.loading[loadingState] > 0;
}
