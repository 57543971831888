import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'redux-first-router-link';
import { connect } from 'react-redux';

import { INBOX_DETAIL } from '@/router';
import SessionTimer from "@/components/generic/SessionTimer";

import classnames from 'classnames';
import { formatDateString } from '@/helpers/dates';
import * as texts from '@/constants/texts';

const mapStateToProps = (state) => ({
  isMessageOpen: state.location.type === INBOX_DETAIL,
  timer: state.login.timer,
});

const InboxList = ({
  messages = [],
  isMessageOpen = '',
  timer,
}) => {
  const inboxListClasses = classnames('inbox-list', {
    'inbox-list--hidden-mobile': isMessageOpen,
  });

  return (
    <aside className={inboxListClasses}>
      <div className='inbox-list__session'>
        <SessionTimer time={timer} />
      </div>
      <div className='inbox-list__content'>
        <h1 className='inbox-list__title'>{texts.otherTexts.inbox}</h1>
        {(messages.length > 0)
          ? (
            <ul
              className='inbox-list__messages'
              aria-controls='message'
            >
              {messages.map(message => {
                const asideMessageClasses = classnames('inbox-list__message', {
                  'inbox-list__message--unread': !message.readAt,
                });

                return (
                  <li key={message.id}>
                    <NavLink
                      activeClassName='inbox-list__message--active'
                      className={asideMessageClasses}
                      to={{ type: INBOX_DETAIL, payload: { messageId: message.id }}}
                      isActive={(match, location) =>
                        location.payload.messageId && location.payload.messageId === message.id
                      }
                    >
                      {message.subject}
                      <time
                        dateTime={message.date}
                        className='inbox-list__date'
                      >
                        {formatDateString(message.date)}
                      </time>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            )
          : (
            <p className='inbox-list__no-messages-text'>
              {texts.inboxOverview.noMessagesYet}
            </p>
          )
        }
      </div>
    </aside>
  );
};

InboxList.propTypes = {
  isMessageOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  messages: PropTypes.array,
  timer: PropTypes.number,
};

export default connect(mapStateToProps)(InboxList);
