import ajax from '@/adapters/ajax';
import * as actions from '@/actions/creators';
import * as t from '@/actions/types';
import getEndpoint from '@/constants/endpoints';
import { serverError } from '@/constants/errors';
import * as selectors from '@/reducers/selectors';

const SUBMISSION_RUNNING = 'isRequestingDepot';

export default
(store, client = ajax, getters = selectors) => next => async action => {

  const { type } = action;

  const state = store.getState();
  const credentials = getters.getAuthCredentials(state);

  if (type === t.REQUEST_DEPOT) {
    if(getters.getLoadingState(state, SUBMISSION_RUNNING)) { return; }

    const endpoint = getEndpoint('userPortfolio');

    if (!credentials) { return; }

    store.dispatch(actions.activateLoading(SUBMISSION_RUNNING));

    try {
      await client(endpoint, 'post', credentials);
      store.dispatch(actions.redirectConfirmIdentity());
      store.dispatch(actions.deactivateLoading(SUBMISSION_RUNNING));
      store.dispatch(actions.track('orderCompleted')());
    } catch(error) {
      store.dispatch(actions.addError(serverError));
    }
  } else {
    next(action);
  }
};
