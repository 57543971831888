import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '@/actions/creators';

import * as texts from '@/constants/texts';
import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';
import HelpText from '@/components/generic/HelpText';
import Button from '@/components/generic/Button';
import UserData from '@/components/specific/UserData';

const mapDispatchToProps = (dispatch) => ({
  onSubmit: () => dispatch(actions.requestDepot()),
});

const Summary = ({ onSubmit }) => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={1} />
      <HelpText arrowAlignment='top'>{texts.onboardingHelpTexts.summary}</HelpText>
    </HelpArea>
    <div className='summary'>
      <h1 className='summary__headline'>{texts.headings.onboarding.summary}</h1>
      <p className='summary__text'>{texts.headings.onboarding.summarySubHeading}</p>
      <UserData />
      <Button type='button' onClick={onSubmit}>
        {texts.otherTexts.requestDepot}
      </Button>
    </div>
  </OnboardingLayout>
);

Summary.propTypes = {
  onSubmit: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Summary);
