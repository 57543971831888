import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

import LabeledCheckbox from '@/components/generic/LabeledCheckbox';
import * as uniqueId from '@/helpers/uniqueId';

class AgreementsToggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggled: this.props.fieldProps.isToggled ? true : false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  // TODO: Update to maintain compatibility with future versions of React.
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillmount
  UNSAFE_componentWillMount() {
    const id = `a11y-legal-${uniqueId.getNext()}`;
    this.setState({ id: id });
  }

  onToggle() {
    const isCurrentlyToggled = this.state.isToggled;
    this.setState({ isToggled: !isCurrentlyToggled });
  }

  render() {
    const {
      DetailComponent,
      fieldProps,
      infoText = '',
    } = this.props;

    const id = this.state.id;

    const agreementsClasses = classnames('agreements-toggle', {
      'agreements-toggle--active': this.state.isToggled,
    });

    return [
      <Field key='field'
        {...fieldProps}
        component={LabeledCheckbox}
        required={true}
      />
      ,
      <div className={agreementsClasses} key='legal-text'>
        <button
          type='button'
          className='agreements-toggle__button'
          onClick={this.onToggle}
          aria-controls={id}
          aria-expanded={this.state.isToggled}
        >
          <svg className='agreements-toggle__button-image' width='14' height='9' xmlns='http://www.w3.org/2000/svg'>
            <path className='agreements-toggle__button-image-path' stroke='#DE1F37'
              strokeWidth='2' d='M1 1l5.87 6L13 1' fill='none' fillRule='evenodd'
            />
          </svg>
          {infoText}
        </button>
        <div
          id={id}
          className='agreements-toggle__content'
          aria-hidden={!this.state.isToggled}
        >
          <DetailComponent />
        </div>
      </div>
      ,
    ];
  }
};

AgreementsToggle.propTypes = {
  DetailComponent: PropTypes.func,
  fieldProps: PropTypes.object,
  infoText: PropTypes.string,
};

export default AgreementsToggle;
