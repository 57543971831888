import React from 'react';
import PropTypes from 'prop-types';

import * as texts from '@/constants/texts';

import Logo from '@/components/generic/Logo';

const FrontFormLayout = ({ children }) => (
  <div className='front-form-layout'>
    <div className='front-form-layout__top'>
      <Logo />
    </div>

    <div className='front-form-layout__content'>
      {children}
    </div>

    <footer className='front-form-layout__footer'>
      <a
        className='front-form-layout__footer-link'
        href='https://www.der-zukunftsfonds.de/impressum'
      >
        {texts.otherTexts.imprint}
      </a>
    </footer>
  </div>
);

FrontFormLayout.propTypes = {
  children: PropTypes.node,
};

export default FrontFormLayout;
