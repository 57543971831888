import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import ProgressSteps from '@/components/generic/ProgressSteps';
import HelpText from '@/components/generic/HelpText';
import LegalAgreementsForm from '@/components/forms/LegalAgreementsForm';

const LegalAgreements = () => (
  <OnboardingLayout>
    <HelpArea>
      <ProgressSteps currentStep={1} />
      <HelpText arrowAlignment='top'>Bitte lesen und bestätigen Sie unsere Geschäftsbedingungen.</HelpText>
    </HelpArea>
    <LegalAgreementsForm />
  </OnboardingLayout>
);

export default LegalAgreements;
