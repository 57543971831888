import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import * as routes from '@/router';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import * as texts from '@/constants/texts';

import BackButton from '@/components/generic/BackButton';
import AccountLayout from '@/components/specific/AccountLayout';
import UserAccount from '@/components/specific/UserAccount';
import UserData from '@/components/specific/UserData';

const mapStateToProps = (state) => ({
  isDeleted: selectors.getIsUserDeleted(state),
});

const mapDispatchToProps = (dispatch) => ({
  onPreviousStep: () => {
    dispatch(actions.redirectDashboard());
  },
});

const UserInfo = ({ isDeleted = false, onPreviousStep }) => (
  <AccountLayout>
    <UserAccount title={texts.headings.others.userInfo}>
      {!isDeleted &&
        <Link className='user-info__link' to={{ type: routes.CHANGE_EMAIL }}>{texts.userInfo.changeEmail}</Link>
      }
      {!isDeleted &&
        <Link className='user-info__link' to={{ type: routes.CHANGE_PASSWORD }}>{texts.userInfo.changePassword}</Link>
      }
      <UserData />
      {!isDeleted &&
        <Link className='user-info__link' to={{type: routes.DELETE_ACCOUNT}}>{texts.letters.deleteAccount}</Link>
      }
      <BackButton onClick={() => onPreviousStep()}>
        {texts.backLinks.backToDashboard}
      </BackButton>
    </UserAccount>
  </AccountLayout>
);

UserInfo.propTypes = {
  onPreviousStep: PropTypes.func,
  isDeleted: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
