import { combineReducers } from 'redux';

import info from './info';
import modal from './modal';

export default combineReducers({
  info,
  modal,
});

export * from './info';
export * from './modal';
