import { redirect } from 'redux-first-router';

import * as routes from '@/router';

export function redirectDashboard() {
  return redirect({ type: routes.DASHBOARD });
}

export function redirectStart() {
  return redirect({ type: routes.START });
}

export function redirectOnboardingStatus() {
  return redirect({ type: routes.ONBOARDING_STATUS });
}

export function redirectUserInfo() {
  return redirect({ type: routes.USER_INFO });
}

export function redirectInboxOverview() {
  return redirect({ type: routes.INBOX_OVERVIEW });
}

export function redirectConfirmIdentity() {
  return redirect({ type: routes.CONFIRM_IDENTITY });
}
