import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import * as selectors from '@/reducers/selectors';

import * as texts from '@/constants/texts';
import { orderConfirmation as labels } from "@/constants/forms";

import LabeledInput from '@/components/generic/LabeledInput';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state, ownProps) => ({
  errors: selectors.getFormErrors(state, ownProps.formName),
  form: ownProps.formName,
});

const OrderConfirmation = ({ errors }) => (
  <div>
    <Field
      name='password'
      type='password'
      label={labels.yourPass}
      hint={texts.hints.confirmOrder}
      component={LabeledInput}
      required={true}
      errors={errors.password}
    />
    <RequiredHint />
  </div>
);

OrderConfirmation.propTypes = {
  errors: PropTypes.object,
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    initialValues: {
      password: '',
    },
    destroyOnUnmount: false,
  }),
)(OrderConfirmation);
