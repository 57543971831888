import React from 'react';
import PropTypes from 'prop-types';

import InputContext from '@/components/generic/InputContext';
import * as uniqueId from '@/helpers/uniqueId';

const LargeNumberedInput = ({
  required = false,
  value = '',

  // for redux-form
  input = {},

  // for InputContext
  hint = '',
  errors = [],
}) => {

  const inputId = `input-${uniqueId.getNext()}`;

  return (
    <InputContext hint={hint} errors={errors}>
      <input
        {...input}
        value={input.value || value}
        id={inputId}
        className={`large-numbered-input__input ${errors.length > 0 && 'large-numbered-input__input--error'}`}
        placeholder='000000'
        type='number'
        min='5'
        max='5'
      />
    </InputContext>
  );

};

LargeNumberedInput.propTypes = {
  required: PropTypes.bool,
  hint: PropTypes.string,
  errors: PropTypes.array,
  input: PropTypes.object,
  value: PropTypes.string,
};

export default LargeNumberedInput;
