import React from 'react';
import PropTypes from 'prop-types';
import { orderConfirmation } from '@/constants/texts';
import SavingsRateText from '@/components/specific/orders/SavingsRateText';

const OrderConfirmationText = ({
  orderType,
  orderValue,
  portfolioNumber,
  savingsRateValidation,
}) => {
  const texts = orderConfirmation[orderType].text;
  const firstTransactionDate = savingsRateValidation.firstTransactionDate || '';
  let dayOfMonth = parseInt(firstTransactionDate.split('-')[2], 10).toString();
  dayOfMonth = dayOfMonth[0] === '0' ? dayOfMonth.slice(-1) : dayOfMonth;

  return (
    <div>
      <p><strong>{orderConfirmation.ffbInstruction}</strong></p>
      {orderType === 'savingsRate'
        ?
          <SavingsRateText
            orderValue={orderValue}
            savingsRateValidation={savingsRateValidation}
            dayOfMonth={dayOfMonth}
            portfolioNumber={portfolioNumber}
          />
        :
          <p>{texts[0]} <strong>{orderValue}</strong> {`${texts[1]} ${portfolioNumber} ${texts[2]}`}</p>
      }
      <p>{orderConfirmation.note}</p>
    </div>
  );
};

OrderConfirmationText.propTypes = {
  orderType: PropTypes.string,
  orderValue: PropTypes.string,
  portfolioNumber: PropTypes.string,
  savingsRateValidation: PropTypes.object,
};

export default OrderConfirmationText;
