import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';
import { otherTexts as labels } from '@/constants/texts';
import * as selectors from '@/reducers/selectors';

const mapStateToProps = (state, ownProps) => ({
  hasUserDataForm: !!ownProps.FormComponent,
  isFormActive: selectors.getIsUserDataFormActive(state, ownProps.formName),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleFormComponent: () => dispatch(actions.toggleUserDataForm(ownProps.formName)),
});

const UserDataOverview = ({
  isDeleted = false,
  hasUserDataForm = false,
  isFormActive = false,
  formName,
  userRole,
  title = '',
  data = [],
  FormComponent,
  toggleFormComponent,
}) => {

  return (
    <section className='user-data-overview'>
      <header className='user-data-overview__header'>
        <h2 className='user-data-overview__title'>{title}</h2>
        {hasUserDataForm && !isDeleted &&
          <button type='button' className='user-data-overview__link' onClick={toggleFormComponent}>
            {isFormActive
            ? (
              <span>
                {labels.cancel}
              </span>
              )
            : (
              <span>
                {labels.change}
              </span>
            )}
          </button>
        }
      </header>
      <div className='user-data-overview__content'>
        {isFormActive
          ? <FormComponent userRole={userRole} formName={formName} />
          : (
            <div className='user-data-overview__panel'>
              <ul className='user-data-overview__list'>
                {data
                  .filter(keyValue => keyValue.value !== undefined && keyValue.value !== null && keyValue.value !== '')
                  .map(data => (
                    <li className='user-data-overview__list-item' key={`label-${data.label}-${data.value}`}>
                      <dl className='user-data-overview__definition-list'>
                        <dt className='user-data-overview__definition-key'>{data.label}</dt>
                        <dd className='user-data-overview__definition-value'>{data.value}</dd>
                      </dl>
                    </li>
                ))}
              </ul>
            </div>
          )
        }
      </div>
    </section>
  );
};

UserDataOverview.propTypes = {
  isDeleted: PropTypes.bool,
  hasUserDataForm: PropTypes.bool,
  isFormActive: PropTypes.bool,
  userRole: PropTypes.string,
  title: PropTypes.string,
  formName: PropTypes.string,
  data: PropTypes.array,
  FormComponent: PropTypes.func,
  toggleFormComponent: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataOverview);
