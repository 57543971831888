import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload = {} } = action;

  switch(type) {
    case t.VALIDATION_ERROR:
      const newError = {};
      newError[payload.formName] = payload.error;
      return Object.assign({}, state, newError);

    case t.RESET_ERRORS:
      if (payload.formName) {
        const resetError = {};
        resetError[payload.formName] = null;
        return Object.assign({}, state, resetError);
      }
      return initialState;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getFormErrors(state, formName) {
  return state.ui.errors.validation[formName] || {};
}
