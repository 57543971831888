import * as t from '@/actions/types';

const initialState = [];

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.ADD_ALERT:
      return [...state, payload.alert];

    case t.REMOVE_ALERT:
      const stateClone = [...state];
      stateClone[payload.id] = undefined;
      return stateClone;

    default:
      return state;
  }
};

export function getInfoAlerts(state) {
  return state.ui.alerts.info;
}
