import * as t from '@/actions/types';
import * as formSelectors from '@/reducers/forms';
import { formatDateString } from '@/helpers/dates';

const protectedInitialValues = [
  'gender',
  'countryOfBirthCode',
  'nationalityCountryCode',
  'taxCountryCode',
];

const initialState = {
  gender: 'male',
  middleNames: null,
  noMiddleNames: false,
  countryOfBirthCode: null,
  nationalityCountryCode: null,
  taxCountryCode: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_USER_INFO:
      // Ignore empty personalInformation for select fields
      const newState = { ...payload.userInfo.personalInformation };

      protectedInitialValues.forEach(value => {
        newState[value] = newState[value] || initialState[value];
      });

      return newState;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getUserPersonalInfo(state) {
  return state.user.personalInfo;
}

export function getFormattedPersonalInfo(state) {
  const personalInfo = getUserPersonalInfo(state);
  const nationalities = formSelectors.getFormOptions(state, 'nationalityCountryCode') || [];
  const countriesOfBirth = formSelectors.getFormOptions(state, 'countryOfBirthCode') || [];
  const taxCountries = formSelectors.getFormOptions(state, 'taxCountryCode') || [];

  const nationalityObject =
    nationalities.find(nat => nat.value === personalInfo.nationalityCountryCode) || {};

  const countryOfBirthObject =
    countriesOfBirth.find(ctr => ctr.value === personalInfo.countryOfBirthCode) || {};

  const taxCountryObject =
    taxCountries.find(ctr => ctr.value === personalInfo.taxCountryCode) || {};

  const additionalTaxCountryObject =
    taxCountries.find(ctr => ctr.value === personalInfo.additionalTaxCountryCode) || {};

  return {
    ...personalInfo,
    dateOfBirth: formatDateString(personalInfo.dateOfBirth),
    nationality: nationalityObject.label,
    countryOfBirth: countryOfBirthObject.label,
    taxCountry: taxCountryObject.label,
    additionalTaxCountry: additionalTaxCountryObject.label,
  };
}
