import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = ({ type = 'button', onClick = () => {}, children, buttonStyle = '', ...props }) => {
  const classes = classnames('button', {
    'button--outlined': buttonStyle === 'outlined',
    'button--plain': buttonStyle === 'plain',
  });

  return (
    <button
      {...props}
      type={type}
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  buttonStyle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
  onClick: PropTypes.func,
};

export default Button;
