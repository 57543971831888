import React from 'react';

import AccountLayout from '@/components/specific/AccountLayout';
import DashboardFormLayout from '@/components/specific/dashboard/DashboardFormLayout';
import ChangePasswordForm from '@/components/forms/ChangePasswordForm';

const ChangePassword = () => (
  <AccountLayout>
    <DashboardFormLayout>
      <ChangePasswordForm />
    </DashboardFormLayout>
  </AccountLayout>
);

export default ChangePassword;
