import ajax from '@/adapters/ajax';
import * as actions from '@/actions/creators';
import * as t from '@/actions/types';
import getEndpoint from '@/constants/endpoints';
import * as selectors from '@/reducers/selectors';

const LOADING_FLAG_NAME = 'isCheckingDepotRequestStatus';

const CHECK_DEPOT_REQUEST_STATUS_TIMEOUT = 3000;

const activateLoading = store => store.dispatch(actions.activateLoading(LOADING_FLAG_NAME));
const deactivateLoading = store => store.dispatch(actions.deactivateLoading(LOADING_FLAG_NAME));

export default (store, client = ajax, getters = selectors, browser = window) => next => async action => {

  const { type, payload } = action;

  const state = store.getState();
  const credentials = getters.getAuthCredentials(state);

  if (type === t.CHECK_DEPOT_REQUEST_STATUS) {
    const endpoint = getEndpoint('onboardingStatus');
    const { amountRequests } = payload;

    if(!getters.getLoadingState(state, LOADING_FLAG_NAME)) {
      activateLoading(store);
    }

    try {
      const [response] = await client(endpoint, 'get', credentials);

      const onboardingStatus = response.onboardingStatus;
      if (onboardingStatus.identificationUrl) {
        deactivateLoading(store);
        store.dispatch(actions.fetchedOnboardingStatus(response));
      } else {
        if (amountRequests > 0) {
          browser.setTimeout(() => {
            store.dispatch(actions.checkDepotRequestStatus(amountRequests - 1));
          }, CHECK_DEPOT_REQUEST_STATUS_TIMEOUT);
        } else {
          deactivateLoading(store);
        }
      }
    } catch(error) {
      deactivateLoading(store);
      store.dispatch(actions.redirectOnboardingStatus());
    }
  } else {
    next(action);
  }
};
