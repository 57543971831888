import { combineReducers } from 'redux';

import showAdditionalTaxCountry from './showAdditionalTaxCountry';
import summary from './summary';

export default combineReducers({
  showAdditionalTaxCountry,
  summary,
});

export * from './showAdditionalTaxCountry';
export * from './summary';
