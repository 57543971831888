import * as selectors from '@/reducers/selectors';
import * as t from '@/actions/types';

export default (state = false, action) => {
  if (action.type === t.SHOW_ADDITIONAL_TAX_COUNTRY) {
    return true;
  }

  return state;
};

export function getShowAdditionalTaxCountry(state) {
  const personalInfo = selectors.getUserPersonalInfo(state);
  // if user redirects directly to form, ui state is gone. Only condition is the acutal values submitted
  const hasSubmittedAdditionalTaxCountry =
    !!personalInfo.additionalTaxCountry || !!personalInfo.additionalTaxIdentNumber;
  return state.ui.onboarding.showAdditionalTaxCountry || hasSubmittedAdditionalTaxCountry;
}
