import React from 'react';
import PropTypes from 'prop-types';

const InboxLayout = ({ children }) => (
  <div className='inbox-layout'>
    <div className='inbox-layout__overview'>
      {children}
    </div>
  </div>
);

InboxLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InboxLayout;
