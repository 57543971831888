import React from 'react';
import PropTypes from 'prop-types';

import { steps } from '@/constants/onboarding';

const ProgressStatus = ({ text }) => (
  <div className='progress-status'>
    <ol className='progress-status__list'>
      {steps.map((step, index) => (
        <li key={step} className='progress-status__list-item'>
          <span className='progress-status__label'>{step}</span>
        </li>
      ))}
    </ol>

    <span className='progress-status__title'>{text}</span>
  </div>
);

ProgressStatus.propTypes = {
  text: PropTypes.string,
};

export default ProgressStatus;
