import { getFormValues } from 'redux-form';

import ajax from '@/adapters/ajax';
import * as actions from '@/actions/creators';
import getEndpoint from '@/constants/endpoints';
import { serverError } from '@/constants/errors';
import * as forms from '@/helpers/forms';
import { isServerError } from '@/helpers/errors';
import * as selectors from '@/reducers/selectors';

const submissionRunning = {};
const onboardingRoles = ['prospect', 'pending_customer', 'account_creation'];

function queue(dispatch, actions) {
  return actions.reduce((prev, action) => prev.then(() => dispatch(action)), Promise.resolve());
}

export function submit(options) {
  const {
    useToken,
    formName,
    wrapperName,
    endpointName = formName,
    requiredFormData = [],
    method = 'post',
    showSuccessMessage,
    errorMessageKey,
    submissionKey,
    successDispatch = [],
    errorDispatch = [],
    finallyDispatch = [],
  } = options;

  return async function (dispatch, getState) {
    if (!formName) {
      console.error('No form name specified!');
      return;
    }

    // TODO: Instead, simply check & update state to prevent "double submission"
    if (submissionKey) {
      if (submissionRunning[submissionKey]) return;
      submissionRunning[submissionKey] = true;
    }

    const state = getState();

    const credentials = useToken ? selectors.getAuthCredentials(state) : null;

    if (useToken && !credentials) return;

    const endpoint = getEndpoint(endpointName);
    const formInput = getFormValues(formName)(state);

    let hasErrors = false;

    requiredFormData.forEach(formData => {
      if (!formInput[formData.name]) {
        dispatch(actions.addError(formData.error));
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const formValues = forms.amendValues(formName, formInput);
    const requestData = wrapperName ? { [wrapperName]: formValues } : formValues;

    await dispatch(actions.activateLoading());
    await dispatch(actions.resetErrors(null, formName));

    try {
      const response = await ajax(endpoint, method, credentials, requestData);
      const result = response[0];

      if (formName === 'signUp' || formName === 'logIn') {
        const authToken = result.user.token;
        const userId = result.user.id;
        let role = result.user ? result.user.role || 'prospect' : 'prospect';
        let isDeleted = false;

        if (role === 'marked_for_deletion') {
          role = 'customer';
          isDeleted = true;
        }

        await dispatch(actions.loginSuccess(authToken, userId, role, isDeleted));
        await dispatch(actions.userSessionTick());

        if (role === 'customer') {
          await dispatch(actions.redirectDashboard());
        } else if (onboardingRoles.includes(role)) {
          await dispatch(actions.redirectOnboardingStatus());
        }
      }

      if (showSuccessMessage) await dispatch(actions.addAlert(result.message));

      await queue(dispatch, successDispatch.map(creator => creator(result, formName)));
    } catch(error) {
      if (isServerError(error)) {
        await dispatch(actions.addError(serverError));
      } else {
        const errorBody = error[0] || {};
        const errors = errorBody.errors || {};

        await dispatch(actions.validationError(formName, errors));

        if (errorMessageKey && errors[errorMessageKey]) {
          const errorMessage = Array.isArray(errors[errorMessageKey])
            ? errors[errorMessageKey][0]
            : errors[errorMessageKey];

          await dispatch(actions.addError(errorMessage));
        }

        await queue(dispatch, errorDispatch.map(creator => creator(errorBody)));
      }
    } finally {
      await queue(dispatch, finallyDispatch.map(creator => creator()));

      await dispatch(actions.deactivateLoading());

      if (submissionKey) {
        submissionRunning[submissionKey] = false;
      }
    }
  };
};
