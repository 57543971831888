import React from 'react';

import FrontFormLayout from '@/components/specific/FrontFormLayout';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';

const ResetPassword = () => (
  <FrontFormLayout>
    <ResetPasswordForm />
  </FrontFormLayout>
);

export default ResetPassword;
