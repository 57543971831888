import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import * as texts from '@/constants/texts';

import Logo from '@/components/generic/Logo';
import LogoutButton from '@/components/generic/LogoutButton';
import SessionTimer from "@/components/generic/SessionTimer";

const OnboardingLayout = ({ children, isLoggedIn, onLogout, timer }) => {
  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length !== 2) {
    console.error('You need to pass two children to OnboardingLayout: HelpArea and a component/JSX.');
    return null;
  }

  return (
    <div className='onboarding-layout'>
      <div className='onboarding-layout-wrapper'>
        {isLoggedIn && <LogoutButton onClick={onLogout}>Logout</LogoutButton>}
        {!isLoggedIn && <Logo />}
        <aside className='onboarding-layout__aside'>
          {children[0]}
        </aside>
        <main className='onboarding-layout__main'>
          {isLoggedIn &&
            <header className='onboarding-layout__main-header'>
              <SessionTimer time={timer} />
            </header>
          }
          <div className='onboarding-layout__main-content'>
            {children[1]}
          </div>

          <footer className='onboarding-layout__main-footer'>
            <div className='onboarding-layout__contact-info'>
              <h4 className='contact-info__headline'>Haben Sie Fragen? Wir helfen gerne:</h4>
              <a href='tel:08007771200' className='contact-info__link contact-info__link--phone'>
                0800 777 12 00 (gebührenfrei)
              </a>
              <a href='mailto:service@der-zukunftsfonds.de' className='contact-info__link contact-info__link--mail'>
                service@der-zukunftsfonds.de
              </a>
            </div>
            <a
              className='onboarding-layout__footer-link'
              href='https://www.der-zukunftsfonds.de/impressum'
              target='_blank' rel='noopener noreferrer'
            >
              {texts.otherTexts.imprint}
            </a>
          </footer>
        </main>
      </div>
    </div>
  );
};

OnboardingLayout.propTypes = {
  children: PropTypes.node,
  isLoggedIn: PropTypes.bool,
  timer: PropTypes.number,
  onLogout: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoggedIn: selectors.getIsLoggedIn(state),
  timer: state.login.timer,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.submitLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLayout);
