import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, isDirty } from 'redux-form';
import { redirect } from 'redux-first-router';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';

import { otherTexts as otherLabels } from '@/constants/texts';
import * as texts from '@/constants/texts';
import { bankInfo as labels } from '@/constants/forms';
import * as strings from '@/helpers/strings';
import { ENTER_INITIAL_INVESTMENT } from '@/router';

import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const formValues = getFormValues('bankInfo')(state) || {};
  const bankInfo = selectors.getUserBankInfo(state) || {};
  const bankAccountIban = bankInfo.bankAccountIban || '';
  const name = selectors.getUserName(state);
  const errors = selectors.getFormErrors(state, 'bankInfo');
  if (!errors.bankAccountIban) {
    errors.bankAccountIban = errors.iban;
  }
  return {
    errors,
    inputIban: formValues.bankAccountIban,
    isDirty: isDirty('bankInfo')(state),
    initialValues: {
      bankAccountHolder: strings.buildFullName(name),
      bankAccountIban,
    },
  };
};

const mapDispatchToProps = (dispatch, { isUpdate = true }) => ({
  onSubmit: (e) => {
    const successActions = [
      actions.setUserDataForm.bind({}, 'bankInfo', false),
      actions.fetchData.bind({}, 'userInfo'),
    ];
    if (!isUpdate) {
      successActions.push(
        actions.fetchOnboardingStatusAndForward,
        actions.track('EEcheckout'),
      );
    }
    e.preventDefault();
    return dispatch(actions.submitBankInfo(isUpdate, successActions));
  },
  onPreviousStep: (isDirty) => {
    if (isDirty) {
      dispatch(actions.showModal({
        heading: otherLabels.previousStep,
        text: otherLabels.previousStepModalText,
        modalAction: redirect.bind({}, { type: ENTER_INITIAL_INVESTMENT }),
        showCloseButton: true,
      }));
    } else {
      dispatch(redirect({ type: ENTER_INITIAL_INVESTMENT }));
    }
  },
  onValidateIban: (iban) => dispatch(actions.validateIban(iban, 'bankInfo', 'bankAccountIban')),
});

const BankInfoForm = ({
  showBackButton = false,
  isDirty = false,
  legendText = '',
  infoText = '',
  inputIban = '',
  submitButtonLabel = otherLabels.confirmChanges,
  errors = {},
  onSubmit,
  onPreviousStep,
  onValidateIban,
}) => (
  <Form onSubmit={onSubmit} legend={legendText} info={infoText}>
    <Field
      name='bankAccountHolder'
      type='text'
      maxLength='130'
      label={labels.bankAccountHolder}
      hint={texts.hints.bankAccountHolder}
      component={LabeledInput}
      errors={errors.bankAccountHolder}
      disabled={true}
    />
    <Field
      name='bankAccountIban'
      type='text'
      label={labels.bankAccountIban}
      hint={texts.hints.ibanOnlyGerman}
      required={true}
      component={LabeledInput}
      errors={errors.bankAccountIban}
      onBlur={() => onValidateIban(inputIban)}
    />
    <RequiredHint />
    <Button type='submit'>
      {submitButtonLabel}
    </Button>
    {showBackButton &&
      <BackButton onClick={() => onPreviousStep(isDirty)}>
        {otherLabels.previousStep}
      </BackButton>
    }
  </Form>
);

BankInfoForm.propTypes = {
  showBackButton: PropTypes.bool,
  isDirty: PropTypes.bool,
  legendText: PropTypes.string,
  infoText: PropTypes.string,
  inputIban: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onPreviousStep: PropTypes.func,
  onValidateIban: PropTypes.func,
};

export { BankInfoForm };
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'bankInfo',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }, mapStateToProps),
)(BankInfoForm);
