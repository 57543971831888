import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { LOG_IN } from '@/router';
import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { loginSignUp as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import Button from '@/components/generic/Button';
import Form from '@/components/generic/Form';
import FormFooter from '@/components/generic/FormFooter';
import LabeledInput from '@/components/generic/LabeledInput';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'resetPassword'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.resetPassword());
  },
});

const ResetPasswordForm = ({
  errors = {},
  onSubmit,
}) => (
  <Form onSubmit={onSubmit} legend={texts.headings.others.resetPassword}>
    <Field
      name='email'
      type='email'
      label={labels.email}
      required={true}
      component={LabeledInput}
      errors={errors.email}
    />
    <RequiredHint />
    <Button type='submit'>
      { labels.resetPassword }
    </Button>
    <FormFooter>
      <p>
        <Link to={{ type: LOG_IN }}>{texts.backLinks.backToLogin}</Link>
      </p>
    </FormFooter>
  </Form>
);
ResetPasswordForm.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default compose(
  reduxForm({
    form: 'resetPassword',
    initialValues: {
      email: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ResetPasswordForm);
