import * as t from '@/actions/types';

const initialState = '';

const legalAgreements = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case t.FETCHED_LEGAL_AGREEMENTS:
      return payload.legalAgreements;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getLegalAgreements(state) {
  return state.onboarding.legalAgreements;
}

export default legalAgreements;
