import React from 'react';

import AccountLayout from '@/components/specific/AccountLayout';
import DashboardFormLayout from '@/components/specific/dashboard/DashboardFormLayout';
import ChangeEmailForm from '@/components/forms/ChangeEmailForm';

const ChangeEmail = () => (
  <AccountLayout>
    <DashboardFormLayout>
      <ChangeEmailForm />
    </DashboardFormLayout>
  </AccountLayout>
);

export default ChangeEmail;
