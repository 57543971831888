import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import InputContext from '@/components/generic/InputContext';
import * as uniqueId from '@/helpers/uniqueId';

class LabeledInput extends React.Component {
  constructor() {
    super();
    this.state = { isFocused: false };
    this.onFocus = this.toggleFocused.bind(this, true);
    this.onBlur = this.toggleFocused.bind(this, false);
    this.focusInput = this.focusInput.bind(this);
  }

  toggleFocused(isFocused) {
    this.setState({ isFocused });
  }

  focusInput() {
    this.input.focus();
  }

  render() {
    const {
      required = false,
      label = '',
      type = '',
      disabled = false,
      value = '',
      icon = '',

      // for redux-form
      input = {},

      // for InputContext
      hint = '',
      prefix = '',
      errors = [],
    } = this.props;

    const inputId = `input-${uniqueId.getNext()}`;
    const classes = classnames('labeled-input', {
      'labeled-input--disabled' : disabled,
      'labeled-input--focus': this.state.isFocused,
      [`labeled-input--icon labeled-input--icon-${icon}`]: icon !== '',
      'labeled-input--prefixed': prefix !== '',
    });
    const inputClasses = classnames({
      'labeled-input__input': !prefix,
      'labeled-input__prefixed-input': prefix !== '',
      'labeled-input__input--error': errors.length > 0,
    });
    const prefixClasses = classnames('labeled-input__prefix', {
      'labeled-input__prefix--focused': this.state.isFocused || input.value !== '',
    });

    return (
      <InputContext hint={hint} errors={errors}>
        <div className={classes} onClick={this.focusInput}>
          {prefix &&
            <span className={prefixClasses}>{prefix}</span>
          }
          <input
            {...input}
            ref={input => { this.input = input; }}
            value={input.value || value}
            disabled={disabled}
            id={inputId}
            type={type}
            className={inputClasses}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <label htmlFor={inputId} className='labeled-input__label'>{label} {required && ' *'}</label>
        </div>
      </InputContext>
    );
  }
};

LabeledInput.propTypes = {
  required: PropTypes.bool,
  type: PropTypes.oneOf(["text", "number", "email", "password", "tel", "date"]),
  label: PropTypes.string,
  hint: PropTypes.string,
  errors: PropTypes.array,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  prefix: PropTypes.string,
  icon: PropTypes.oneOf(["pencil"]),
};

export default LabeledInput;
