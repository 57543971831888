/* eslint-disable */

import React from 'react';
import { onboardingTermUrl } from '@/helpers/download_urls';

//TODO: how to outsource those texts into constants?
const LegalContract = () => (
  <ul>
    <li>
      <h4>Beratungsfreies Geschäft</h4>
      <p>
        Für den Erwerb von Der Zukunftsfonds ist kein Angemessenheitstest erforderlich. Ich nehme zur Kenntnis, dass, sofern der Erwerb von Fondsanteilen einen Angemessenheitstest erfordert, die FFB bereits jetzt darauf hinweist, dass eine kundenbezogene Prüfung der Kenntnisse und Erfahrungen hinsichtlich der Einschätzung der Risiken im Zusammenhang mit der Art der Finanzanlage nicht möglich ist, solange die FFB keinen Angemessenheitstest von mir erhalten hat und der Erwerb dieser Finanzinstrumente in meinem eigenen Ermessen erfolgt.
      </p>
      <p>
        Ich habe den hier abrufbaren FFB <a href={"https://www.ffb.de/formulare/download?forid=6Y7scFeUeJ76pHGXV%252ByMn4izDTvUT11xAMJ2juk1XCoBOIm8J9ogxmUyeP84sRzJq1IB5mwvgd5bFqRw5Gy0tSDWpO23q3%252BoM2cCn1BpyEu6%252BQ49c6J2OW8k0QxgP%252BoDPUPMueZMmu8fkaW1DqxkoEmIhhFnwSqMyQBoC2ww93RhmhAB0RDss2UXjl6QhxWYVahJDtGt1fxxNYfpYG%252FqTYXfAPex%252F%252BRon%252FXDvhVlSqVs%252BrKvb5%252FJJ4hvEpgGFVJgPNRcxBqFXAdsv1H4vDFsuDuKuIrqm4pFD0n4ucX4oAuXYvoOIWsB0i6Qd8qmyc4xhVSUrtMTpArO1BjHnFzkO2PyEpdvevjZHj7wSqsqczreR%252FfmKmvzx0x7asW6fKqu"} target='_blank'>Angemessenheitstest</a> erhalten.
      </p>
      <p>
        Ich nehme zur Kenntnis, dass Anlagen in Investmentfonds erst nach Kenntnisnahme der gesetzlich vorgeschriebenen Verkaufsunterlagen (aktueller Verkaufsprospekt, Kundeninformationsdokumente, Halb-/Jahresbericht, Vorab-Kosteninformation und Basisinformationen) erfolgen können.
      </p>
      <p>
        Die Basisinformationen der FFB werde/n ich/wir online in meinem/unserem persönlichen Bereich der mir/uns zur Verfügung gestellten Internetanwendung abrufen oder per Post erhalten.
      </p>
      <p>
        Alle anderen zuvor genannten Dokumente habe ich direkt von meinem Vermittler erhalten. Übrige Verkaufsdokumente, deren Übergabe vor Auftragserteilung nicht gesetzlich vorgeschrieben ist, kann ich auf Anforderung von meinem Vermittler erhalten.
      </p>
      <p>
        Sofern ich vor Erhalt der Basisinformationen bereits einen Kaufauftrag erteile, bestätige ich hiermit über die für den Erwerb des Finanzinstruments notwendigen Kenntnisse und Erfahrungen zu verfügen, es sei denn ich habe vor Ausführung des Auftrags eine anders lautende Erklärung abgegeben, um von der FFB zu erfahren, ob die Zielmarktkriterien des Fonds auf mich zutreffen.
      </p>
    </li>
    <li>
      <h4>Hinweise zu Interessenkonflikten und Orderausführungen</h4>
      <p>
        Dem Depoteröffnungsantrag beigefügt sind die <a href={onboardingTermUrl('Offenlegung_von_Interessenkonflikten_012019.pdf')} target='_blank'>Offenlegung von Interessenkonflikten</a> und die <a href={onboardingTermUrl('Grundsaetze_Orderausfuehrung_012019.pdf')} target='_blank'>Grundsätze der Orderausführung</a>.
        Ich stimme ausdrücklich den <a href={onboardingTermUrl('Grundsaetze_Orderausfuehrung_012019.pdf')} target='_blank'>Grundsätzen der Orderausführung</a> zu. Beim Erwerb von ausländischen Investmentfonds erfolgt ggf. eine Eintragung der FFB im Aktionärsregister für erworbene Investmentfondsanteile. Die FFB wird mir auf gesonderte Aufforderung die Wahrnehmung meiner Aktionärsrechte für solche Investmentfondsanteile ermöglichen.
      </p>
    </li>
    <li>
      <h4>Geschäftsbedingungen/Preis- und Leistungsverzeichnis</h4>
      <p>
        Für die Geschäftsverbindung mit der FFB gelten die <a href={"/api/v1/user/onboarding/broker_information/terms_and_conditions"} target='_blank'>Allgemeinen Geschäftsbedingungen</a>
        , die <a href={onboardingTermUrl('Sonderbedingungen_Der_Zukunftsfonds.pdf')} target='_blank'>Sonderbedingungen Der Zukunftsfonds</a>
        , das <a href={onboardingTermUrl('Preis_und_Leistungsverzeichnis_Der_Zukunftsfonds_92018.pdf')} target='_blank'>Preis- und Leistungsverzeichnis Der Zukunftsfonds</a>
        , die <a href={onboardingTermUrl('Bedingungen_Sepa-Basislastschriftverfahren_012019.pdf')} target='_blank'>Bedingungen für Zahlungen mittels Lastschrift im SEPA-Basislastschriftverfahren</a>
        , die <a href={onboardingTermUrl('Bedingungen_Ueberweisungsverkehr_012019.pdf')} target='_blank'>Bedingungen für den Überweisungsverkehr</a>
        , <a href={onboardingTermUrl('Sonderbedingungen_Onlinezugang_012019.pdf')} target='_blank'>Sonderbedingungen für die Nutzung des Onlinezugangs und den elektronischen Postversand</a>
        , <a href={onboardingTermUrl('Informationen_zu_Bankgeschaeften_Fernabsatz_012019.pdf')} target='_blank'>Informationen zu außerhalb von Geschäftsräumen geschlossenen Verträgen und Fernabsatzverträgen über Finanzdienstleistungen</a>
        , die <a href={onboardingTermUrl('Grundsaetze_Orderausfuehrung_012019.pdf')} target='_blank'>Grundsätze der Orderausführung</a>
        , die <a href={onboardingTermUrl('Offenlegung_von_Zuwendungen_012019.pdf')} target='_blank'>Offenlegung von Zuwendungen</a>
        , die <a href={onboardingTermUrl('Offenlegung_von_Interessenkonflikten_012019.pdf')} target='_blank'>Offenlegung von Interessenkonflikten</a>
        , die <a href={onboardingTermUrl('Hinweise_zum_Datenschutz_fuer_Anleger_122018.pdf')} target='_blank'>Hinweise zum Datenschutz für Anleger</a>
        , die <a href={onboardingTermUrl('Vorab-Kosteninformationen_FFB_62018.pdf')} target='_blank'>Vorab-Kosteninformation</a>
        , der <a href={onboardingTermUrl('Informationsbogen_Einleger_012019.pdf')} target='_blank'>Informationsbogen für den Einleger</a> sowie
        das <a href={"/api/v1/user/onboarding/broker_information/fees_and_services"} target='_blank'>Preis- und Leistungsverzeichnis</a>
        , die <a href={onboardingTermUrl('Sonderbedingungen_FondsdepotPlus_012019.pdf')} target='_blank'>Sonderbedingungen für das FFB FondsdepotPlus</a>.
      </p>
      <p>
        Den Druck oder die Speicherung dieser Dokumente habe ich veranlasst.
      </p>
    </li>
    <li>
      <h4>Einlagensicherung</h4>
      <p>
        Mir ist bekannt, dass die FFB der Entschädigungseinrichtung deutscher Banken GmbH angehört und freiwillig Mitglied im Einlagensicherungsfonds des Bundesverbandes deutscher Banken ist.
        Nähere Informationen kann ich den <a href={"/api/v1/user/onboarding/broker_information/terms_and_conditions"} target='_blank'>Allgemeinen Geschäftsbedingungen </a>
        und dem <a href={onboardingTermUrl('Informationsbogen_Einleger_012019.pdf')} target='_blank'>Informationsbogen für den Einleger</a> entnehmen.
        Den Erhalt bestätige ich hiermit.
      </p>
    </li>
    <li>
      <h4>Datenverarbeitung</h4>
      <p>
        Ich/Wir beauftrage/n die FFB, die sich aus der Geschäftsbeziehung ergebenden Daten, Transaktions- und Steuerdaten sowie durch den Angemessenheitstest erhobene Daten den von mir/uns jeweilsbenannten Vermittler/Untervermittler sowie dessen Vermittlerzentrale für eine umfassende anlagegerechte Vermittlung und ggf. Beratung über die Weiterentwicklung und Pflege des Depotbestandes und die Anlage in Investmentfondsanteilen bei der FFB zu übermitteln.
        Mir/uns ist bekannt, dass die FFB sämtliche im Rahmen der Geschäftsbeziehung erhaltenen personenbezogenen Daten im Einklang mit den Bestimmungen der Europäischen Datenschutz-Grundverordnung und dem Bundesdatenschutzgesetz verarbeitet.
        Weitere Regelungen und Hinweise zum Datenschutz kann ich/können wir den jeweils geltenden
        <a href={onboardingTermUrl('Hinweise_zum_Datenschutz_fuer_Anleger_122018.pdf')} target='_blank'>Hinweisen zum Datenschutz für Anleger</a>
        der FFB entnehmen.
      </p>
    </li>
    <li>
      <h4>Vereinnahmte und gewährte Vergütungen</h4>
      <p>
        Ich stimme in Abweichung von den §§ 675, 667 BGB zu, dass die FFB und die Vermittler bzw. Vermittlerzentrale, die ihnen jeweils von Dritter Seite zufließenden Provisionen bzw. geldwerten Leistungen behalten.
        Einzelheiten zu den von der FFB vereinnahmten und gewährten Vergütungen sind auf Anfrage bei der FFB erhältlich.
      </p>
    </li>
    <li>
      <h4>Informationen zu Risiken einer Geldanlage</h4>
      <p>
        Sofern ich in diesem Depoteröffnungsantrag die Nutzung des Onlinezugangs nicht ausgeschlossen habe, stimme ich mit Übersendung des Depoteröffnungsantrages zu, dass die Informationen, die angemessen sind, damit ich nach vernünftigem Ermessen die Art und die Risiken der mir angebotenen oder von mir nachgefragten Arten von Finanzinstrumenten oder Wertpapierdienstleistungen verstehe und auf dieser Grundlage meine Anlageentscheidungen treffe, auf der Internetseite <a href='//www.ffb.de/' target='_blank'>www.ffb.de</a> kostenlos zur Verfügung gestellt werden.
        Insbesondere kann ich dort die aktuellen Verkaufsprospekte/wesentlichen Anlegerinformationen (einschließlich der Vertragsbedingungen, die die Informationen über die Provision (Ausgabeaufschlag), die Kosten und die Verwaltungsvergütung der Fonds enthalten), den aktuellen Halbjahresbericht bzw. Jahresbericht, die Vorab-Kosteninformation und die Basisinformationen kostenlos herunterladen.
        Anlagen in Investmentfonds können erst nach Kenntnisnahme der gesetzlich vorgeschriebenen Verkaufsunterlagen (aktueller Verkaufsprospekt, wesentliche Anlegerinformation, Halb-/Jahresbericht, Vorab-Kosteninformation und Basisinformationen) erfolgen.
        Sofern ich gleichzeitig mit dem Depoteröffnungsantrag einen Kauf oder Sparplan beauftrage, bestätige ich, dass mir rechtzeitig vor Auftragserteilung der jeweils aktuelle Verkaufsprospekt/die wesentlichen Anlegerinformationen (einschließlich der Informationen über die Vertriebsprovisionen/Ausgabeaufschläge, die Kosten und die Verwaltungsvergütung der Fonds), der jeweils aktuelle Jahresbericht und ggf. der anschließende Halbjahresbericht kostenlos durch meinen Vermittler zur Verfügung gestellt wurde(n).
      </p>
    </li>
    <li>
      <p>
        Indem ich den Button "Bestätigen und nächster Schritt" anklicke, bestätige ich, dass ich die oben genannten Punkte zur Kenntnis genommen habe und ich mit diesen Bestimmungen einverstanden bin.
        Ich bestätige zudem, dass ich kein US-Bürger bin, nicht in den USA wohnhaft bin und ich hinsichtlich meiner weltweiten Einkünfte nicht steuerpflichtig gegenüber US-Behörden bin.
      </p>
    </li>
  </ul>
);

export default LegalContract;
