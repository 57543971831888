import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { connect } from 'react-redux';

import { INBOX_DETAIL, INBOX_OVERVIEW } from '@/router';
import * as selectors from '@/reducers/selectors';

import classnames from 'classnames';
import * as texts from '@/constants/texts';
import { formatDateString } from '@/helpers/dates';

const mapStateToProps = (state) => {
  const isMessageOpen = state.location.type === INBOX_DETAIL;
  const selectedMessage = isMessageOpen ?
    selectors.getMessageDetail(state) : {};

  let attachment, blob;
  if (selectedMessage.attachment) {
    blob = new Blob([selectedMessage.attachment], { type: 'application/pdf' });
    attachment = window.URL.createObjectURL(blob);
  }
  return {
    isMessageOpen,
    selectedMessage,
    attachment,
    blob,
    useFallbackDownload: window.navigator && window.navigator.msSaveOrOpenBlob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openFallbackDownload: (blob, fileName) => {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  },
});

const InboxDetail = ({
  isMessageOpen = '',
  selectedMessage = {},
  useFallbackDownload,
  attachment,
  blob,
  openFallbackDownload,
}) => {
  const inboxContentClasses = classnames('inbox-detail', {
    'inbox-detail--hidden-mobile': !isMessageOpen,
  });

  return (
    <div
      className={inboxContentClasses}
      role='region'
      aria-live='polite'
      aria-atomic='true'
      id='message'
    >
      {(isMessageOpen)
        ? (
          <div>
            <Link className='inbox-detail__back-button'
              to={{ type: INBOX_OVERVIEW}}
            >
              {texts.backLinks.backToInbox}
            </Link>
            <h2 className='inbox-detail__message-title'>{selectedMessage.subject}</h2>
            <time
              dateTime={selectedMessage.date}
              className='inbox-detail__message-date'
            >
              {formatDateString(selectedMessage.date)}
            </time>
            {selectedMessage.body && <p className='inbox-detail__message-text'>{selectedMessage.body}</p>}
            {selectedMessage.attachmentPath && selectedMessage.attachment && (
              <p className='inbox-detail__attachment'>
                {useFallbackDownload
                ? (
                  <a
                    className='inbox-detail__attachment-link'
                    href='#download'
                    onClick={() => openFallbackDownload(blob, `${selectedMessage.subject}.pdf`)}
                  >
                    {texts.inbox.openAttachment}
                  </a>
                )
                : (
                  <a
                    className='inbox-detail__attachment-link'
                    href={attachment}
                    download={`${selectedMessage.subject}.pdf`}
                  >
                    {texts.inbox.openAttachment}
                  </a>
                )
                }
              </p>
            )}
          </div>
          )
        : (
          <div className='inbox-detail__choose-message'>
            <img className='inbox-detail__choose-message-image' src='/static/images/paperplane.svg' alt='' />
            <p className='inbox-detail__choose-message-text'>
              {texts.inboxOverview.noMessagesSelected}
            </p>
          </div>
        )
      }
    </div>
  );
};

InboxDetail.propTypes = {
  isMessageOpen: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  selectedMessage: PropTypes.object,
  useFallbackDownload: PropTypes.bool,
  attachment: PropTypes.string,
  blob: PropTypes.object,
  openFallbackDownload: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxDetail);
