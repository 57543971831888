/* eslint-disable max-len */

export const noTokenProvided = 'Der Aktivierungscode ist nicht gültig.';
export const serverError = 'Leider ist ein Fehler aufgetreten.';
export const autoSessionLogout = 'Sie wurden aus Sicherheitsgründen automatisch ausgeloggt, da Sie 10 Minuten nicht aktiv waren.';
export const accessDenied = 'Der Zugriff wurde verweigert.';
export const orderRestrictions = {
  buy: 'Zurzeit können Sie leider keinen Kauf durchführen, da noch ein nicht disponierter Totalverkauf vorliegt.',
  sell: 'Zurzeit können Sie leider keinen Verkauf durchführen, da noch ein nicht disponierter Totalverkauf vorliegt.',
  savingsRate: 'Zurzeit können Sie leider keine Anpassung Ihres Sparplans durchführen, da noch ein nicht disponierter Totalverkauf vorliegt.',
};
