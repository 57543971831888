import { combineReducers } from 'redux';

import global from './global';
import validation from './validation';

export default combineReducers({
  global,
  validation,
});

export * from './global';
export * from './validation';
