import { redirect as rfrRedirect } from 'redux-first-router';
import queryString from 'query-string';
import castArray from 'lodash-es/castArray';

import routesMap, * as routes from './index';
import { validator } from './validator';
import * as actions from '@/actions/creators';
import * as errorMessages from '@/constants/errors';
import * as selectors from '@/reducers/selectors';

export default {
  querySerializer: queryString,

  async onBeforeChange(dispatch, getState, action, redirect = rfrRedirect, validators = validator) {
    const { type } = action;
    const route = routesMap[type];

    dispatch(actions.resetErrors());
    dispatch(actions.hideModal());
    dispatch(actions.userSessionReset());

    const formOptionsLoaded = selectors.getFormOptionsLoaded(getState());
    if (!formOptionsLoaded) {
      dispatch(actions.fetchData('options'));
    }

    if (route.isAuthRequired) {
      dispatch(actions.activateLoading('isSubmittingForm'));

      const { isValid, token, role, isDeleted, userId } = await validators.validateLogin();
      if (!isValid) {
        dispatch(actions.resetSession());
        dispatch(redirect({ type: routes.LOG_IN }));
        dispatch(actions.addError(errorMessages.accessDenied));
        return dispatch(actions.deactivateLoading('isSubmittingForm'));
      }

      dispatch(actions.loginSuccess(token, userId, role, isDeleted));
      dispatch(actions.deactivateLoading('isSubmittingForm'));

      if (route.isOnboarding) {
        if (role === 'customer') {
          dispatch(actions.addError(errorMessages.accessDenied));
          return dispatch(redirect({ type: routes.DASHBOARD }));
        }
      } else {
        if (role === 'prospect' || role === 'pending_customer' || role === 'account_creation') {
          dispatch(actions.addError(errorMessages.accessDenied));
          return dispatch(redirect({ type: routes.ONBOARDING_STATUS }));
        }
      }
    }

    if (route.isOnboarding) {
      if (type === routes.CONFIRM_IDENTITY) {
        dispatch(actions.checkDepotRequestStatus());
      } else if (type !== routes.ONBOARDING_STATUS) {
        if (await validators.isPortfolioOrderPending(getState())) {
          dispatch(actions.addError(errorMessages.accessDenied));
          return dispatch(redirect({ type: routes.ONBOARDING_STATUS }));
        }
      }
    }

    if (route.fetchData) {
      const fetchData = castArray(route.fetchData);
      fetchData.forEach(data =>
        dispatch(actions.fetchData(data)),
      );
    }

    if (route.isOnboarding) {
      dispatch(actions.fetchOnboardingStatus());
    }
  },
};
