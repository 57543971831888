import React from 'react';
import { formatDateString } from '@/helpers/dates';
import PropTypes from 'prop-types';
import { orderConfirmation } from '@/constants/texts';
import parse from 'date-fns/parse';

const transactionMightOccurLater = (nextFfbBankingDateString, estimatedNextWithdrawalString) => {
  const nextFfbBankingDate = parse(nextFfbBankingDateString);
  const estimatedNextWithdrawal = parse(estimatedNextWithdrawalString);

  return nextFfbBankingDate >= estimatedNextWithdrawal;
};

const SavingsRateText = ({ orderValue, savingsRateValidation, dayOfMonth, portfolioNumber }) => {
  const savingsRateTexts = orderConfirmation.savingsRate;
  const { firstTransactionDate,
          pendingTransactionDate,
          nextFfbBankingDate,
          estimatedNextWithdrawal } = savingsRateValidation;

  const suspendSavingsRate = !parseFloat(orderValue);
  const paragraphs = [];

  if (suspendSavingsRate) {
    paragraphs.push(<p key='suspend'>{savingsRateTexts.suspendSavingsRateText}</p>);

    if (pendingTransactionDate) {
      paragraphs.push(
        <p key='pendingTransaction'>
          {`${orderConfirmation.savingsRate.hint[0]}
          ${formatDateString(firstTransactionDate)}
          ${orderConfirmation.savingsRate.hint[1]}`}
        </p>,
      );
    }

  } else {
    paragraphs.push(
      <p key='savingsRate'>
        {savingsRateTexts.text[0]} <strong>{`${orderValue} ${savingsRateTexts.text[1]} ${dayOfMonth}`}</strong>
        {`${savingsRateTexts.text[2]} ${portfolioNumber} ${savingsRateTexts.text[3]}`}
      </p>,
    );

    if (pendingTransactionDate) {
      paragraphs.push(
        <p key='pendingTransaction'>
          {`${orderConfirmation.savingsRate.hint[0]}
          ${formatDateString(firstTransactionDate)}
          ${orderConfirmation.savingsRate.hint[1]}`}
        </p>,
      );
    } else {
      paragraphs.push(
        <p key='firstTransaction'>
          {`${savingsRateTexts.text[4]}
            ${formatDateString(savingsRateValidation.firstTransactionDate)}
            ${savingsRateTexts.text[5]}`}
        </p>,
      );
    }
  }

  if (transactionMightOccurLater(nextFfbBankingDate, estimatedNextWithdrawal)) {
    paragraphs.push(
      <p key='transactionMightOccurLater' className='qa-transaction-might-occur-later'>
        <strong>
          {`${savingsRateTexts.transactionMightOccurLater}
          ${formatDateString(estimatedNextWithdrawal)}
        `}
        </strong>
      </p>,
    );
  }

  return paragraphs;
};

SavingsRateText.propTypes = {
  orderValue: PropTypes.string,
  savingsRateValidation: PropTypes.object,
  dayOfMonth: PropTypes.string,
  portfolioNumber: PropTypes.string,
};

export default SavingsRateText;
