import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as selectors from '@/reducers/selectors';
import * as actions from '@/actions/creators';

import { loginSignUp as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'changePassword'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.submitChangePassword());
  },
  onCancel: () => dispatch(actions.redirectUserInfo()),
});

const ChangePasswordForm = ({
  formClass = 'classic-form',
  errors = {},
  onSubmit,
  onCancel,
}) => (
  <Form onSubmit={onSubmit} className={formClass} legend={texts.headings.others.changePassword}>
    <div className={`${formClass}--actions`}>
      <Field
        name='currentPassword'
        type='password'
        label={labels.currentPassword}
        component={LabeledInput}
        required={true}
        errors={errors.currentPassword}
      />
      <Field
        name='password'
        type='password'
        label={labels.newPassword}
        component={LabeledInput}
        required={true}
        errors={errors.password}
        hint={texts.hints.passwordEightChars}
      />
      <Field
        name='passwordConfirmation'
        type='password'
        label={labels.newPasswordConfirmation}
        component={LabeledInput}
        required={true}
        errors={errors.passwordConfirmation}
      />
      <RequiredHint />
      <Button className={`${formClass}--submit`} type='submit'>
        {labels.submitPassword}
      </Button>
      <BackButton onClick={onCancel}>
        {texts.backLinks.backToUserInfo}
      </BackButton>
    </div>
  </Form>
);

ChangePasswordForm.propTypes = {
  formClass: PropTypes.string,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default compose(
  reduxForm({
    form: 'changePassword',
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(ChangePasswordForm);
