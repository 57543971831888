import React from 'react';

import SavingsRateForm from '@/components/forms/SavingsRateForm';
import AccountLayout from '@/components/specific/AccountLayout';
import DashboardFormLayout from '@/components/specific/dashboard/DashboardFormLayout';

const SavingsRate = () => (
  <AccountLayout>
    <DashboardFormLayout>
      <SavingsRateForm key='savingsRateForm' />
    </DashboardFormLayout>
  </AccountLayout>
);

export default SavingsRate;
