import { combineReducers } from 'redux';

import portfolio from './portfolio';
import orders from './orders';
import orderValidation from './orderValidation';
import savingsRateValidation from './savingsRateValidation';

export default combineReducers({
  portfolio,
  orders,
  orderValidation,
  savingsRateValidation,
});

export * from './portfolio';
export * from './orders';
export * from './orderValidation';
export * from './savingsRateValidation';
