import { combineReducers } from 'redux';

import bankInfo from './bankInfo';
import contactInfo from './contactInfo';
import customerNumber from './customerNumber';
import email from './email';
import initialInvestment from './initialInvestment';
import legalAgreements from './legalAgreements';
import name from './name';
import personalInfo from './personalInfo';
import userId from './userId';

export default combineReducers({
  bankInfo,
  contactInfo,
  customerNumber,
  email,
  initialInvestment,
  legalAgreements,
  name,
  personalInfo,
  userId,
});

export * from './bankInfo';
export * from './contactInfo';
export * from './customerNumber';
export * from './email';
export * from './initialInvestment';
export * from './legalAgreements';
export * from './name';
export * from './personalInfo';
export * from './userId';
