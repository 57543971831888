import React from 'react';
import Async from 'react-code-splitting';
import { NOT_FOUND } from 'redux-first-router';

import NotFound from '@/components/routes/NotFound';
import LogIn from '@/components/routes/LogIn';
import SignUp from '@/components/routes/SignUp';

import ChangePassword from '@/components/routes/ChangePassword';
import ResetPassword from '@/components/routes/ResetPassword';
import NewPassword from '@/components/routes/NewPassword';
import UserInfo from '@/components/routes/UserInfo';
import DeleteAccount from '@/components/routes/DeleteAccount';
import PersonalInfoForm from '@/components/forms/PersonalInfoForm';
import ContactInfoForm from '@/components/forms/ContactInfoForm';
import PayIn from '@/components/routes/orders/PayIn';
import PayOut from '@/components/routes/orders/PayOut';
import SavingsRate from '@/components/routes/orders/SavingsRate';
import OrderHistory from '@/components/routes/orders/OrderHistory';
import Inbox from '@/components/routes/Inbox';
import ChangeEmail from '@/components/routes/ChangeEmail';
import ConfirmChangedEmail from '@/components/routes/ConfirmChangedEmail';

import Imprint from '@/components/routes/Imprint';

import * as thunks from './thunks';

import onboardingRoutes from './onboarding';

const Dashboard = () => <Async load={import('@/components/routes/Dashboard')} />;

export * from './onboarding';

export const START = 'router/START';
export const SIGN_UP = 'router/SIGN_UP';
export const LOG_IN = 'router/LOG_IN';

export const CHANGE_PASSWORD = 'router/CHANGE_PASSWORD';
export const RESET_PASSWORD = 'router/RESET_PASSWORD';
export const NEW_PASSWORD = 'router/NEW_PASSWORD';
export const USER_INFO = 'router/USER_INFO';
export const CHANGE_PERSONAL_INFO = 'router/CHANGE_PERSONAL_INFO';
export const CHANGE_CONTACT_INFO = 'router/CHANGE_CONTACT_INFO';
export const DELETE_ACCOUNT = 'router/DELETE_ACCOUNT';
export const UNLOCK_ACCOUNT = 'router/UNLOCK_ACCOUNT';
export const DASHBOARD = 'router/DASHBOARD';
export const PAY_IN = 'router/PAY_IN';
export const PAY_OUT = 'router/PAY_OUT';
export const SAVINGS_RATE = 'router/SAVINGS_RATE';
export const ORDER_HISTORY = 'router/ORDER_HISTORY';
export const INBOX_DETAIL = 'router/INBOX_DETAIL';
export const INBOX_OVERVIEW = 'router/INBOX_OVERVIEW';
export const CHANGE_EMAIL = 'router/CHANGE_EMAIL';
export const CONFIRM_CHANGED_EMAIL = 'router/CONFIRM_CHANGED_EMAIL';

export const IMPRINT = 'router/IMPRINT';

export default {
  ...onboardingRoutes,
  [START]: {
    path: '/',
    component: LogIn,
    thunk: thunks.forwardLoggedIn,
  },
  [LOG_IN]: {
    path: '/login',
    component: LogIn,
    thunk: thunks.forwardLoggedIn,
  },
  [UNLOCK_ACCOUNT]: {
    path: '/unlock-account',
    component: LogIn,
    thunk: thunks.unlockAccount,
  },
  [SIGN_UP]: {
    path: '/signup',
    component: SignUp,
    thunk: thunks.forwardLoggedIn,
    fetchData: 'options',
  },
  [CHANGE_PASSWORD]: {
    path: '/change-password',
    component: ChangePassword,
  },
  [RESET_PASSWORD]: {
    path: '/reset-password',
    component: ResetPassword,
    thunk: thunks.forwardLoggedIn,
  },
  [NEW_PASSWORD]: {
    path: '/new-password',
    component: NewPassword,
    thunk: thunks.newPassword,
  },
  [USER_INFO]: {
    path: '/user-info',
    component: UserInfo,
    isAuthRequired: true,
    fetchData: ['userInfo', 'userPortfolio'],
  },
  [CHANGE_PERSONAL_INFO]: {
    path: '/user-info/change-personal-info',
    component: PersonalInfoForm,
    isAuthRequired: true,
    fetchData: 'userInfo',
  },
  [CHANGE_CONTACT_INFO]: {
    path: '/user-info/change-contact-info',
    component: ContactInfoForm,
    isAuthRequired: true,
    fetchData: 'userInfo',
  },
  [DELETE_ACCOUNT]: {
    path: '/user-info/delete-account',
    component: DeleteAccount,
    isAuthRequired: true,
    fetchData: ['userInfo', 'userPortfolio'],
  },
  [DASHBOARD]: {
    path: '/dashboard',
    component: Dashboard,
    isAuthRequired: true,
    fetchData: ['userInfo', 'userPortfolio', 'orderHistory', 'messages'],
  },
  [PAY_IN]: {
    path: '/pay-in',
    component: PayIn,
    isAuthRequired: true,
    fetchData: 'userPortfolio',
  },
  [PAY_OUT]: {
    path: '/pay-out',
    component: PayOut,
    isAuthRequired: true,
    fetchData: 'userPortfolio',
  },
  [SAVINGS_RATE]: {
    path: '/savings-rate',
    component: SavingsRate,
    isAuthRequired: true,
    fetchData: ['userPortfolio'],
  },
  [ORDER_HISTORY]: {
    path: '/orders',
    component: OrderHistory,
    isAuthRequired: true,
    fetchData: 'orderHistory',
  },
  [INBOX_DETAIL]: {
    path: '/inbox/:messageId',
    component: Inbox,
    isAuthRequired: true,
    thunk: thunks.fetchMessage,
    fetchData: 'messages',
  },
  [INBOX_OVERVIEW]: {
    path: '/inbox',
    component: Inbox,
    isAuthRequired: true,
    fetchData: 'messages',
  },
  [CHANGE_EMAIL]: {
    path: '/user-info/change-email',
    component: ChangeEmail,
    isAuthRequired: true,
    fetchData: 'userInfo',
  },
  [CONFIRM_CHANGED_EMAIL]: {
    path: '/user-info/confirm-email',
    component: ConfirmChangedEmail,
    isAuthRequired: false,
  },
  [IMPRINT]: {
    path: '/imprint',
    component: Imprint,
  },
  [NOT_FOUND]: {
    component: NotFound,
  },
};
