import React from 'react';
import PropTypes from 'prop-types';

const SideBySide = ({ children, ratio = 'half' }) => {
  const childrenArray = React.Children.toArray(children).slice(0, 2);

  if (children.length > 2) {
    console.warn('You’ve passed more than 2 children to the SideBySide component.');
  }

  if (childrenArray.length === 0) {
    return null;
  }

  return (
    <div className={`side-by-side side-by-side--${ratio}`}>
      <div className='side-by-side__box'>
        {childrenArray[0]}
      </div>
      {childrenArray[1] &&
        <div className='side-by-side__box'>
          {childrenArray[1]}
        </div>
      }
    </div>
  );
};

SideBySide.propTypes = {
  children: PropTypes.node,
  ratio: PropTypes.oneOf(['half', 'golden']),
};

export default SideBySide;
