import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions/creators';

import * as texts from '@/constants/texts';
import * as selectors from '@/reducers/selectors';

import AccountLayout from '@/components/specific/AccountLayout';
import BackButton from '@/components/generic/BackButton';
import SessionTimer from "@/components/generic/SessionTimer";
import DepositHistory from '@/components/routes/orders/DepositHistory';

const mapStateToProps = (state) => ({
  orders: selectors.getUserOrders(state),
  timer: state.login.timer,
});

const mapDispatchToProps = (dispatch) => ({
  backToDashboard: () => dispatch(actions.redirectDashboard()),
});

const OrderHistory = ({
  orders = [],
  backToDashboard,
  timer,
}) => (
  <AccountLayout>
    <div className='order-history'>
      <div className='order-history__session'>
        <SessionTimer time={timer} />
      </div>
      <div className='order-history__layout'>
        <h1 className='order-history__headline'>{texts.headings.others.orderHistory}</h1>
        <DepositHistory orders={orders} labels={true} />
        <BackButton onClick={backToDashboard}>
          {texts.backLinks.backToDashboard}
        </BackButton>
      </div>
    </div>
  </AccountLayout>
);

OrderHistory.propTypes = {
  orders: PropTypes.array,
  backToDashboard: PropTypes.func,
  timer: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
