import React from 'react';

import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from '@/components/generic/HelpArea';
import WelcomeText from '@/components/specific/onboarding/WelcomeText';
import FfbDisclaimer from '@/components/generic/FfbDisclaimer';

import SignUpForm from '@/components/forms/SignUpForm';

const SignUp = () => (
  <OnboardingLayout>
    <HelpArea>
      <WelcomeText />
      <FfbDisclaimer />
    </HelpArea>
    <SignUpForm />
  </OnboardingLayout>
);

export { SignUp };
export default SignUp;
