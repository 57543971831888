import { combineReducers } from 'redux';

import errors from './errors';
import loading from './loading';
import alerts from './alerts';
import onboarding from './onboarding';

export default combineReducers({
  errors,
  loading,
  alerts,
  onboarding,
});

export * from './errors';
export * from './loading';
export * from './alerts';
export * from './onboarding';
