import { redirect } from 'redux-first-router';

import * as onboarding from '@/helpers/onboarding';
import * as t from '@/actions/types';
import routes from '@/router';

export default (store) => next => action => {
  const { type, payload } = action;

  if (type === t.FETCHED_ONBOARDING_STATUS) {
    const status = payload.status;
    const routeType = store.getState().location.type;
    const route = routes[routeType];
    const nextStep = onboarding.getNextStep(status);

    if (route.step > nextStep.step) {
      return store.dispatch(redirect({ type: nextStep.routeAction }));
    }

    payload.nextStep = nextStep;
  }
  return next(action);
};
