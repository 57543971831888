import { letters as texts } from '@/constants/texts';

/*
  Usage: create a full name string out of its components
*/
export function buildFullName(
  personalInfo,
  options,
  useGender = false,
  useTitle = false,
  useBirthName = false,
) {
  let name = [];

  if (useGender && personalInfo.gender) {
    name.push(personalInfo.gender === 'female' ? 'Frau' : 'Herr');
  }

  if (useTitle && options.title && personalInfo.title) {
    const title = options.title.find(o => o.value === personalInfo.title);
    if (title) {
      name.push(title.label);
    }
  }

  name.push(personalInfo.firstName);
  if (!personalInfo.noMiddleNames && personalInfo.middleNames) {
    name.push(personalInfo.middleNames);
  }
  name.push(personalInfo.lastName);

  if (useBirthName && personalInfo.birthName) {
    name.push(`(geboren ${personalInfo.birthName})`);
  }

  return name.join(' ').trim();
}

/*
  Usage: create encoded uris from a parameter object
*/
export function buildParameterString(params) {
    let paramString = '?';
    const keys = Object.keys(params);
    const lastKey = keys.length - 1;
    keys.forEach((key, index) => {
        paramString += `${key}=${params[key]}`;
        if (index !== lastKey) {
            paramString += '&';
        }
    });
    return window.encodeURI(paramString);
}

/*
  Usage: subject line for banking related letters (e.g. delete account)
*/
export function buildDepotInfo(id, portfolioNo, personNo) {
  const subjectLine = [];

  if (id) {
    subjectLine.push(`${texts.id} ${id}`);
  }
  if (portfolioNo) {
    subjectLine.push(`${texts.portfolioNo} ${portfolioNo}`);
  }
  if (personNo) {
    subjectLine.push(`${texts.personNo} ${personNo}`);
  }

  return subjectLine.join(', ').trim();
}
