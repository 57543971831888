import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import * as actions from '@/actions/creators';
import * as selectors from '@/reducers/selectors';
import { legal as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';

import LegalContract from '@/components/specific/legal/Contract';
import PersonalData from '@/components/specific/legal/PersonalData';
import Withdrawal from '@/components/specific/legal/Withdrawal';
import Sepa from '@/components/specific/legal/Sepa';
import DfgAgreements from '@/components/specific/legal/DfgAgreements';
import DfgPrivacyPolicy from '@/components/specific/legal/DfgPrivacyPolicy';
import DfgContractInfo from '@/components/specific/legal/DfgContractInfo';
import DfgInvestmentAdvice from '@/components/specific/legal/DfgInvestmentAdvice';
import DfgRightOfWithdrawal from '@/components/specific/legal/DfgRightOfWithdrawal';
import Form from '@/components/generic/Form';
import Button from '@/components/generic/Button';
import AgreementsToggle from '../generic/AgreementsToggle';
import AgreementsSeparator from '../generic/AgreementsSeparator';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => {
  const formValues = getFormValues('legalAgreements')(state) || {};
  return {
    isSubmitDisabled: Object.values(formValues).filter(v => !v).length > 0,
    errors: selectors.getFormErrors(state, 'createPortfolio'),
    initialValues: selectors.getUserLegalAgreements(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.submitLegalAgreements([
      actions.fetchOnboardingStatusAndForward,
      actions.track('legalAgreementsAgreed'),
    ]));
  },
});

const LegalAgreementsForm = ({
  isSubmitDisabled,
  errors = {},
  onSubmit,
}) => (
  <Form
    key='portfolio-form'
    onSubmit={onSubmit}
    legend={labels.legend}
    info={labels.info}
  >
    <AgreementsSeparator headline={labels.dfgAgreementSeparatorHeadline}>
      <AgreementsToggle
        DetailComponent={DfgInvestmentAdvice}
        infoText={labels.dfgInvestmentAdviceLink}
        fieldProps={{
          name: 'dfgInvestmentAdvice',
          labelTitle: labels.dfgInvestmentAdviceTitle,
          label: labels.dfgInvestmentAdvice,
          errors: errors.dfgContractInfo,
          isToggled: true,
        }}
      />
      <AgreementsToggle
        DetailComponent={DfgAgreements}
        infoText={labels.dfgAgreementsLink}
        fieldProps={{
          name: 'dfgAgreements',
          labelTitle: labels.dfgAgreementsTitle,
          label: labels.dfgAgreements,
          errors: errors.dfgAgreements,
        }}
      />
      <AgreementsToggle
        DetailComponent={DfgPrivacyPolicy}
        infoText={labels.dfgPrivacyPolicyLink}
        fieldProps={{
          name: 'dfgPrivacyPolicy',
          labelTitle: labels.dfgPrivacyPolicyTitle,
          label: labels.dfgPrivacyPolicy,
          errors: errors.dfgPrivacyPolicy,
        }}
      />
      <AgreementsToggle
        DetailComponent={DfgRightOfWithdrawal}
        infoText={labels.dfgRightOfWithdrawalLink}
        fieldProps={{
          name: 'dfgRightOfWithdrawal',
          labelTitle: labels.dfgRightOfWithdrawalTitle,
          label: labels.dfgRightOfWithdrawal,
          errors: errors.dfgRightOfWithdrawal,
        }}
      />
      <AgreementsToggle
        DetailComponent={DfgContractInfo}
        infoText={labels.dfgContractInfoLink}
        fieldProps={{
          name: 'dfgContractInfo',
          labelTitle: labels.dfgContractInfoTitle,
          label: labels.dfgContractInfo,
          errors: errors.dfgContractInfo,
        }}
      />
    </AgreementsSeparator>

    <AgreementsSeparator headline={labels.ffbAgreementSeparatorHeadline}>
      <AgreementsToggle
        DetailComponent={LegalContract}
        infoText={labels.contractAgreementLink}
        fieldProps={{
          name: 'contractAgreement',
          labelTitle: labels.contractAgreementTitle,
          label: labels.contractAgreement,
          errors: errors.contractAgreement,
        }}
      />
      <AgreementsToggle
        DetailComponent={PersonalData}
        infoText={labels.personalDataLink}
        fieldProps={{
          name: 'personalData',
          labelTitle: labels.personalDataTitle,
          label: labels.personalData,
          errors: errors.personalData,
        }}
      />
      <AgreementsToggle
        DetailComponent={Withdrawal}
        infoText={labels.rightOfWithdrawalLink}
        fieldProps={{
          name: 'rightOfWithdrawal',
          labelTitle: labels.rightOfWithdrawalTitle,
          label: labels.rightOfWithdrawal,
          errors: errors.rightOfWithdrawal,
        }}
      />
      <AgreementsToggle
        DetailComponent={Sepa}
        infoText={labels.sepaDebitTermLink}
        fieldProps={{
          name: 'sepaDebitTerm',
          labelTitle: labels.sepaDebitTermTitle,
          label: labels.sepaDebitTerm,
          errors: errors.sepaDebitTerm,
        }}
      />
    </AgreementsSeparator>
    <p>
      Folgende Unterlagen haben wir Ihnen bereits per E-mail zugesendet:
    </p>

    <span className='labeled-checkbox__label-title'>Unterlagen der DFG Deutsche Fondsgesellschaft SE Invest</span>

    <ul className='legal-agreements-form__list'>
      <li>Allgemeine Geschäftsbedingungen der DFG</li>
      <li>Datenschutzerkläerung der DFG</li>
      <li>Wichtige vorvertragliche Informationen der DFG</li>
    </ul>

    <span className='labeled-checkbox__label-title'>Unterlagen zu Ihrem Depot bei der FFB</span>

    <ul className='legal-agreements-form__list'>
      <li>Allgemeine Geschäftsbedingungen der FFB</li>
      <li>Bedingungen Sepa Basislastschriftverfahren</li>
      <li>Bedingungen Überweisungsverkehr</li>
      <li>Grundsätze Ordnerausführung</li>
      <li>Hinweise zum Datenschutz</li>
      <li>Informationen zu Bankgeschäften Fernabsatz</li>
      <li>Informationsbogen Einleger</li>
      <li>Offenlegung von Interessenkonflikten</li>
      <li>Offenlegung von Zuwendungen</li>
      <li>Preis und Leistungsverzeichnis</li>
      <li>Preis und Leistungsverzeichnis Der Zukunftsfonds</li>
      <li>Sonderbedingungen Der Zukunftsfonds</li>
      <li>Sonderbedingungen FondsdepotPlus</li>
      <li>Sonderbedingungen Onlinezugang</li>
      <li>Vorab Kosteninformationen FFB</li>
    </ul>

    <span className='legal-agreements-form__title'>Unterlagen von Universal Investment</span>

    <ul className='legal-agreements-form__list'>
      <li>Key Investor Information</li>
      <li>Verkaufsprospect</li>
    </ul>

    <RequiredHint />
    <Button type='submit' disabled={isSubmitDisabled}>
      {texts.otherTexts.confirmAndNextStep}
    </Button>
  </Form>
  );

LegalAgreementsForm.propTypes = {
  isSubmitDisabled: PropTypes.bool,
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'legalAgreements',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  }, mapStateToProps),
)(LegalAgreementsForm);
