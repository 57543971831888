import * as t from '@/actions/types';

const initialState = {
  firstTransactionDate: null,
  pendingTransactionDate: null,
  nextFfbBankingDate: null,
  estimatedNextWithdrawal: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {

    case t.SET_SAVINGS_RATE_VALIDATION:
      return Object.assign({}, state, payload.validation);

    case t.RESET_SAVINGS_RATE_VALIDATION:
      return initialState;

    default:
      return state;
  }
};

export function getSavingsRateValidation(state) {
  return state.finance.savingsRateValidation;
}
