import * as t from '@/actions/types';

const initialState = null;

export default (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.SET_RESET_PASSWORD_TOKEN:
      return payload.token;

    case t.RESET_SESSION:
      return initialState;

    default:
      return state;
  }
};

export function getResetPasswordToken(state) {
  return state.login.resetPasswordToken;
}
