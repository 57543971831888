import * as t from '@/actions/types';
import * as actions from '@/actions/creators';
import * as errors from '@/constants/errors';

let intervalId;

export default store => next => action => {
  const { type } = action;
  const { login: { timer } } = store.getState();

  if (!intervalId) {
    intervalId = setInterval(() => {
      store.dispatch(actions.userSessionTick());
    }, 1000);
  }

  if (type === t.USER_SESSION_TIMER_TICK && timer === 1) {
    store.dispatch(actions.submitLogout(false));
    store.dispatch(actions.addError(errors.autoSessionLogout));
  }

  return next(action);
};
