import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import * as actions from '@/actions/creators';
import { changeEmail as labels } from '@/constants/forms';
import * as texts from '@/constants/texts';
import * as selectors from '@/reducers/selectors';

import Button from '@/components/generic/Button';
import BackButton from '@/components/generic/BackButton';
import Form from '@/components/generic/Form';
import LabeledInput from '@/components/generic/LabeledInput';
import RequiredHint from '@/components/generic/RequiredHint';

const mapStateToProps = (state) => ({
  errors: selectors.getFormErrors(state, 'changeEmail'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(actions.updateUserInfo('changeEmail'));
  },
  onCancel: () => dispatch(actions.redirectUserInfo()),
});

const ChangeEmailForm = ({
  errors = {},
  onSubmit,
  onCancel,
}) => (
  <Form onSubmit={onSubmit} legend={texts.headings.others.changeEmail}>
    <Field
      name='email'
      type='email'
      label={labels.description}
      hint={texts.hints.confirmEmail}
      required={true}
      component={LabeledInput}
      errors={errors.email}
    />
    <RequiredHint />
    <Button type='submit'>{labels.submit}</Button>
    <BackButton onClick={onCancel}>
      {texts.backLinks.backToUserInfo}
    </BackButton>
  </Form>
);

ChangeEmailForm.propTypes = {
  errors: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'changeEmail',
    initialValues: {
      email: '',
    },
  }),
)(ChangeEmailForm);
