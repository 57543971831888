import { combineReducers } from 'redux';

import graphSettings from './graphSettings';
import legalAgreements from './legalAgreements';
import nextStep from './nextStep';
import status from './status';

export default combineReducers({
  graphSettings,
  legalAgreements,
  nextStep,
  status,
});

export * from './graphSettings';
export * from './legalAgreements';
export * from './nextStep';
export * from './status';
