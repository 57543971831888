import { combineReducers } from 'redux';

import * as t from '@/actions/types';

/****
DATA
****/
const initialStateData = {};

const data = (state = initialStateData, action = {}) => {
  const { type, payload } = action;
  switch(type) {
    case t.FETCHED_FORM_OPTIONS:
      return Object.assign({}, state, payload.options);
    case t.RESET_SESSION:
      return initialStateData;
    default:
      return state;
  }
};

export function getFormOptions(state, option) {
  if (option) {
    return state.forms.options.data[option];
  }
  return state.forms.options.data || {};
}

/****
STATUS
****/
const initialStateStatus = false;

const status = (state = initialStateStatus, action = {}) => {
  const { type } = action;
  switch(type) {
    case t.FETCHED_FORM_OPTIONS:
      return true;
    case t.RESET_SESSION:
      return initialStateStatus;
    default:
      return state;
  }
};

export function getFormOptionsLoaded(state) {
  return state.forms.options.status;
}

export default combineReducers({
  data,
  status,
});
