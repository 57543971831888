import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { connect } from 'react-redux';

import * as texts from '@/constants/texts';
import * as selectors from '@/reducers/selectors';
import { START } from '@/router';
import OnboardingLayout from '@/components/specific/onboarding/OnboardingLayout';
import HelpArea from "@/components/generic/HelpArea";

const mapStateToProps = (state) => {
  const previousRouteType = selectors.previousRouteType(state) || START;
  return {
    previousRouteType,
  };
};

const Imprint = ({ previousRouteType = '' }) => (
  <OnboardingLayout>
    <HelpArea />
    <div>
      <h1>{texts.headings.others.imprint}</h1>
      <p>tbd</p>
      <Link to={{ type: previousRouteType }}>{texts.otherTexts.back}</Link>
    </div>
  </OnboardingLayout>
);

Imprint.propTypes = {
  previousRouteType: PropTypes.string,
};

export default connect(mapStateToProps)(Imprint);
