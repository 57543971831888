/* eslint-disable */

import React from 'react';

//TODO add real texts
const PrivacyPolicy = () => (
  <div className='privacy-policy'>
    <h4>Datenschutz</h4>
    <p>www.der-zukunftsfonds.de</p>
    <p>1.	Worum geht es in dieser Datenschutzerklärung ?</p>
    <p>1.1	Die DFG Deutsche Fondsgesellschaft SE Invest, Schlüterstr. 40, 10707 Berlin(„DFG“), ist Betreiber der Webseite <a href='https://www.der-zukunftsfonds.de/' target='_blank'>www.der - zukunftsfonds.de</a>(„Webseite“).Über die Webseite vermittelt die DFG Geschäfte über die Anschaffung und Veräußerung von Anteilen an „Der Zukunftsfonds“.</p>
    <p>1.2	In diesem Dokument(die „Datenschutzerklärung“) erklärt die DFG, welche personenbezogenen Daten von wem der unmittelbar Beteiligten wie erhoben, verarbeitet und genutzt werden im Zusammenhang mit</p>
    <p>(a)	dem Registrierungsprozess und der Anlage eines Benutzerkontos(wie in Ziffer 2 definiert) einschließlich dem Bemühen der DFG um Vermittlung der Eröffnung eines Depots durch die Kooperationsbank(wie in Ziffer 2 definiert)(siehe dazu nachfolgende Ziffer 2); und</p>
    <p>(b)	der Nutzung von Funktionalitäten des Benutzerkontos, insbesondere zur Verwaltung des Benutzerkontos, der Übermittlung von Weisungen der Kunden(wie in Ziffer 3 definiert) an die Kooperationsbank sowie der Übermittlung von Willenserklärungen und Informationen der Kooperationsbank an die Kunden(siehe dazu nachfolgende Ziffer 3).</p>
    <p>1.3	Weiter informiert die DFG in dieser Datenschutzerklärung darüber, wie und zu welchem Zweck Cookies und ähnliche Technologien von der DFG bzw.Dritten im Rahmen der Nutzung der Webseite eingesetzt werden(siehe dazu nachfolgende Ziffer 4) und gibt die DFG allgemeine Informationen im Hinblick auf die Erhebung, Verarbeitung und Nutzung personenbezogener Daten der Kunden, insbesondere darüber, an wen sich Kunden zur Geltendmachung von datenschutzrechtlichen Ansprüchen(insbesondere auf Auskunft, Löschung, Berichtigung und Datenportabilität) wenden können, (siehe dazu nachfolgende Ziffer 5).</p>
    <p>2.	Erhebung, Verarbeitung und Nutzung von Daten im Rahmen des Registrierungsprozesses und der Anlage eines Benutzerkontos.</p>
    <p>Bei der Registrierung und der Anlage eines Benutzerkontos geht es darum, dass.</p>
    <p>(a)	die DFG den Antrag einer Person, die Anteile an „Der Zukunftsfonds“ erwerben möchte(„Interessenten“), auf Abschluss eines Nutzungsvertrages mit dem Interessenten nach Maßgabe der <a href='https://www.der-zukunftsfonds.de/agb' target='_blank'>Allgemeinen Geschäftsbedingungen</a> abrufbaren Allgemeinen Geschäftsbedingungen(der „Nutzungsvertrag“) prüft und das Benutzerkonto einrichtet, und</p>
    <p>(b)	die Kooperationsbank den ihr von der DFG übermittelten Antrag des Interessenten auf Eröffnung eines Depots bei der Kooperationsbank prüft.</p>
    <p>Die DFG bietet Interessenten, die nicht bereits über ein Wertpapierdepot verfügen oder ein bestehendes Wertpapierdepot nicht im Zusammenhang mit dem Erwerb von Anteilen an „Der Zukunftsfonds“ nutzen wollen die Möglichkeit, sich auf der Webseite zu registrieren und ein Benutzerkonto anzulegen(das „Benutzerkonto“).Die DFG wird sich sodann darum bemühen, dem Interessenten die Eröffnung eines Wertpapierdepots bei einer Bank oder einem Kreditinstitut, mit der / dem die DFG für diese Zwecke eine Kooperation vereinbart hat(„Kooperationsbank“), zu vermitteln, indem bestimmte Daten und ein entsprechender Antrag des Interessenten an die Kooperationsbank übermittelt werden. Die gegenwärtige Kooperationsbank kann <a href='https://www.der-zukunftsfonds.de/partnerbank' target='_blank'>hier</a> eingesehen werden und wird im konkreten Fall auch im Rahmen des Registrierungsprozesses offen gelegt.</p>
    <p>Interessenten können Anteile an „Der Zukunftsfonds“ auch über ihre Hausbank bzw.Depotbank erwerben.Ein Besuch der Webseite ist in diesem Fall nicht erforderlich, ebenso wenig eine Registrierung und Anlage eines Benutzerkontos.</p>
    <p>2.1	Wer ist neben der DFG an der Erhebung, Verarbeitung und Nutzung von Daten im Rahmen der Registrierung und der Anlage eines Benutzerkontos beteiligt ?</p>
    <p>Neben der DFG ist die Kooperationsbank in den Registrierungsprozess eingebunden, da die Annahme des Antrags auf Abschluss eines Depotvertrages durch die Kooperationsbank zwingende Voraussetzung für die Einrichtung eines Benutzerkontos und die Annahme des Antrags des Kunden auf Abschluss eines Nutzungsvertrags mit der DFG ist.</p>
    <p>2.2	Welche Daten werden von der DFG im Rahmen des Registrierungsprozesses als verantwortliche Stelle wie erhoben, verarbeitet und genutzt ?</p>
    <p>(a)	Stammdaten der Interessenten</p>
    <p>Im Rahmen des Registrierungsprozesses müssen Interessenten die folgenden Angaben machen:</p>
    <p>(i)	Name und Vorname(n) des Interessenten,</p>
    <p>(ii)	Anrede des Interessenten,</p>
    <p>(iii)	Geburtsdatum des Interessenten,</p>
    <p>(iv)	E - Mail Adresse des Interessenten,</p>
    <p>(v)	private Postanschrift des Interessenten,</p>
    <p>(vi)	Staatsangehörigkeit,</p>
    <p>(vii)	Steuerland,</p>
    <p>(viii)	Steueridentifikationsnummer(TIN)</p>
    <p>(nachfolgend, die „Stammdaten“).</p>
    <p>Die DFG erhebt, verarbeitet und nutzt die Stammdaten eines Interessenten zur Bearbeitung und Prüfung des Antrages dieses Interessenten auf Abschluss eines Nutzungsvertrages sowie bei erfolgreicher Registrierung zur Einrichtung eines Benutzerkontos und zur Durchführung der Funktionalitäten der Webseite.</p>
    <p>Die DFG übermittelt die Stammdaten sowie das geplante Sparvorhaben an die Kooperationsbank im Zusammenhang mit dem Bemühen um Vermittlung der Eröffnung eines Depots durch die Kooperationsbank und den Abschluss eines Depotvertrages zwischen dem Interessenten und der Kooperationsbank sowie zur Durchführung der Funktionalitäten der Webseite.</p>
    <p>(b)	Bankverbindungsdaten der Interessenten</p>
    <p>Zusätzlich zu den Stammdaten werden für den Abschluss eines Depotvertrages mit der Kooperationsbank auch die Bankverbindungsdaten(IBAN) des jeweiligen Interessenten benötigt.Diese werden von der DFG ausschließlich zur Weiterleitung an die Kooperationsbank gespeichert und bei der DFG gelöscht, wenn der Interessent sein Depot stilllegt bzw.kündigt.</p>
    <p>(c)	Protokollierung</p>
    <p>Um die Registrierung eines Interessenten sowie seine Zustimmung zu dem Nutzungsvertrag zu dokumentieren, speichert die DFG den Zeitpunkt der Bestätigung des Registrierungsantrags und der Zustimmung zu dem Nutzungsvertrag sowie die IP - Adresse des Geräts, das dafür jeweils genutzt wurde.Um der Kooperationsbank die Prüfung zu ermöglichen, dass ein Kunde dem Nutzungsvertrag zugestimmt hat, übermittelt die DFG der Kooperationsbank auf Verlangen die insoweit protokollierten Daten.</p>
    <p>Die IP - Adresse aller Besucher der Webseite wird zusätzlich für Wartungs - und Sicherheitszwecke für 30 Tage gespeichert und dann automatisch gelöscht.</p>
    <p>2.3	Auf welcher Grundlage verwendet die DFG als verantwortliche Stelle personenbezogene Daten im Rahmen des Registrierungsprozesses ?</p>
    <p>  Grundlage ist die Anbahnung eines Nutzungsvertrags.</p>
    <p>2.4	Welche personenbezogene Daten werden von der Kooperationsbank im Rahmen des Registrierungsprozesses als verantwortliche Stelle wie erhoben, verarbeitet und genutzt ?</p>
    <p>  Welche Daten von der Kooperationsbank im Rahmen des Registrierungsprozesses als verantwortliche Stelle wie und auf welcher Grundlage erhoben, verarbeitet und genutzt werden, ergibt sich aus den <a href='https://www.der-zukunftsfonds.de/partnerbank' target='_blank'>hier</a> abrufbaren bzw.in dem im Rahmen des Registrierungsprozesses übermittelten Antragsunterlagen der Kooperationsbank enthaltenen Datenschutzhinweisen der Kooperationsbank.</p>
    <p>Nach Abschluss der Prüfung des Antrags eines Interessenten auf Abschluss eines Depotvertrages teilt die Kooperationsbank der DFG mit, ob diese Prüfung erfolgreich war oder nicht.</p>
    <p>2.5	Was passiert mit den von der DFG als verantwortliche Stelle im Rahmen des Registrierungsprozesses erhobenen Stammdaten, wenn die Kooperationsbank den Antrag auf Abschluss eines Depotvertrages nicht annimmt ?</p>
    <p>Nimmt die Kooperationsbank den Antrag des Interessenten auf Abschluss eines Depotvertrages nicht an, ist der Abschluss einer Registrierung als Kunde auf der Webseite und die Einrichtung eines Benutzerkontos leider nicht möglich und informiert die DFG den Kunden entsprechend über die im Rahmen des Registrierungsprozesses von dem Interessenten angegebene E - Mail Adresse.Die von einem Interessenten im Rahmen des Registrierungsprozesses von der DFG als verantwortliche Stelle erhobenen Daten werden in diesem Fall bei der DFG gelöscht, es sei denn für eine weitere Speicherung besteht eine gesetzliche Grundlage.</p>
    <p>3.	Erhebung, Verarbeitung und Nutzung von Daten im Zusammenhang mit der Nutzung der Funktionalitäten des Benutzerkontos</p>
    <p>Über die Webseite haben Interessenten, für die ein Benutzerkonto von der DFG eingerichtet und freigeschaltet wurde(„Kunden“), die Möglichkeit, in ihrem Benutzerkonto bestimmte Informationen über ihr bei der Kooperationsbank geführtes Depot abzurufen und bestimmte vordefinierte Weisungen zum Kauf und Verkauf sowie zur Einrichtung von Fondssparplänen an die Kooperationsbank zu übermitteln.</p>
    <p>3.1	Verwaltung des Benutzerkontos</p>
    <p>Auf der Webseite bzw.innerhalb des Benutzerkontos werden bestimmte Funktionalitäten zur Verwaltung des Benutzerkontos bereit gestellt, bspw.zur Passwortänderung oder der Kontolöschung.</p>
    <p>  Daten, die der Kunde im Rahmen der Verwaltung seines Benutzerkontos auf der Webseite bzw.innerhalb des Benutzerkontos eingibt und an die DFG übermittelt, werden von der DFG für diese Verwaltungszwecke genutzt.Wenn ein Kunde seine Stammdaten aktualisiert(was schriftlich erfolgen muss, entsprechende Formulare werden im Benutzerkonto zur Verfügung gestellt), reicht die DFG diese Änderungen an die betreffende Kooperationsbank weiter.</p>
    <p>Eine Änderung der Bankverbindungsdaten ist ebenfalls nur schriftlich in einem separaten Prozess unmittelbar gegenüber der Kooperationsbank möglich, die entsprechenden Unterlagen sind über das Benutzerkonto zugänglich.</p>
    <p>3.2	Weisungen zum Kauf und Verkauf</p>
    <p>Aus dem Benutzerkonto heraus können Kunden nach Maßgabe des Nutzungsvertrages bestimmte vordefinierte Weisungen zum Kauf und Verkauf von Anteilen an „Der Zukunftsfonds“ sowie zur Einrichtung von Fondssparplänen mit Anteilen an „Der Zukunftsfonds“ an die Kooperationsbank übermitteln.</p>
    <p>3.3	Persönliches Postfach</p>
    <p>In dem Benutzerkonto wird Kunden ein persönliches Postfach bereitgestellt, über welches den Kunden Willenserklärungen der Kooperationsbank und Informationen über ihr bei der Kooperationsbank geführtes, von der DFG vermitteltes Depot bereitgestellt werden.Die DFG erhebt, verarbeitet und nutzt von der Kooperationsbank insoweit erhaltene personenbezogene Daten zur Bereitstellung in dem persönlichen Postfach zum Abruf durch den Kunden.</p>
    <p>3.4	Transaktionshistorie</p>
    <p>In dem Benutzerkonto können Kunden zudem ihre Transaktionshistorie für einen bestimmten Zeitraum einsehen.Die diesbezüglichen Daten erhält die DFG in regelmäßigen Abständen von der Kooperationsbank und speichert und verwendet die DFG um dem Kunden die Transaktionshistorie anzuzeigen.</p>
    <p>3.5	Auf welcher Grundlage erfolgt die Verwendung von personenbezogenen Daten durch die DFG im Zusammenhang mit der Nutzung der Funktionalitäten des Benutzerkontos ?</p>
    <p>  Grundlage ist die Durchführung des Nutzungsvertrages.</p>
    <p>4.	Cookies und ähnliche Technologien</p>
    <p>4.1	Cookies</p>
    <p>Wenn Kunden bzw.Interessenten oder sonstige natürliche Personen(„Besucher“) die Webseite besuchen bzw.öffnen, sammelt die DFG Informationen über den Besucher, indem die DFG Cookies einsetzt.Cookies sind kleine Textdateien, die eine Wiedererkennung des Browsers des Besuchers ermöglichen und die an den Computer oder sein mobiles Gerät gesendet werden.Die DFG verwendet Cookies, um Besuchern eine nutzerfreundliche und sichere Webseite anbieten zu können und um die Webseite an ihre Bedürfnisse und Interessen anzupassen, z.B.ihnen auf sie zugeschnittene Werbung auf der Webseite(z.B.als Bannerwerbung) anzuzeigen.</p>
    <p>Besucher können die Webseite grundsätzlich auch ohne Cookies nutzen.Sie können ihren Browser so einstellen, dass Cookies generell abgelehnt werden.Ebenso können Besucher bereits gesetzte Cookies in ihren Browser - Einstellungen löschen.Wenn Besucher keine Cookies verwenden, kann dies jedoch dazu führen, dass die Funktionen der Webseite nicht in vollem Umfang genutzt werden können.</p>
    <p>Auf der Webseite verwendet die DFG die folgenden verschiedenen Arten von Cookies:</p>
    <table>
      <thead>
        <tr>
          <th>Bezeichnung des Cookies</th>
          <th>Funktion / Verwendung</th>
          <th>Dauer der Speicherung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cookie-Consent Cookie</td>
          <td>Google Analytics-Cookie</td>
          <td>Google Analytics Opt-Out Cookie</td>
        </tr>
        <tr>
          <td>Wenn Besucher auf der Webseite der Verwendung von Cookies zustimmen, wird in diesem Cookie diese Zustimmung gespeichert.</td>
          <td>Siehe dazu Ziffer 4.2(a)</td>
          <td>Siehe dazu Ziffer 4.2(a)</td>
        </tr>
        <tr>
          <td>12 Monate</td>
          <td>18 Monate</td>
          <td>unbegrenzt</td>
        </tr>
      </tbody>
    </table>
    <p>Bezeichnung des Cookies	Funktion / Verwendung	Dauer der Speicherung</p>
    <p>Cookie - Consent Cookie	Wenn Besucher auf der Webseite der Verwendung von Cookies zustimmen, wird in diesem Cookie diese Zustimmung gespeichert.	12 Monate</p>
    <p>Google Analytics - Cookie	Siehe dazu Ziffer 4.2(a)	18 Monate</p>
    <p>Google Analytics Opt - Out Cookie	Siehe dazu Ziffer 4.2(a)	unbegrenzt</p>
    <p>4.2	Ähnliche Technologien</p>
    <p>  (a)	Hinweis zur Verwendung von Google - Analytics</p>
    <p>Die DFG verwendet den Analysedienst Google Analytics der Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA(Google) zur Rationalisierung ihres Website - Portfolios(i) durch die Optimierung des Verkehrs zu und zwischen Websites der DFG und(ii) gegebenenfalls durch die Integration und Optimierung von Websites.Google Analytics verwendet Cookies(siehe oben unter 4.1) und generiert detaillierte Statistiken über den Verkehr einer Website und dessen Ursprung und misst Conversions und Umsätze.Die durch den Cookie erzeugten Informationen(einschließlich der IP - Adresse der Besucher) über die Benutzung der Webseite durch Besucher werden an einen Server von Google in den USA übertragen und dort gespeichert und verarbeitet.</p>
    <p>Google wird im Auftrag der DFG diese Informationen benutzen, um die Nutzung der Webseite auszuwerten, um Reports über Webseitenaktivitäten zusammenzustellen und DFG diese Berichte zu Analysezwecken zur Verfügung zu stellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber der DFG zu erbringen.Aus den verarbeiteten Daten können pseudonyme Nutzungsprofile erstellt werden.</p>
    <p>Auf der Webseite wird Google Analytics um den Code „ano - nymizeIP“ erweitert.Dies bedeutet, dass die IP - Adresse der Besucher innerhalb der EU oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt wird.Nur in Ausnahmefällen wird die volle IP - Adresse an einen Server von Google in den USA übertragen und dort gekürzt.Weitere Informationen zur Funktionsweise der IP - Anonymisierung finden Sie unter <a href='https://support.google.com/analytics/answer/2763052' target='_blank'>https://support.google.com/analytics/answer/2763052</a>. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
    <p>Die Speicherung der Cookies können Besucher durch eine entsprechende Einstellung in ihrem Browser verhindern.Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf ihre Nutzung der Webseite bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser - Plugin herunterladen und installieren: <a href='http://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'>http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
    <p>Die Webanalyse bleibt in diesem Fall solange deaktiviert, wie das Add - On von Google nicht seinerseits deaktiviert bzw.gelöscht wird.Daher sollten Besucher das Add - On nicht löschen, solange die Webanalyse nicht gewollt ist.Das Add - On ist pro Browser und Rechner gesetzt.Falls Besucher die Webseite also mit unterschiedlichen Browsern oder von unterschiedlichen Rechnern aus aufrufen, müssen sie für jeden Browser bzw.Rechner das Add - On gesondert hinzufügen.Alternativ zum Browser - Add - On, insbesondere bei Browsern auf mobilen Endgeräten, können Besucher die Erfassung durch Google Analytics zudem verhindern, indem sie auf diesen Link klicken: <a href='http://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'>http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
    <p>Wenn Besucher diesen Link klicken, wird ein Opt - Out - Cookie gesetzt, das die zukünftige Erfassung von Daten des Besuchers beim Besuch der Webseite verhindert.Der Opt - Out - Cookie gilt nur in diesem Browser und nur für die Webseite und wird auf dem Gerät des Besuchers abgelegt.Löscht der Besucher die Cookies in diesem Browser, muss er das Opt - Out - Cookie erneut setzen.</p>
    <p>Die DFG nutzt Google Analytics des Weiteren dazu, Daten aus DoubleClick - Cookies und auch aus AdWords zu statistischen Zwecken auszuwerten.Dies umfasst demografische Merkmale(z.B.Alter und Geschlecht) sowie Interessen.Diese Daten sind nicht auf eine bestimmte Person zurückzuführen.Sollten Besucher dies nicht wünschen, können sie dies jederzeit über die <a href='https://adssettings.google.com/anonymous?hl=de&sig=ACi0TCh7qclSkjyEa0SsTlU_3m0RYd6Uc8ZOOTOvKLpUfNjApt_OtMMitFKJpS6gb_fJIhUSHLGArnfHdtXqU4tals2MDKV5L6EV4eJS3AF4RTQ0H4HksAY' target='_blank'>hier</a> erreichbaren  Anzeigeneinstellungen  deaktivieren.</p>
    <p>Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten.</p>
    <p>(b)	Local Storage</p>
    <p>Im Browser werden durch „Local Storage“ die Nutzerkennung des Kunden und ein Login - Token gespeichert.Diese können vom Kunden selbständig über die entsprechende Funktion im Browser gelöscht werden.Sie werden ebenfalls gelöscht, wenn sich der Nutzer aus dem Benutzerkonto ausloggt.</p>
    <p>4.3	Auf welcher Grundlage erfolgt die Nutzung von Cookies und ähnlichen Technologien durch die DFG als verantwortliche Stelle</p>
    <p>Grundlage sind berechtigte Interessen der DFG.</p>
    <p>5.	Allgemeines</p>
    <p>5.1	Wo werden personenbezogene Daten durch die DFG als verantwortliche Stelle verarbeitet ?</p>
    <p>  Die Stammdaten und die Bankverbindungsdaten werden nur innerhalb der Europäischen Union und des Europäischen Wirtschaftsraums durch die DFG oder von den in Ziffer 5.6 genannten Dienstleistern für und im Auftrag der DFG für die DFG verarbeitet.</p>
    <p>Die von Google Inc.gemäß Ziffer 4.2(a) erhobenen Daten werden an Server von Google in den USA übertragen und dort gespeichert und verarbeitet.Die Gesetze zum Schutz von personenbezogenen Daten in den USA können von dem Schutz innerhalb der EU abweichen und gegebenenfalls nur ein geringeres Schutzniveau gewährleisten.</p>
    <p>5.2	Für welche anderen Zwecke und auf welcher Rechtsgrundlage erhebt, verarbeitet und nutzt die DFG personenbezogene Daten von Besuchern als verantwortliche Stelle ?</p>
    <p>  Abgesehen von den in dieser Datenschutzerklärung ausdrücklich genannten Zwecken erhebt, verarbeitet und nutzt die DFG personenbezogene Daten von Besuchern(einschließlich der IP - Adresse, Browser - Referer und dem Zeitpunkt des Zugriffs auf die Webseite) als verantwortliche Stelle wie folgt:</p>
    <p>(a)	Aufgrund berechtigter Interessen der DFG:</p>
    <p>(i)	zur Bereitstellung, zur Wartung, zum Schutz und zur Verbesserung von Diensten durch die DFG, zur Entwicklung neuer Dienste sowie zum Schutz der DFG und zum Schutz anderer Nutzer von Diensten der DFG,</p>
    <p>  (ii)	um Ihnen personalisierte Werbung(z.B.mittels Werbe - bannern auf Webseiten) zur Verfügung zu stellen,</p>
    <p>    (iii)	zur weiteren Verbesserung der Sicherheit der IT - Systeme der DFG zum Vorteil aller Nutzer,</p>
    <p>      (iv)	zur Durchsetzung geltender Bedingungen des Nutzungsvertrages oder der Verfolgung von Verstößen gegen geltendes Recht(z.B.Betrug), einschließlich der Untersuchung möglicher Verstöße gegen den Nutzungsvertrag oder geltendes Recht,</p>
    <p>        (v)	zur sonstigen erforderlichen Kommunikation mit Besuchern, sofern diese nicht zur Erfüllung(vor -)vertraglicher Verpflichtungen den Besuchern gegenüber erfolgt(etwa um ihnen Störungen mitzuteilen),</p>
    <p>          (vi)	zur Aufdeckung oder zur Verhinderung technischer Probleme,</p>
    <p>            (vii)	zum Schutz von Rechten, Eigentum oder Sicherheit der DFG, Eigentum oder Sicherheit anderer Nutzer von Diensten der DFG oder der Öffentlichkeit, soweit gesetzlich zulässig und erforderlich.</p>
    <p>(b)	Aufgrund gesetzlicher Verpflichtungen:</p>
    <p>(i)	zur Erfüllung und Einhaltung von geltenden Gesetzen, Vorschriften, Rechtsverfahren oder vollziehbaren bzw.vollstreckbaren behördlichen Anordnungen, die für die DFG verbindlich sind,</p>
    <p>  (ii)	zur Aufdeckung, zur Verhinderung oder zur Bekämpfung von Rechtsverstößen, sofern die DFG hierzu gesetzlich verpflichtet ist oder Dritte darauf einen Anspruch gegenüber der DFG haben.</p>
    <p>Dies schließt die Übermittlung an Dritte(einschließlich Behörden) zur Erreichung der vorgenannten Zwecke ein.</p>
    <p>  Daten, die keinen Personenbezug zu einem Besucher haben, also nicht mit einer bestimmten oder bestimmbaren natürlichen Person in Zusammenhang gebracht werden können(z.B.vollständig anonymisierte Daten), gibt die DFG möglicherweise an Dritte weiter.</p>
    <p>5.3	Wie lange werden personenbezogene Daten gespeichert ?</p>
    <p>  Die DFG als verantwortliche Stelle speichert die erhobenen Daten nicht länger als zur Erfüllung des jeweiligen Zwecks, für den sie erhoben wurden, es sei denn, für eine solche Speicherung besteht eine gesetzliche Grundlage.Anschließend wird die DFG die Daten löschen.Die jeweiligen Aufbewahrungsfristen sind von dem zugrundeliegenden Zweck und von der Art der personenbezogenen Daten abhängig.</p>
    <p>Insbesondere speichert die DFG(vorbehaltlich längerer gesetzlicher Speicherpflichten):</p>
    <p>(a)	Stammdaten, so lange der betreffende Kunde sein Benutzerkonto nutzen will und ein Nutzungsvertrag besteht.Wenn ein Kunde sein Benutzerkonto nicht mehr nutzen will, kann er dieses, einschließlich sämtlicher Kundendaten, anhand der dafür im Benutzerkonto bereitgestellten Funktionalität(siehe dazu oben Ziffer 3.1) löschen.</p>
    <p>(b)	die IP - Adresse von Besuchern für maximal 30(dreißig) Tage; abweichend davon wird die zur Protokollierung gemäß Ziffer 2.2(c) gespeicherte IP - Adresse solange gespeichert, wie die Stammdaten gemäß Ziffer 5.3(a) gespeichert werden.</p>
    <p>(c)	anhand von Cookies erhobene Daten für den jeweils in Ziffer 4.1 genannten Zeitraum.Darüber hinaus können Besucher Cookies jederzeit von ihrem Computer löschen.</p>
    <p>5.4	Welche Rechte haben Besucher im Hinblick auf ihre Daten ?</p>
    <p>  Bezüglich der Verarbeitung ihrer Daten stehen den Besuchern folgende Rechte zu:</p>
    <p>(a)	ein Recht auf Auskunft gegen die DFG in Bezug auf ihre Daten(Art. 15 DS - GVO),</p>
    <p>  (b)	ein Recht auf Berichtigung, sofern ihre Daten unrichtig sind(Art. 16 DS - GVO),</p>
    <p>    (c)	ein Recht auf Löschung, (1) wenn eine Speicherung ihrer Daten zu den genannten Zwecken nicht weiter notwendig ist, (2) wenn die Spei - cherung sich auf eine von ihnen mittlerweile widerrufene Einwilligung stützt und keine andere Rechtsgrundlage für eine Speicherung durch die DFG vorliegt, (3) wenn sie Widerspruch gegen durch die DFG durchgeführte Direktwerbung oder Profiling erhoben haben und die DFG ihre Daten zu diesen Zwecken speichern, (4) wenn die DFG ihre Daten unrechtmäßig verarbeitetet hat, oder(5) wenn eine rechtliche Verpflichtung zur Löschung besteht(sog.„Recht auf Vergessenwerden“, Art. 17 DS - GVO),</p>
    <p>      (d)	ein Recht auf Einschränkung der Verarbeitung, (1) wenn sie die Rich - tigkeit ihrer Daten bestritten haben für den Zeitraum, den die DFG zur Prüfung der Richtigkeit benötigt, (2) wenn die Verarbeitung ihrer Daten unrechtmäßig war und sie eine Einschränkung der Verarbeitung statt einer Löschung wünschen, oder(3) wenn eine Speicherung durch die DFG zu den genannten Zwecken nicht länger erforderlich ist, sie ihre Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen(Art. 18 DS - GVO),</p>
    <p>        (e)	ein Recht auf Datenübertragbarkeit im Hinblick auf ihre Daten, die sie der DFG zur Verfügung gestellt haben, wenn die Verarbeitung auf einem Vertrag mit der DFG beruht, d.h.sie können ihre der DFG zur Verfügung gestellten Daten in einem strukturierten, gängigen und maschinenlesbaren Format an sich oder an Dritte herausverlangen(Art. 20 DS - GVO),</p>
    <p>          (f)	ein Recht auf Widerspruch gegen die Verarbeitung(Art. 21 DS - GVO).</p>
    <p>Diese Rechte sind teilweise gesetzlich eingeschränkt.Sofern die DFG diese Rechte daher nicht erfüllen kann, wird sie dies dem betreffenden Besucher mitteilen.</p>
    <p>Im Übrigen steht Besuchern hinsichtlich der Verarbeitung ihrer Daten ein Recht zur Beschwerde bei der zuständigen Aufsichtsbehörde zu.</p>
    <p>5.5	An wen können sich Besucher zur Geltendmachung der vorgenannten Rechte wenden ?</p>
    <p>  Soweit die DFG verantwortliche Stelle im datenschutzrechtlichen Sinne ist, können sich Besucher zur Geltendmachung der vorgenannten Rechte an die DFG auf folgenden Wegen wenden:</p>
    <p>(a)	per Brief an die DFG Deutsche Fondsgesellschaft SE Invest, Schlüterstr. 40, 10707 Berlin, oder</p>
    <p>  (b)	per E - Mail an die folgende Adresse: <a href='mailto:service@der-zukunftsfonds.de'>service@der-zukunftsfonds.de</a></p>
    <p>Soweit die Kooperationsbank verantwortliche Stelle im datenschutzrechtlichen Sinne ist gelten die Ausführungen in den <a href='https://www.der-zukunftsfonds.de/partnerbank' target='_blank'>hier</a> abrufbaren bzw.in dem im Rahmen des Registrierungsprozesses übermittelten Antragsunterlagen der Kooperationsbank enthaltenen Datenschutzhinweisen der Kooperationsbank.</p>
    <p>5.6	Dienstleister der DFG beim Betrieb der Webseite</p>
    <p>Beim Betrieb der Webseite arbeitet die DFG gegebenenfalls mit externen Dienstleistern zusammen.Die jeweils im Rahmen des Betriebs der Webseite von der DFG beauftragten und für diese tätigen externen Dienstleister, die auch die in dieser Datenschutzerklärung aufgeführten personenbezogenen Daten verarbeiten oder darauf Zugriff haben, können <a href='https://www.der-zukunftsfonds.de/dienstleister' target='_blank'>hier</a> eingesehen werden. Diese Dienstleister verarbeiten diese Daten ausschließlich nach den Weisungen der DFG als Auftragsverarbeiter.</p>
    <p>5.7	Fragen zum Datenschutz</p>
    <p>Für alle Fragen zum Datenschutz im Zusammenhang mit der Nutzung der Webseite können Besucher sich an die DFG wenden:</p>
    <p>(a)	per Brief an die DFG Deutsche Fondsgesellschaft SE Invest, Schlüterstr. 40, 10707 Berlin, oder</p>
    <p>  (b)	per E - Mail an die folgende Adresse: <a href='mailto:service@der-zukunftsfonds.de'>service@der-zukunftsfonds.de</a></p>
    <p>5.8	Was passiert bei einer Aktualisierung dieser Datenschutzerklärung ?</p>
    <p>  Diese Datenschutzerklärung kann von Zeit zu Zeit geändert werden, z.B.um sie neuen datenschutzrechtlichen Bestimmungen anzupassen.Alle Änderungen der Datenschutzerklärung werden von der DFG auf dieser Seite veröffentlicht.Falls die Änderungen wesentlich sein sollten, wird die DFG Kunden mit einem Benutzerkonto gegebenenfalls gesondert(z.B.per E - Mail) benachrichtigen.Außerdem wird die DFG ältere Versionen dieser Datenschutzerklärung zur Einsicht in einem Archiv aufbewahren.Diese Datenschutzerklärung wurde zuletzt am 17. Mai 2018 aktualisiert.</p>
  </div>
);

export default PrivacyPolicy;
