import React from 'react';
import Async from 'react-code-splitting';

import * as thunks from './thunks';

import ConfirmActivationCode from '@/components/routes/onboarding/ConfirmActivationCode';
import ResendActivationCode from '@/components/routes/onboarding/ResendActivationCode';
import PersonalInfo from '@/components/routes/onboarding/PersonalInfo';
import ContactInfo from '@/components/routes/onboarding/ContactInfo';
import BankInfo from '@/components/routes/onboarding/BankInfo';
import LegalAgreements from '@/components/routes/onboarding/LegalAgreements';
import ConfirmIdentity from '@/components/routes/onboarding/ConfirmIdentity';
import Summary from '@/components/routes/onboarding/Summary';
import Status from '@/components/routes/onboarding/Status';
import LogIn from '@/components/routes/LogIn';

const InitialInvestment = () => <Async load={import('@/components/routes/onboarding/InitialInvestment')} />;

export const CONFIRM_SIGNUP_EMAIL = 'router/CONFIRM_SIGNUP_EMAIL';
export const CHANGE_SIGNUP_EMAIL = 'router/CHANGE_SIGNUP_EMAIL';
export const ENTER_PERSONAL_INFO = 'router/ENTER_PERSONAL_INFO';
export const ACTIVATE_ACCOUNT = 'router/ACTIVATE_ACCOUNT';
export const ENTER_CONTACT_INFO = 'router/ENTER_CONTACT_INFO';
export const ENTER_INITIAL_INVESTMENT = 'router/ENTER_INITIAL_INVESTMENT';
export const ENTER_BANK_INFO = 'router/ENTER_BANK_INFO';
export const ACCEPT_LEGAL_AGREEMENTS = 'router/ACCEPT_LEGAL_AGREEMENTS';
export const CONFIRM_IDENTITY = 'router/CONFIRM_IDENTITY';
export const ONBOARDING_SUMMARY = 'router/ONBOARDING_SUMMARY';
export const ONBOARDING_STATUS = 'router/ONBOARDING_STATUS';

export default {
  [ACTIVATE_ACCOUNT]: {
    path: '/onboarding/activate-account',
    component: ConfirmActivationCode,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 1,
    thunk: thunks.forwardOnboarding,
  },
  [ENTER_CONTACT_INFO]: {
    path: '/onboarding/contact-info',
    component: ContactInfo,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 2,
  },
  [ENTER_PERSONAL_INFO]: {
    path: '/onboarding/personal-info',
    component: PersonalInfo,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 3,
  },
  [ENTER_INITIAL_INVESTMENT]: {
    path: '/onboarding/initial-investment',
    component: InitialInvestment,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: ['userInfo', 'graphSettings'],
    step: 4,
  },
  [ENTER_BANK_INFO]: {
    path: '/onboarding/bank-info',
    component: BankInfo,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 5,
  },
  [ACCEPT_LEGAL_AGREEMENTS]: {
    path: '/onboarding/legal-agreements',
    component: LegalAgreements,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 6,
  },
  [ONBOARDING_SUMMARY]: {
    path: '/onboarding/summary',
    component: Summary,
    isAuthRequired: true,
    isOnboarding: true,
    fetchData: 'userInfo',
    step: 7,
  },
  [CONFIRM_IDENTITY]: {
    path: '/onboarding/confirm-identity',
    component: ConfirmIdentity,
    isAuthRequired: true,
    isOnboarding: true,
    step: 8,
  },
  [ONBOARDING_STATUS]: {
    path: '/onboarding/status',
    component: Status,
    isAuthRequired: true,
    isOnboarding: true,
    step: 0,
  },
  [CONFIRM_SIGNUP_EMAIL]: {
    path: '/onboarding/confirm-email',
    component: LogIn,
    isAuthRequired: false,
    thunk: thunks.confirmSignupEmail,
  },
  [CHANGE_SIGNUP_EMAIL]: {
    path: '/onboarding/change-email',
    component: ResendActivationCode,
    isAuthRequired: true,
    isOnboarding: true,
    step: 0,
    thunk: thunks.changeSignupEmail,
  },
};
